import { useCallback, useMemo } from 'react'
import isBoolean from 'lodash/isBoolean'
import {
  BoolValue,
  StringValue,
} from 'google-protobuf/google/protobuf/wrappers_pb'
import { AuditBotPromiseClient } from '@trustero/trustero-api-web/lib/audit/auditbot_grpc_web_pb'
import { RoadmapServicePromiseClient } from '@trustero/trustero-api-web/lib/roadmap/roadmap_grpc_web_pb'
import { Empty } from 'google-protobuf/google/protobuf/empty_pb'
import {
  GetLiveAuditRunsResponse,
  GetOrCreateComplianceRoadmapRequest,
  GetOrCreateComplianceRoadmapResponse,
  GetRiskResponse,
  GetScopeRequest,
  GetScopeResponse,
  ToggleRequiredDocumentsRequest,
} from '@trustero/trustero-api-web/lib/roadmap/roadmap_pb'
import { GrpcResponse, NewGrpcResponse } from 'src/components/async/hooks/types'
import { useSwrImmutableGrpc } from 'src/components/async/useSwrImmutableGrpc'
import { useInAudit } from 'src/context/AuditContext'
import { useAuthorizedGrpcClient } from 'src/adapter/grpcClient'
import {
  GetAuditReadinessRequest,
  GetAuditReadinessResponse,
  GetSmartChecksTestInformationRequest,
  GetSmartChecksTestInformationResponse,
} from '@trustero/trustero-api-web/lib/audit/auditbot_pb'
import { useGrpcRevalidateByMethod } from 'src/components/async/useGrpcMutate'
import { FRAMEWORK_OPTION_NAMES } from 'src/Utils/globalEnums'
import { useCurrentAudit } from 'src/components/async/model/audit'
import { useHasSoc2 } from 'src/components/async/complianceframework/useComplianceFrameworks'
import { CONTROL_TEST_NAMES } from '../AuditBot/accordion/subsection/ControlChecks/AuditBotControlChecks.constants'
import { DESIGN_TESTS, RoadmapIndexConfig } from './roadmap.constants'
import { getRoadmapWidgetRequestConfig } from './widgets/RoadmapWidgets.helpers'
import { ROADMAP_WIDGET_LOCATION } from './widgets/RoadmapWidgets.constants'
import { useRoadmapWidgetCounts } from './widgets/RoadmapWidgets.hooks'
import {
  getRoadMapIndexRowsConfig,
  handleRoadmapErrors,
} from './roadmap.helpers'

export const useScope = (): GrpcResponse<GetScopeResponse> => {
  const { auditId } = useInAudit()
  const request = new GetScopeRequest()
  auditId && request.setAuditId(auditId)

  const { response } = useSwrImmutableGrpc(
    RoadmapServicePromiseClient.prototype.getScope,
    request,
    true,
  )

  return NewGrpcResponse(response)
}

export const useRisk = (): GrpcResponse<GetRiskResponse> => {
  const { response } = useSwrImmutableGrpc(
    RoadmapServicePromiseClient.prototype.getRisk,
    new Empty(),
    true,
  )

  return NewGrpcResponse(response)
}

/**
 * Get the current compliance_roadmap for the audit or create one if it doesn't exist
 *
 * @param auditId: <string> the audit id
 * @returns <GrpcResponse<GetOrCreateComplianceRoadmapResponse>> which contains a Roadamp message
 */
export const useFindOrCreateRoadmap = (
  auditId?: string,
): GrpcResponse<GetOrCreateComplianceRoadmapResponse> => {
  const req = useMemo(
    () => new GetOrCreateComplianceRoadmapRequest().setAuditId(auditId || ''),
    [auditId],
  )
  const { response } = useSwrImmutableGrpc(
    RoadmapServicePromiseClient.prototype.getOrCreateComplianceRoadmap,
    req,
  )
  return NewGrpcResponse(response)
}

/**
 * Get the list of control tests in an audit period and the associated controls with pass/fail status
 *
 * @param auditId: <string> the audit id
 * @returns <GrpcResponse<GetSmartChecksTestInformationResponse>> which contains a map of control tests to applicable controls
 */
export const useSmartChecks = (
  auditId?: string,
  controlTests?: string[],
): GrpcResponse<GetSmartChecksTestInformationResponse> => {
  const req = useMemo(
    () =>
      new GetSmartChecksTestInformationRequest()
        .setAuditId(auditId || '')
        .setControlTestsList(controlTests || []),
    [auditId, controlTests],
  )
  const { response } = useSwrImmutableGrpc(
    AuditBotPromiseClient.prototype.getSmartChecksTestInformation,
    req,
  )
  return NewGrpcResponse(response)
}

/**
 * Get the list of controls in an audit period and the associated control tests with pass/fail status
 * This is essentially the inverse of the useSmartChecks hook
 *
 * @param auditId: <string> the audit id
 * @returns: <GrpcResponse<GetAuditReadinessResponse>> which contains a map of controls to applicable control tests
 */
export const useAuditReadiness = ({
  auditId,
  relevantTests,
  controlId,
  calculateStaleness,
}: {
  auditId?: string
  relevantTests?: CONTROL_TEST_NAMES[]
  controlId?: string
  calculateStaleness?: boolean
}): GrpcResponse<GetAuditReadinessResponse> => {
  const req = useMemo(() => {
    const req = new GetAuditReadinessRequest().setAuditId(auditId || '')
    if (controlId) {
      req.setControlId(new StringValue().setValue(controlId))
    }
    if (calculateStaleness) {
      req.setCalculateStaleness(calculateStaleness)
    }

    return req
  }, [auditId, controlId, calculateStaleness])

  if (relevantTests) {
    req.setControlTestsList(relevantTests)
  }
  const { response } = useSwrImmutableGrpc(
    AuditBotPromiseClient.prototype.getAuditReadiness,
    req,
  )
  return NewGrpcResponse(response)
}

export const useControlChecks = (
  request: GetAuditReadinessRequest,
  auditId?: string,
  shouldFetch = true,
): GrpcResponse<GetAuditReadinessResponse> => {
  if (auditId) {
    request.setAuditId(auditId)
  }

  request.setControlTestsList([
    CONTROL_TEST_NAMES.POLICY_MATCH,
    CONTROL_TEST_NAMES.COMPLETENESS,
    CONTROL_TEST_NAMES.SPOT_CHECK,
  ])

  const { response } = useSwrImmutableGrpc(
    AuditBotPromiseClient.prototype.getAuditReadiness,
    request,
    shouldFetch,
  )
  return NewGrpcResponse(response)
}

export const useHasAuditReadiness = ({
  auditId,
  relevantTests,
}: {
  auditId?: string
  relevantTests?: CONTROL_TEST_NAMES[]
}): boolean => {
  const { data, isLoading } = useAuditReadiness({
    auditId,
    relevantTests,
  })
  // return true while loading or if there is no data
  // this prevents masking the loading state
  if (isLoading || !data) {
    return true
  }
  const records = data?.getAuditReadinessList()
  const hasAuditReadiness = records?.reduce((acc, record) => {
    if (record.getControlTestsList().length > 0) {
      return true
    }
    return acc
  }, false)
  return hasAuditReadiness || false
}

/**
 * Update the compliance_roadmap row approvals based on user input
 *
 * @param auditId : <string> the audit id
 * @returns: <void>
 */
export const useToggleRequiredDocuments = (
  auditId?: string,
): (({
  businessContinuityContingency,
  supportingDocumentsSop,
  policiesApproved,
}: {
  businessContinuityContingency?: boolean
  supportingDocumentsSop?: boolean
  policiesApproved?: boolean
}) => Promise<void>) => {
  const client = useAuthorizedGrpcClient(RoadmapServicePromiseClient)
  const mutate = useGrpcRevalidateByMethod()

  const toggleRequiredDocs = useCallback(
    async ({
      businessContinuityContingency,
      supportingDocumentsSop,
      policiesApproved,
    }: {
      businessContinuityContingency?: boolean
      supportingDocumentsSop?: boolean
      policiesApproved?: boolean
    }) => {
      const req = new ToggleRequiredDocumentsRequest().setAuditId(auditId || '')
      isBoolean(businessContinuityContingency) &&
        req.setBusinessContinuityContingency(
          new BoolValue().setValue(businessContinuityContingency),
        )
      isBoolean(supportingDocumentsSop) &&
        req.setHasSupportingDocumentsSop(
          new BoolValue().setValue(supportingDocumentsSop),
        )
      isBoolean(policiesApproved) &&
        req.setPoliciesApproved(new BoolValue().setValue(policiesApproved))
      await client.toggleRequiredDocuments(req)
      await mutate(
        RoadmapServicePromiseClient.prototype.getOrCreateComplianceRoadmap,
      )
    },
    [auditId, client, mutate],
  )

  return toggleRequiredDocs
}

export const useRoadmapIndexRowsConfig = (
  auditId?: string,
  isSoc2 = false,
): RoadmapIndexConfig => {
  const { data: scopeData } = useScope()
  const hasSoc2 = useHasSoc2()
  const designConfig = getRoadmapWidgetRequestConfig({
    appLocation: ROADMAP_WIDGET_LOCATION.DESIGN,
    auditId,
    isSoc2,
    hasSoc2,
  })
  const implementationConfig = getRoadmapWidgetRequestConfig({
    appLocation: ROADMAP_WIDGET_LOCATION.IMPLEMENTATION,
    auditId,
    isSoc2,
  })
  const handleRequestsConfig = getRoadmapWidgetRequestConfig({
    appLocation: ROADMAP_WIDGET_LOCATION.HANDLE_REQUESTS,
    auditId,
    isSoc2,
  })
  const operatingEffectivenessConfig = getRoadmapWidgetRequestConfig({
    appLocation: ROADMAP_WIDGET_LOCATION.OPERATING_EFFECTIVENESS,
    auditId,
    isSoc2,
  })
  const { data: designWidgetData, error: designError } = useRoadmapWidgetCounts(
    {
      auditId,
      requestConfig: designConfig,
    },
  )
  const { data: implementationWidgetData, error: implementationError } =
    useRoadmapWidgetCounts({
      auditId,
      requestConfig: implementationConfig,
    })
  const { data: handleRequestsWidgetData, error: handleRequestsError } =
    useRoadmapWidgetCounts({
      auditId,
      requestConfig: handleRequestsConfig,
    })
  const {
    data: operatingEffectivenessWidgetData,
    error: operatingEffectivenessError,
  } = useRoadmapWidgetCounts({
    auditId,
    requestConfig: operatingEffectivenessConfig,
  })
  const { data: roadmapToggleData, error: roadmapToggleError } =
    useFindOrCreateRoadmap(auditId)
  // TODO: Update when we add more tests
  const { data: smartChecksData, error: smartCheckError } = useSmartChecks(
    auditId,
    DESIGN_TESTS,
  )
  const { data: auditReadinessData, error: auditReadinessError } =
    useAuditReadiness({
      auditId,
    })

  const { data: riskData, error: riskError } = useRisk()
  const requestConfig = getRoadmapWidgetRequestConfig({
    appLocation: ROADMAP_WIDGET_LOCATION.RISK_ASSESSMENT,
    isSoc2: false, // doesn't matter here
  })
  const { data: riskCounts, error: riskError2 } = useRoadmapWidgetCounts({
    requestConfig,
  })

  handleRoadmapErrors({
    auditId,
    designError,
    implementationError,
    handleRequestsError,
    operatingEffectivenessError,
    roadmapToggleError,
    smartCheckError,
    auditReadinessError,
    riskError: riskError || riskError2,
  })

  return getRoadMapIndexRowsConfig({
    scopeData,
    designWidgetData,
    implementationWidgetData,
    handleRequestsWidgetData,
    operatingEffectivenessWidgetData,
    roadmapToggleData,
    smartChecksData,
    auditReadinessData,
    riskData,
    riskCounts,
    hasSoc2,
  })
}

export const useIsSoc2Audit = (): boolean => {
  const { data: audit } = useCurrentAudit()

  const isSoc2 =
    audit
      ?.getComplianceFrameworkOptionsList()
      .some(
        (option) =>
          option.getName() === FRAMEWORK_OPTION_NAMES.SOC2Type2 ||
          option.getName() === FRAMEWORK_OPTION_NAMES.SOC2Type1,
      ) || false

  return isSoc2
}

/**
 * Grab the live audit runs for a given audit & compliance roadmap
 * Used to indicate whether a scan is running on a given control or control test in the roadmap accordions
 *
 * We will only fetch data if a valid ID is passed in (i.e. we are in an audit)
 */
export const useLiveAuditRuns = (): GrpcResponse<GetLiveAuditRunsResponse> => {
  const { response } = useSwrImmutableGrpc(
    RoadmapServicePromiseClient.prototype.getLiveAuditRuns,
    new Empty(),
  )
  return NewGrpcResponse(response)
}
