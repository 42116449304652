import React from 'react'
import { Grid } from 'src/components/Reusable/Grid'
import { VendorRecord } from '@trustero/trustero-api-web/lib/vendormanagement/vendormanagement_pb'
import { VendorsGridHeader, VendorsGridRow } from './VendorsGrid.components'

export const VendorsGrid = ({
  vendors,
}: {
  vendors: VendorRecord[]
}): JSX.Element => (
  <Grid gridTemplateColumns="1fr repeat(5, max-content)">
    <VendorsGridHeader />
    {vendors.map((vendor: VendorRecord) => (
      <VendorsGridRow key={vendor.getId()} vendor={vendor} />
    ))}
  </Grid>
)
