import { useEffect, useMemo, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { FilterParam } from 'src/components/Reusable/IndexPage/FilterBar/FilterBar.types'
import queryString from 'query-string'
import { TYPEWRITER_INTERVAL } from './globalConstants'

export const useTypewriter = ({
  text,
  interval = TYPEWRITER_INTERVAL,
  shouldLoop,
  shouldType = true,
}: {
  text: string
  interval?: number
  shouldLoop?: boolean
  shouldType?: boolean
}): string => {
  const [currentPosition, setCurrentPosition] = useState<number>(0)
  const currentPositionRef = useRef<number>(0)

  useEffect(() => {
    if (!text || !shouldType) {
      return
    }
    const intervalId = setInterval(() => {
      currentPositionRef.current += 1
      setCurrentPosition((prev) => prev + 1)
      if (currentPositionRef.current > text.length) {
        if (shouldLoop) {
          setCurrentPosition(0)
          currentPositionRef.current = 0
        } else {
          clearInterval(intervalId)
        }
      }
    }, interval)
    return () => {
      clearInterval(intervalId)
      setCurrentPosition(0)
      currentPositionRef.current = 0
    }
  }, [text, interval, shouldLoop, shouldType])

  return text.substring(0, currentPosition)
}

export const useAppliedFilters = (filterParam: FilterParam): string[] => {
  const location = useLocation()
  const searchParams = useMemo(() => {
    return queryString.parse(location.search, {
      arrayFormat: 'bracket',
    })
  }, [location])
  return useMemo(() => {
    let appliedFilters: string[]
    const preprocessed = searchParams[filterParam]
    if (Array.isArray(preprocessed)) {
      appliedFilters = preprocessed
    } else {
      appliedFilters = preprocessed ? [preprocessed] : []
    }
    return appliedFilters
  }, [searchParams, filterParam])
}
