import { useAccordionButton } from 'react-bootstrap/AccordionButton'
import React, { useContext } from 'react'
import AccordionContext from 'react-bootstrap/AccordionContext'
import styled from 'styled-components/macro'
import { ChevronSVG } from '../../Icons/Basic'
import { IconButton, IconButtonVariant } from './index'

type Props = {
  onToggled: (newState: boolean) => void
  defaultKey?: string
}

export function ExpandButton(props: Props): JSX.Element {
  const onClick = useAccordionButton(props.defaultKey ?? '0')
  const { activeEventKey } = useContext(AccordionContext)
  const isExpanded = Boolean(activeEventKey)
  return (
    <ExpandIconButton
      onClick={onClick}
      onToggled={props.onToggled}
      isExpanded={isExpanded}
    />
  )
}

type ExpandIconButtonProps = Omit<Props, 'defaultKey'> & {
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  isExpanded: boolean
}
export function ExpandIconButton({
  onClick,
  onToggled,
  isExpanded,
}: ExpandIconButtonProps): JSX.Element {
  return (
    <IconButton
      variant={IconButtonVariant.icon}
      onClick={(e) => {
        onClick?.(e)
        onToggled(!isExpanded)
      }}
    >
      <ChevronIcon $isToggled={isExpanded} />
    </IconButton>
  )
}

export const ChevronIcon = styled(ChevronSVG)<{ $isToggled: boolean }>`
  transform: ${(props) => {
    return props.$isToggled ? 'rotate(90deg)' : 'rotate(0deg)'
  }};
  transition: transform 0.25s;
`
