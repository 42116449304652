import React from 'react'
import {
  Auth0Icon,
  EntraIdIcon,
  GoogleIcon,
  MicrosoftIcon,
  OktaIcon,
  OneLoginIcon,
  PingOneIcon,
} from './Login.components'
import { LoginProviders } from './LoginPage.constants'

export const getProviderIcon = (provider: LoginProviders): JSX.Element => {
  switch (provider) {
    case LoginProviders.GOOGLE:
      return <GoogleIcon />
    case LoginProviders.OKTA:
      return <OktaIcon />
    case LoginProviders.AUTH0:
      return <Auth0Icon />
    case LoginProviders.MICROSOFT:
      return <MicrosoftIcon />
    case LoginProviders.ONELOGIN:
      return <OneLoginIcon />
    case LoginProviders.ENTRA:
      return <EntraIdIcon />
    case LoginProviders.PINGONE:
      return <PingOneIcon />
    default:
      return <></>
  }
}
