import React, { SetStateAction, useEffect, useRef, useState } from 'react'
import styled from 'styled-components/macro'
import { InfoButton } from 'src/components/PageLayout/InfoButton'
import isFunction from 'lodash/isFunction'
import isBoolean from 'lodash/isBoolean'
import { LayoutProps, SpaceProps } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'
import palette from 'src/designSystem/variables/palette'
import {
  Tooltip,
  TooltipOverlayType,
  TooltipPositions,
} from '../../Tooltip/Tooltip'
import {
  StyledInputError,
  StyledLabel,
  StyledLabelTitle,
  StyledTextInput,
} from './styles'

export type TextInputProps = LayoutProps &
  SpaceProps & {
    name: string
    initVal: string
    as?: 'textarea'
    required?: boolean
    form?: string
    label?: string
    placeholder?: string
    isValid?: ((value: string) => boolean) | boolean
    errorMessage?: string
    onChangeCb?: (value: string) => void
    isWarning?: boolean
    isDisabledMessage?: string
    isDisabled?: boolean
    hideLabel?: boolean
    allowEmptyVal?: boolean
    onBlurCb?: (value: string) => void
  }

export const TextInput = ({
  as,
  required,
  name,
  initVal = '',
  placeholder,
  form,
  label,
  isValid = () => true,
  errorMessage,
  onChangeCb,
  isWarning,
  isDisabledMessage,
  isDisabled,
  hideLabel,
  allowEmptyVal,
  onBlurCb,
  ...styleProps
}: TextInputProps): JSX.Element => {
  const [value, setValue] = useState<string>('')
  const [showError, setShowError] = useState<boolean>(false)

  useEffect(() => {
    if (!allowEmptyVal && !initVal) {
      return
    }
    setValue(initVal)
  }, [initVal, allowEmptyVal])

  const onChange: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (e) => {
    const val = e.target.value
    setValue(val)
    onChangeCb?.(val)
    if (isFunction(isValid)) setShowError(!isValid(val))
    else if (isBoolean(isValid)) setShowError(!isValid)
    if (required && !val) setShowError(true)
  }
  const onBlur: React.FocusEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (e) => {
    const val = e.target.value
    onBlurCb?.(val)
    if (required && !val) setShowError(true)
    else if (isFunction(isValid)) setShowError(!isValid(val))
    else if (isBoolean(isValid)) setShowError(!isValid)
    else setShowError(false)
  }

  const inputProps = {
    as,
    required,
    name,
    value,
    placeholder,
    onChange,
    showError,
    disabled: isDisabled,
    onBlur,
  }

  if (!label)
    return (
      <>
        <StyledTextInput {...inputProps} {...styleProps} />
        <StyledInputError showError={showError} isWarning={isWarning}>
          {errorMessage}
        </StyledInputError>
      </>
    )

  const showDisabledTooltip = isDisabled && isDisabledMessage

  return (
    <StyledLabel form={form} {...styleProps}>
      {!hideLabel && (
        <StyledLabelTitle>
          <>
            <p>{label}</p>
            {showDisabledTooltip && (
              <Tooltip
                id={`text-input-tooltip-${name}`}
                tooltipBody={isDisabledMessage}
                overlayType={TooltipOverlayType.tooltip}
                placement={TooltipPositions.top}
              >
                <InfoButton />
              </Tooltip>
            )}
          </>
        </StyledLabelTitle>
      )}
      <StyledTextInput {...inputProps} />
      {!hideLabel && (
        <StyledInputError showError={showError}>
          {errorMessage}
        </StyledInputError>
      )}
    </StyledLabel>
  )
}

type DerivedTextInputProps = TextInputProps & {
  setFormData?: React.Dispatch<SetStateAction<{ [key: string]: string }>>
  customOnChange?: React.ChangeEventHandler
  maxInputLength?: number
  labelmb?: string
}

export const DerivedTextInput = ({
  as,
  setFormData,
  required,
  name,
  initVal = '',
  placeholder,
  form,
  label,
  isValid,
  customOnChange,
  errorMessage,
  maxInputLength,
  labelmb,
  ...styleProps
}: DerivedTextInputProps): JSX.Element => {
  const [showError, setShowError] = useState(false)

  useEffect(() => {
    if (isBoolean(isValid)) setShowError(!isValid)
  }, [isValid])

  const onChange: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (e) => {
    const field = e.target.name
    const newVal = e.target.value
    isFunction(setFormData) &&
      setFormData((state: { [key: string]: string }) => ({
        ...state,
        [field]: newVal,
      }))
    if (isFunction(isValid)) setShowError(!isValid(newVal))
    else if (isBoolean(isValid)) setShowError(!isValid)
  }

  const onKeyDown: React.KeyboardEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (e) => {
    const target = e.target as HTMLInputElement | HTMLTextAreaElement
    if (e.key === 'Enter' && isFunction(isValid) && !isValid(target.value)) {
      setShowError(true)
    }
  }

  const inputProps = {
    as,
    required,
    name,
    value: initVal,
    placeholder,
    onKeyDown,
    onChange: isFunction(customOnChange) ? customOnChange : onChange,
    showError,
  }

  if (!label) {
    return (
      <>
        <StyledTextInput
          {...{ ...inputProps, ...styleProps }}
          maxLength={maxInputLength}
        />
        <StyledInputError showError={showError}>
          {errorMessage}
        </StyledInputError>
      </>
    )
  }

  return (
    <>
      <StyledLabel form={form} {...styleProps} mb={labelmb}>
        <p>{label}</p>
        <StyledTextInput {...inputProps} maxLength={maxInputLength} />
        <StyledInputError showError={showError}>
          {errorMessage}
        </StyledInputError>
      </StyledLabel>
    </>
  )
}

export const DynamicTextInput = (
  props: React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
    autoFocusKey?: string
  },
): JSX.Element => {
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null)

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = 'auto'
      textAreaRef.current.style.height = textAreaRef.current.scrollHeight + 'px'
    }
  }, [props.value])

  useEffect(() => {
    if (
      typeof props.value === 'string' &&
      props.value.trim() === '' &&
      textAreaRef.current
    ) {
      textAreaRef.current.style.height = 'auto'
    }
  }, [props.value])

  useEffect(() => {
    if (props.autoFocusKey) {
      const timeout = setTimeout(() => {
        textAreaRef.current?.focus()
      }, 50)
      return () => clearTimeout(timeout)
    }
  }, [props.autoFocusKey])

  return <DynamicTextArea ref={textAreaRef} rows={1} {...props} />
}

export const DynamicTextArea = styled.textarea`
  width: 100%;
  border: none;
  outline: none;
  overflow: hidden;
  resize: none;
  height: 18px;
  line-height: 21px;
  font-size: 14px;
  font-weight: 400;
  color: ${palette.neutral[900]};
`

export const DerivedTextArea = styled(DerivedTextInput).attrs({
  forwardedAs: 'textarea',
})`
  &[class^='StyledTextInput'],
  > *[class^='StyledTextInput'] {
    display: block;
    resize: none;
    height: 240px;
    padding: ${themeGet('space.s')}px;
  }
`

export const DerivedResizableTextArea = styled(DerivedTextInput).attrs({
  forwardedAs: 'textarea',
})`
  &[class^='StyledTextInput'],
  > *[class^='StyledTextInput'] {
    display: block;
    resize: vertical;
    height: 62px;
    padding: ${themeGet('space.s')}px;
  }
`
