import {
  ChatContext as ChatContextGRPC,
  ChatContextSource,
  ChatContextSourceEvidenceFor,
  ChatContextSourceIncludedIds,
  ChatContextSourceType,
  CreateChatContextRequest,
  EvidenceId,
  EvidenceIds,
  IncludedIds,
  UpdateChatContextRequest,
} from '@trustero/trustero-api-web/lib/chatcontext/chatcontext_pb'
import {
  ChatContextType,
  ChatContext,
  ChatContextTypes,
} from './useChatContext.hooks'

export const getCreateChatContextRequest = (
  chatContext: ChatContext,
): CreateChatContextRequest => {
  const chatContextRequest = new CreateChatContextRequest()
  chatContextRequest.setSourcesList(getGRPCSourcesList(chatContext))
  return chatContextRequest
}

export const getUpdateChatContextRequest = (
  id: string,
  chatContext: ChatContext,
): UpdateChatContextRequest => {
  const chatContextRequest = new UpdateChatContextRequest()
  const chatContextPayload = new ChatContextGRPC()
  chatContextPayload.setId(id)
  chatContextPayload.setSourcesList(getGRPCSourcesList(chatContext))
  chatContextRequest.setContext(chatContextPayload)
  return chatContextRequest
}

const getGRPCSourcesList = (chatContext: ChatContext): ChatContextSource[] => {
  return ChatContextTypes.map((type) => {
    const source = new ChatContextSource()
    if (chatContext[type].includeAll) {
      source.setIncludeAll(true)
    } else {
      const includedIds = Array.from(chatContext[type].included ?? [])
      if (type === ChatContextSourceType.EVIDENCE) {
        const includedIdsGRPC = new IncludedIds()
        const evidenceIds = new EvidenceIds()
        const idsList = includedIds.map((id) =>
          new EvidenceId()
            .setId(id)
            .setFor(
              chatContext[type].type?.[id] ??
                ChatContextSourceEvidenceFor.FOR_CONTROL,
            ),
        )
        evidenceIds.setIdsList(idsList)
        includedIdsGRPC.setEvidenceType(evidenceIds)
        source.setIncludedIds(includedIdsGRPC)
      } else {
        const includedIdsGRPC = new IncludedIds()
        includedIdsGRPC.setIdType(
          new ChatContextSourceIncludedIds().setIdsList(includedIds),
        )
        source.setIncludedIds(includedIdsGRPC)
      }
    }
    source.setType(type)

    return source
  })
}

export const getDefaultChatContext = (includeAll = true): ChatContext => {
  const chatContext: ChatContext = {
    [ChatContextSourceType.KNOWLEDGE_BASE]: {
      includeAll,
      included: new Set(),
    },
    [ChatContextSourceType.POLICY]: { includeAll, included: new Set() },
    [ChatContextSourceType.CONTROL]: { includeAll, included: new Set() },
    [ChatContextSourceType.EVIDENCE]: {
      includeAll,
      included: new Set(),
      type: {},
    },
    [ChatContextSourceType.COMPANY]: { includeAll, included: new Set() },
    [ChatContextSourceType.DOCUMENT_GROUP]: { includeAll, included: new Set() },
  }

  return chatContext
}

export const getChatContextFromGRPC = (
  chatContextGRPC?: ChatContextGRPC,
): ChatContext => {
  const chatContext: ChatContext = getDefaultChatContext()

  if (chatContextGRPC) {
    chatContextGRPC.getSourcesList().forEach((source) => {
      const type = source.getType() as ChatContextType
      chatContext[type].includeAll = source.getIncludeAll()
      if (type === ChatContextSourceType.EVIDENCE) {
        source
          .getIncludedIds()
          ?.getEvidenceType()
          ?.getIdsList()
          .forEach((id) => {
            if (
              chatContext[ChatContextSourceType.EVIDENCE].included !== undefined
            ) {
              chatContext[ChatContextSourceType.EVIDENCE].included.add(
                id.getId(),
              )
            }
            if (
              chatContext[ChatContextSourceType.EVIDENCE].type !== undefined
            ) {
              chatContext[ChatContextSourceType.EVIDENCE].type[id.getId()] =
                id.getFor()
            }
          })
      } else {
        source
          .getIncludedIds()
          ?.getIdType()
          ?.getIdsList()
          .forEach((id) => {
            const contextForType = chatContext[type]
            if (
              contextForType !== undefined &&
              contextForType.included !== undefined
            ) {
              contextForType.included.add(id)
            }
          })
      }
    })
  }

  return chatContext
}
