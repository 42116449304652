import React, { useCallback, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  Question,
  UploadQuestionnaireRequest,
  GetQuestionnaireRequest,
  Questionnaire,
  GetQuestionnairesResponse,
  DeleteQuestionnaireRequest,
  CreateOrUpdateAnswerRequest,
  Answer,
  QUESTIONNAIRE_ANSWER_TYPE,
  GetAccountBalanceResponse,
  ValidateAccountBalanceRequest,
  AnswerAgainRequest,
  AnswerAgainResponse,
  AddSqToKbRequest,
  UploadKnowledgeBaseRequest,
  UploadQuestionnaireResponse,
  QUESTIONNAIRE_TYPE,
  QuestionKbSources,
  GetQuestionKbSourcesRequest,
  GetAnswerSourcesResponse,
  GetAnswerSourcesRequest,
  AnswerQuestionnaireAgainRequest,
} from '@trustero/trustero-api-web/lib/questionnaire/questionnaire_pb'
import { ChatCitation } from '@trustero/trustero-api-web/lib/chatcontext/chatcontext_pb'
import { ChatContextSourceType } from '@trustero/trustero-api-web/lib/chatcontext/chatcontext_pb'
import { QuestionnaireServicePromiseClient } from '@trustero/trustero-api-web/lib/questionnaire/questionnaire_grpc_web_pb'
import { useAuthorizedGrpcClient } from 'src/adapter'
import { BoolValue } from 'google-protobuf/google/protobuf/wrappers_pb'
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'
import { useSwrImmutableGrpc } from 'src/components/async/useSwrImmutableGrpc'
import { Empty } from 'google-protobuf/google/protobuf/empty_pb'
import { GrpcResponse, NewGrpcResponse } from 'src/components/async/hooks/types'
import { useGrpcRevalidateByMethod } from 'src/components'
import log from 'loglevel'
import { useSwrGrpc } from 'src/components/async/useSwrGrpc'
import { useAnalytics } from 'src/analytics/useAnalytics'
import { useConfirmationModal } from 'src/components/ModalForms'
import {
  HardLimitBody,
  SoftLimitBody,
} from 'src/components/Reusable/Cost/Cost.components'
import { useAuth } from 'src/context/authContext'
import { useThrobberContext } from 'src/Throbber'
import {
  ALLOW_TRANSACTION,
  MODEL_TYPE,
} from '@trustero/trustero-api-web/lib/common/model_pb'
import { useAuthorizedGrpcClientWithContentUpdate } from 'src/adapter/grpcClient'
import { AccountPromiseClient } from '@trustero/trustero-api-web/lib/account/account_grpc_web_pb'
import {
  BuyerOrigin,
  SendWelcomeEmailRequest,
} from '@trustero/trustero-api-web/lib/account/account_pb'
import { getCreateChatContextRequest } from 'src/components/Reusable/ChatContext/chatContext.utils'
import { ChatContext } from 'src/components/Reusable/ChatContext/useChatContext.hooks'
import {
  generatePermalinkFromId,
  useCompanyInfoPermalink,
} from 'src/components/PageLayout/Permalink/generatePermalink'
import { runAuditBotAnimation } from '../AuditBot/AuditBot.helpers'
import { getQuestionnaireAsFile } from './modals/securityQuestionnaireModals.helpers'
import { useAnswerQuestionQuestionnaire } from './askQuestion/AnswerQuestion.context'

type QuestionsType = {
  question: string
  answer?: string
}

type UploadQuestionnaireProps = {
  questions: QuestionsType[]
  name?: string
  org?: string
  dueDate?: Timestamp
  answerType: QUESTIONNAIRE_ANSWER_TYPE
  chatContext?: ChatContext
}

export const useUploadSecurityQuestionnaire = (
  hideThrobber?: boolean,
): (({
  questions,
  name,
  org,
  dueDate,
  answerType,
  chatContext,
}: {
  questions: QuestionsType[]
  answerType: QUESTIONNAIRE_ANSWER_TYPE
  name?: string
  org?: string
  dueDate?: Timestamp
  chatContext?: ChatContext
}) => Promise<UploadQuestionnaireResponse | undefined>) => {
  const questionnaireClient = useAuthorizedGrpcClient(
    QuestionnaireServicePromiseClient,
  )
  const { setThrobberState } = useThrobberContext()
  const mutate = useInvalidateQuestionnaireCache()
  const { setActiveQuestionnaireId } = useAnswerQuestionQuestionnaire()

  const accountValidator = useAccountBalanceValidator({
    softLimitConfirmText: 'Continue with AI GRC Q&A',
  })

  const uploadQuestionnaire = useCallback(
    async ({
      questions,
      name,
      org,
      dueDate,
      answerType,
      chatContext,
    }: UploadQuestionnaireProps) => {
      const requestQuestions = questions.map((elem) =>
        new Question().setAnswer(elem.answer || '').setQuestion(elem.question),
      )

      const request = new UploadQuestionnaireRequest()
      request.setName(name || '')
      request.setOrganization(org || '')
      request.setQuestionsList(requestQuestions)
      request.setDueDate(dueDate)
      request.setQuestionnaireAnswerType(answerType)

      if (chatContext) {
        request.setChatContext(getCreateChatContextRequest(chatContext))
      }

      const res = await questionnaireClient.uploadQuestionnaire(request)
      await mutate()
      return res
    },
    [questionnaireClient, mutate],
  )

  const onSoftLimit = useCallback(
    async ({
      questions,
      name,
      org,
      dueDate,
      answerType,
      chatContext,
    }: {
      questions: QuestionsType[]
      name?: string
      org?: string
      dueDate?: Timestamp
      answerType: QUESTIONNAIRE_ANSWER_TYPE
      chatContext?: ChatContext
    }) => {
      const res = await uploadQuestionnaire({
        questions,
        name,
        org,
        dueDate,
        answerType,
        chatContext,
      })

      if (res) {
        setActiveQuestionnaireId(res.getQuestionnaireId())
      }
    },
    [uploadQuestionnaire, setActiveQuestionnaireId],
  )

  const fireSecurityQuestionnaire = useCallback(
    async ({
      questions,
      name,
      org,
      dueDate,
      answerType,
      chatContext,
    }: UploadQuestionnaireProps) => {
      !hideThrobber &&
        runAuditBotAnimation(
          setThrobberState,
          'Initiated AI GRC Q&A. This may take a few minutes.',
        )

      const isAccountValid = await accountValidator({
        answerType,
        callback: () =>
          onSoftLimit({
            questions,
            name,
            org,
            dueDate,
            answerType,
            chatContext,
          }),
      })

      if (isAccountValid) {
        return await uploadQuestionnaire({
          questions,
          name,
          org,
          dueDate,
          answerType,
          chatContext,
        })
      }
    },
    [
      setThrobberState,
      hideThrobber,
      accountValidator,
      uploadQuestionnaire,
      onSoftLimit,
    ],
  )

  return fireSecurityQuestionnaire
}

export const useUpdateAnswer = (): ((answer: Answer) => Promise<void>) => {
  const questionnaireClient = useAuthorizedGrpcClient(
    QuestionnaireServicePromiseClient,
  )

  return async (answer: Answer) => {
    const req = new CreateOrUpdateAnswerRequest().setAnswer(answer)
    await questionnaireClient.createOrUpdateAnswer(req)
  }
}

export const useAddToKnowledgeBase = (): ((
  questionnaire: Questionnaire,
) => Promise<void>) => {
  const mutate = useInvalidateQuestionnaireCache()
  const questionnaireClient = useAuthorizedGrpcClient(
    QuestionnaireServicePromiseClient,
  )

  return async (questionnaire) => {
    try {
      const file = await getQuestionnaireAsFile(questionnaire)
      const questionCount = questionnaire.getQuestionsList().length

      const request = new AddSqToKbRequest()
        .setKnowledgeBase(
          new UploadKnowledgeBaseRequest()
            .setFile(file)
            .setFileName(questionnaire.getName())
            .setQuestionCount(questionCount)
            .setAnswerCount(questionCount),
        )
        .setSecurityQuestionnaireId(questionnaire.getId())
      await questionnaireClient.addQuestionnaireToKnowledgeBase(request)
      mutate()
    } catch (err) {
      log.error(
        'error when attempting to add AI GRC Q&A result to knowledge base id:',
        questionnaire.getId(),
      )
    }
  }
}

export const useUpdateAnswerFromQuestion = (): ((
  question: Question,
) => Promise<void>) => {
  const updateFunc = useUpdateAnswer()

  return async (question: Question) => {
    const answer = new Answer()
    answer
      .setId(question.getAnswerId())
      .setAnswer(question.getAnswer())
      .setAcceptance(question.getAcceptance())
      .setStatus(question.getStatus())
      .setQuestionId(question.getId())

    await updateFunc(answer)
  }
}

export const useGetQuestionnaires =
  (): GrpcResponse<GetQuestionnairesResponse> => {
    const { response } = useSwrImmutableGrpc(
      QuestionnaireServicePromiseClient.prototype.getQuestionnaires,
      new Empty(),
    )

    return NewGrpcResponse(response)
  }

export const useGetQuestionnaire = ({
  id,
  allowFetch = true,
  includeDeleted = false,
}: {
  id?: string
  allowFetch?: boolean
  includeDeleted?: boolean
}): GrpcResponse<Questionnaire> => {
  const shouldFetch = !!(id && id.length) && allowFetch
  const request = new GetQuestionnaireRequest().setId(id || '')
  includeDeleted &&
    request.setIncludeDeleted(new BoolValue().setValue(includeDeleted))
  const { response } = useSwrImmutableGrpc(
    QuestionnaireServicePromiseClient.prototype.getQuestionnaire,
    request,
    shouldFetch,
  )

  return NewGrpcResponse(response)
}

export const useDeleteQuestionnaire = (): ((id: string) => Promise<void>) => {
  const questionnaireClient = useAuthorizedGrpcClient(
    QuestionnaireServicePromiseClient,
  )

  return async (id: string) => {
    const req = new DeleteQuestionnaireRequest().setId(id)
    await questionnaireClient.deleteQuestionnaire(req)
  }
}

export const useAnswerSources = (
  questionId: string,
  questionnaireType?: QUESTIONNAIRE_TYPE,
  shouldFetch = false,
): GrpcResponse<GetAnswerSourcesResponse> => {
  const request = new GetAnswerSourcesRequest().setQuestionId(questionId)
  questionnaireType && request.setType(questionnaireType)

  const { response } = useSwrImmutableGrpc(
    QuestionnaireServicePromiseClient.prototype.getAnswerSources,
    request,
    shouldFetch,
  )

  return NewGrpcResponse(response)
}

export const useQuestionKbSources = (
  questionId: string,
  questionnaireType?: QUESTIONNAIRE_TYPE,
  shouldFetch = false,
): GrpcResponse<QuestionKbSources> => {
  const request = new GetQuestionKbSourcesRequest().setQuestionId(questionId)
  questionnaireType && request.setType(questionnaireType)

  const { response } = useSwrImmutableGrpc(
    QuestionnaireServicePromiseClient.prototype.getQuestionKbSources,
    request,
    shouldFetch,
  )

  return NewGrpcResponse(response)
}

export const useUpdateQuestionnaire = (): ((
  questionnaire: Questionnaire,
) => Promise<void>) => {
  const questionnaireClient = useAuthorizedGrpcClient(
    QuestionnaireServicePromiseClient,
  )
  const mutate = useInvalidateQuestionnaireCache()

  return useCallback(
    async (questionnaire: Questionnaire) => {
      await questionnaireClient.updateQuestionnaire(questionnaire)
      await mutate()
    },
    [questionnaireClient, mutate],
  )
}

export const useAnswerQuestionAgain = (
  question: Question,
): (() => Promise<AnswerAgainResponse | void>) => {
  // we will refetch the AI GRC Q&A result after starting the task and will get the loading state for free
  const { mutate, data } = useGetQuestionnaire({
    id: question.getQuestionnaireId(),
  })
  const questionnaireClient = useAuthorizedGrpcClient(
    QuestionnaireServicePromiseClient,
  )

  const accountValidator = useAccountBalanceValidator({
    softLimitConfirmText: 'Continue with AI GRC Q&A',
  })

  const answerQuestionAgain =
    useCallback(async (): Promise<AnswerAgainResponse> => {
      const request = new AnswerAgainRequest().setQuestion(question)
      const response = await questionnaireClient.answerQuestionAgain(request)
      await mutate()
      return response
    }, [questionnaireClient, mutate, question])

  return useCallback(async (): Promise<AnswerAgainResponse | void> => {
    if (!data) {
      return
    }
    const isAccountValid = await accountValidator({
      answerType: getAnswerType(data.getQuestionnaireType()),
      callback: () => void answerQuestionAgain(),
    })
    if (isAccountValid) {
      return await answerQuestionAgain()
    }
  }, [accountValidator, answerQuestionAgain, data])
}

// to *slightly* improve our cache invalidation strategy
// we will determine what route the user is on to invalidate
// the correct RPC
export const useInvalidateQuestionnaireCache = (): (() => Promise<void>) => {
  const mutateFunc = useGrpcRevalidateByMethod()

  return useCallback(async () => {
    const request = window.location.href.includes('index')
      ? QuestionnaireServicePromiseClient.prototype.getQuestionnaires
      : QuestionnaireServicePromiseClient.prototype.getQuestionnaire

    try {
      await mutateFunc(request)
    } catch (err) {
      log.error('Error when invalidating AI GRC Q&A cache', err)
    }
  }, [mutateFunc])
}

export const useSecurityQuestionnaireBalance =
  (): GrpcResponse<GetAccountBalanceResponse> => {
    const { response } = useSwrGrpc(
      QuestionnaireServicePromiseClient.prototype.getAccountBalance,
      new Empty(),
    )

    return NewGrpcResponse(response)
  }

export const useSendWelcomeEmail = (): ((email: string) => Promise<void>) => {
  const AccountClient =
    useAuthorizedGrpcClientWithContentUpdate(AccountPromiseClient)
  return async (email: string) => {
    try {
      const request = new SendWelcomeEmailRequest()
        .setEmail(email)
        .setBuyerOrigin(BuyerOrigin.SECURITY_QUESTIONNAIRE)
      await AccountClient.sendSaasBuyerWelcomeEmail(request)
    } catch (e) {
      log.error(`Error in AI GRC Q&A hooks send welcome email ${email}`, e)
    }
  }
}

export type AnswerQuestionnaireAgainResponse = {
  isLoading: boolean
  error: string | null
  mutate: (
    questionnaireId: string,
    chatContext: ChatContext,
  ) => Promise<UploadQuestionnaireResponse | undefined>
}

export const useAnswerQuestionnaireAgain =
  (): AnswerQuestionnaireAgainResponse => {
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState<string | null>(null)
    const questionnaireClient = useAuthorizedGrpcClient(
      QuestionnaireServicePromiseClient,
    )

    return useMemo(
      () => ({
        isLoading,
        error,
        mutate: async (
          questionnaireId: string,
          chatContext: ChatContext,
        ): Promise<UploadQuestionnaireResponse | undefined> => {
          try {
            setIsLoading(true)
            setError(null)
            const request = new AnswerQuestionnaireAgainRequest()
              .setQuestionnaireId(questionnaireId)
              .setChatContext(getCreateChatContextRequest(chatContext))
            const response = await questionnaireClient.answerQuestionnaireAgain(
              request,
            )
            return response
          } catch (err) {
            log.error('Error when rerunning AI GRC Q&A', err)
            setError(
              err instanceof Error ? err.message : 'Error rerunning AI GRC Q&A',
            )
          } finally {
            setIsLoading(false)
          }
        },
      }),
      [isLoading, questionnaireClient, error],
    )
  }

export const useCitationLink = (citation: ChatCitation): string => {
  const params = useParams()
  const companyLink = useCompanyInfoPermalink()

  const modelType = useMemo(() => {
    const sourceType = citation.getType()
    switch (sourceType) {
      case ChatContextSourceType.CONTROL:
        return MODEL_TYPE.CONTROL
      case ChatContextSourceType.POLICY:
        return MODEL_TYPE.POLICY
      case ChatContextSourceType.EVIDENCE:
        return MODEL_TYPE.EVIDENCE
      default:
        return null
    }
  }, [citation])

  return useMemo(() => {
    if (citation.getType() === ChatContextSourceType.COMPANY) {
      return companyLink
    }
    if (modelType) {
      return generatePermalinkFromId({
        pageContext: params.pageContext as string,
        modelType,
        modelId: citation.getReferenceId(),
        isInternalLink: false,
      })
    } else {
      return ''
    }
  }, [params, citation, modelType, companyLink])
}

export type AccountBalanceValidatorProps = {
  softLimitConfirmText: string
}

export type AccountBalanceValidatorCallback = {
  answerType: QUESTIONNAIRE_ANSWER_TYPE
  callback: () => Promise<void> | void
}

export const useAccountBalanceValidator = ({
  softLimitConfirmText,
}: AccountBalanceValidatorProps): ((
  props: AccountBalanceValidatorCallback,
) => Promise<boolean>) => {
  const { accountId } = useAuth()
  const { events } = useAnalytics()
  const questionnaireClient = useAuthorizedGrpcClient(
    QuestionnaireServicePromiseClient,
  )

  const hardLimit = useConfirmationModal({
    title: 'Time to Get Trustero AI Back in Action!',
    body: <HardLimitBody />,
    hideCancel: true,
    confirmText: 'Dismiss',
    analyticsEvent: events.V_AI_GRC_QA_HARD_LIMIT,
    analyticsEventData: {
      accountId,
    },
  })

  const softLimit = useSoftLimit(softLimitConfirmText)

  return useCallback(
    async ({ answerType, callback }: AccountBalanceValidatorCallback) => {
      const validationRequest =
        new ValidateAccountBalanceRequest().setQuestionnaireAnswerType(
          answerType,
        )
      const accountValidationResponse =
        await questionnaireClient.validateAccountBalance(validationRequest)
      const allowKb = accountValidationResponse.getAllowKnowledgeBase()
      const allowPlatform = accountValidationResponse.getAllowPlatform()
      const allowChatContext = accountValidationResponse.getAllowChatContext()
      const allowChatContextAndHistory =
        accountValidationResponse.getAllowChatContextAndHistory()

      const isChatContext = answerType === QUESTIONNAIRE_ANSWER_TYPE.CONTEXT
      const isChatContextAndHistory =
        answerType === QUESTIONNAIRE_ANSWER_TYPE.CONTEXT_AND_HISTORY

      const isChatContextHardLimit =
        (isChatContext && allowChatContext === ALLOW_TRANSACTION.HARD_BLOCK) ||
        (isChatContextAndHistory &&
          allowChatContextAndHistory === ALLOW_TRANSACTION.HARD_BLOCK)

      const isChatContextSoftLimit =
        (isChatContext && allowChatContext === ALLOW_TRANSACTION.SOFT_BLOCK) ||
        (isChatContextAndHistory &&
          allowChatContextAndHistory === ALLOW_TRANSACTION.SOFT_BLOCK)

      // TODO: remove non-chat-context semantics once deployed
      if (
        isChatContextHardLimit ||
        allowKb === ALLOW_TRANSACTION.HARD_BLOCK ||
        (answerType !== QUESTIONNAIRE_ANSWER_TYPE.KNOWLEDGE_BASE &&
          !(isChatContext || isChatContextAndHistory) &&
          allowPlatform === ALLOW_TRANSACTION.HARD_BLOCK)
      ) {
        hardLimit()
        return false
      } else if (
        isChatContextSoftLimit ||
        allowKb === ALLOW_TRANSACTION.SOFT_BLOCK ||
        allowPlatform === ALLOW_TRANSACTION.SOFT_BLOCK
      ) {
        softLimit(callback)
        return false
      }
      return true
    },
    [hardLimit, softLimit, questionnaireClient],
  )
}

const useSoftLimit = (confirmText: string) => {
  const { accountId } = useAuth()
  const { events } = useAnalytics()

  return useConfirmationModal<[() => Promise<void> | void]>({
    title: "Let's Keep Trustero AI Running Smoothly!",
    body: <SoftLimitBody />,
    confirmText,
    analyticsEvent: events.V_AI_GRC_QA_SOFT_LIMIT,
    analyticsEventData: {
      accountId,
    },
    onConfirmCB: async (callback) => {
      try {
        await callback()
      } catch (err) {
        log.error(err)
      }
    },
  })
}

const getAnswerType = (
  questionnaireType: QUESTIONNAIRE_TYPE,
): QUESTIONNAIRE_ANSWER_TYPE => {
  switch (questionnaireType) {
    case QUESTIONNAIRE_TYPE.KB_ONLY:
      return QUESTIONNAIRE_ANSWER_TYPE.KNOWLEDGE_BASE
    case QUESTIONNAIRE_TYPE.QC_BASIC:
      return QUESTIONNAIRE_ANSWER_TYPE.BOTH_PREFER_KB
    case QUESTIONNAIRE_TYPE.QC_ADVANCED:
      return QUESTIONNAIRE_ANSWER_TYPE.BOTH_PREFER_PLATFORM
    case QUESTIONNAIRE_TYPE.CHAT_CONTEXT:
      return QUESTIONNAIRE_ANSWER_TYPE.CONTEXT
    case QUESTIONNAIRE_TYPE.CHAT_CONTEXT_AND_HISTORY:
      return QUESTIONNAIRE_ANSWER_TYPE.CONTEXT_AND_HISTORY
  }
}
