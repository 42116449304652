import { StandardButton } from 'src/components/Reusable/Buttons'
import styled from 'styled-components/macro'

export const OIDCEmailButton = styled(StandardButton)`
  margin-top: 2px;
  margin-bottom: 24px;
  padding: 1px 16px;
`

export const OIDCEmailText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-grow: 1;
  color: white;
`
