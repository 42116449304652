import styled from 'styled-components/macro'
import palette from 'src/designSystem/variables/palette'

export const AuditBotThrobberIcon = styled.img`
  height: 120px;
  width: 120px;
`

export const AuditBotThrobberText = styled.div`
  color: ${palette.neutral.white};
  margin-top: 0.5rem;
  margin-bottom: 2.5rem;
  font-size: 1.25rem;
`
