import React, { Fragment } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { PERMISSIONS } from 'src/config/roleConfig'
import { useNavigateContext } from '../../Navigation/NavigateContext'
import { TextButton } from '../../Buttons/TextButton'
import { FilterParam } from './FilterBar.types'

export const ClearAllFilters = (): JSX.Element => {
  const location = useLocation()
  const { navigateState } = useNavigateContext()
  const fallBackNavigate = useNavigate()
  const navigate = navigateState.navigate || fallBackNavigate

  /**
   * Current url whose query strings are filtered to include only FilterParams
   */
  const strippedUrl = queryString.pick(
    `${location.pathname}${location.search}`,
    Object.values(FilterParam),
    {
      arrayFormat: 'bracket',
    },
  )
  /** Object of all search Params */
  const allSearchParams = queryString.parse(location.search, {
    arrayFormat: 'bracket',
  })
  /** Object of applied FilterParams */
  const filterParamsFallback = queryString.parseUrl(strippedUrl, {
    arrayFormat: 'bracket',
  }).query

  const filterParams = navigateState.parsedQueryParams || filterParamsFallback

  /** Have any FilterParams have been applied */
  const hasAppliedFilters = Object.keys(filterParams).length > 0

  if (!hasAppliedFilters) return <Fragment />

  // Construct the url with the page number reset
  const url = queryString.stringifyUrl(
    {
      url: location.pathname,
      query: allSearchParams,
    },
    { arrayFormat: 'bracket' },
  )
  // Get all FilterParams except PAGE
  const excludeParams = Object.values(FilterParam).filter(
    (param) => param != FilterParam.PAGE,
  )

  const clearFilters = () => {
    navigate(
      queryString.exclude(url, excludeParams, { arrayFormat: 'bracket' }),
      { replace: true },
    )
  }

  return (
    <TextButton requiredPermissions={[PERMISSIONS.READ]} onClick={clearFilters}>
      Clear All
    </TextButton>
  )
}
