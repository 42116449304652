import { NAV_ITEM_KEYS } from 'src/navbar/Navbar.constants'

export enum NAVBAR_ACCORDION_KEYS {
  HOME = '1',
  ANALYZE = '2',
  RISK_MANAGEMENT = '3',
  COMPLIANCE = '4',
  INTEGRATIONS = '5',
  SETTINGS = '6',
}

export const NAVBAR_ACCORDION_TITLES = Object.freeze({
  HOME: 'Home',
  COMPLIANCE: 'COMPLIANCE',
  AUDIT: 'AUDIT',
  ANALYZE: 'ANALYZE',
  SETTINGS: 'SETTINGS',
  RISK_MANAGEMENT: 'RISK',
  INTEGRATIONS: 'INTEGRATIONS',
})

export const NAVBAR_ACCORDION_CONTENTS = {
  [NAVBAR_ACCORDION_KEYS.HOME]: [
    NAV_ITEM_KEYS.DASHBOARD,
    NAV_ITEM_KEYS.SCOPE,
    NAV_ITEM_KEYS.COMPANY_INFO,
    NAV_ITEM_KEYS.HELP,
  ],
  [NAVBAR_ACCORDION_KEYS.ANALYZE]: [
    NAV_ITEM_KEYS.TRUSTERO_INTELLIGENCE,
    NAV_ITEM_KEYS.AI_GRC_QA,
    NAV_ITEM_KEYS.AI_EXAMINATION_AND_TEST,
    NAV_ITEM_KEYS.AI_SOC2_REPORT_SUMMARY,
  ],
  [NAVBAR_ACCORDION_KEYS.RISK_MANAGEMENT]: [
    NAV_ITEM_KEYS.RISKS,
    NAV_ITEM_KEYS.VENDORS,
    NAV_ITEM_KEYS.AI_SOC2_REPORT_SUMMARY,
  ],
  [NAVBAR_ACCORDION_KEYS.COMPLIANCE]: [
    NAV_ITEM_KEYS.ROADMAP,
    NAV_ITEM_KEYS.POLICIES,
    NAV_ITEM_KEYS.CONTROLS,
    NAV_ITEM_KEYS.AUDITS,
    NAV_ITEM_KEYS.REQUESTS,
    NAV_ITEM_KEYS.EVIDENCE,
    NAV_ITEM_KEYS.AI_EXAMINATION_AND_TEST,
  ],
  [NAVBAR_ACCORDION_KEYS.INTEGRATIONS]: [
    NAV_ITEM_KEYS.RECEPTORS,
    NAV_ITEM_KEYS.SERVICES,
    NAV_ITEM_KEYS.DOCUMENT_MANAGEMENT,
    NAV_ITEM_KEYS.GRC_PLATFORMS,
  ],
  [NAVBAR_ACCORDION_KEYS.SETTINGS]: [
    NAV_ITEM_KEYS.USERS,
    NAV_ITEM_KEYS.ACCOUNT_SETTINGS,
    NAV_ITEM_KEYS.ACCOUNTS,
    NAV_ITEM_KEYS.MANAGE_DOCUMENTS,
    NAV_ITEM_KEYS.USAGE,
  ],
}
