import React, { useCallback } from 'react'
import {
  ACTOR_TYPE,
  Document,
} from '@trustero/trustero-api-web/lib/attachment/attachment_pb'
import { LINKER_MODEL_IDS } from 'src/lib/Linkers/Linkers.constants'
import { useHasLinker } from 'src/pages/Receptors/receptors.hooks'
import { BrokenLinkIcon } from 'src/components/Icons/Basic/BrokenLinkIcon'
import { MIME_TYPE } from 'src/Utils/globalEnums'
import { Content } from 'src/context/ContentContext'
import {
  CsvIcon,
  ExcelIcon,
  GoogleDocsIcon,
  GoogleDriveIcon,
  GoogleSheetsIcon,
  GoogleSlidesIcon,
  ImageIcon,
  KeynoteIcon,
  LinkIcon,
  NumbersIcon,
  PagesIcon,
  PdfIcon,
  PowerpointIcon,
  TextIcon,
  WordIcon,
} from '../../Icons/FileType'
import { ContentStaticHelper } from '../../../context/Content/statichelpers'
import { useContent } from '../../../context/hooks'
import { UnknownSaaS } from '../../Icons/Dependencies'

export type SVGComponent = React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>

export const useFileTypeIcon = ({
  document,
  mime,
}: {
  document?: Document.AsObject
  mime?: string
}): SVGComponent => {
  const content = useContent()
  const { data } = useHasLinker(
    document?.actor as LINKER_MODEL_IDS,
    !!document && document.actortype === ACTOR_TYPE.LINKER,
  )

  if (document === undefined && mime === undefined) {
    throw Error("Must pass the document, or the mime to 'useFileTypeIcon'")
  }

  if (document && document.actortype === ACTOR_TYPE.RECEPTOR) {
    return ContentStaticHelper.getObjById(content, document.actor).logo
  }
  if (document && document.actortype === ACTOR_TYPE.LINKER) {
    return getLinkerIcon(
      document.actor as LINKER_MODEL_IDS,
      data?.getHasLinker() || false,
    )
  }

  return getMimeTypeIcon((document?.mime || mime) as string)
}

export type EvidenceGroupFileTypeIcon = {
  isAutomated: boolean
  mime: string
  serviceModelId?: string
  content: Content
}

const getEvidenceGroupFileTypeIcon = ({
  isAutomated,
  mime,
  serviceModelId,
  content,
}: EvidenceGroupFileTypeIcon): SVGComponent => {
  if (isAutomated) {
    if (mime.startsWith('multipart/tr-mixed')) {
      const type = mime.split('; ')[1]
      if (type !== 'application/protobuf') {
        return getMimeTypeIcon(type)
      }
    }
    return serviceModelId
      ? ContentStaticHelper.getObjById(content, serviceModelId)?.logo ||
          UnknownSaaS
      : UnknownSaaS
  }

  return getMimeTypeIcon(mime)
}

export const useFileTypeIconForEvidence = (
  isAutomated: boolean,
  mime: string,
  serviceModelId?: string,
): SVGComponent => {
  const content = useContent()

  return getEvidenceGroupFileTypeIcon({
    isAutomated,
    mime,
    serviceModelId,
    content,
  })
}

/**
 * Similar to useFileTypeIconForEvidence, but returns a function that takes a mime type and returns an SVG component.
 * This is useful for generating icons for a list of evidence groups.
 */
export const useFileTypeIconForEvidenceGroups = (): ((
  isAutomated: boolean,
  mime: string,
  serviceModelId?: string,
) => SVGComponent) => {
  const content = useContent()

  return useCallback(
    (
      isAutomated: boolean,
      mime: string,
      serviceModelId?: string,
    ): SVGComponent => {
      return getEvidenceGroupFileTypeIcon({
        isAutomated,
        mime,
        serviceModelId,
        content,
      })
    },
    [content],
  )
}

export const useActorIconForEvidence = (
  isAutomated: boolean,
  mime: string,
  actor: string,
): SVGComponent => {
  const content = useContent()

  if (isAutomated) {
    return ContentStaticHelper.getObjById(content, actor)?.logo || UnknownSaaS
  }

  return getMimeTypeIcon(mime)
}

export const getMimeTypeIcon = (mimeType: string): SVGComponent => {
  if (isCsv(mimeType)) return CsvIcon
  if (isExcel(mimeType)) return ExcelIcon
  if (isImage(mimeType)) return ImageIcon
  if (isKeynote(mimeType)) return KeynoteIcon
  if (isLink(mimeType)) return LinkIcon
  if (isNumbers(mimeType)) return NumbersIcon
  if (isPages(mimeType)) return PagesIcon
  if (isPdf(mimeType)) return PdfIcon
  if (isPowerpoint(mimeType)) return PowerpointIcon
  if (isText(mimeType)) return TextIcon
  if (isWord(mimeType)) return WordIcon
  if (isGoogleDoc(mimeType)) return GoogleDocsIcon
  if (isGoogleSheets(mimeType)) return GoogleSheetsIcon
  if (isGoogleSlides(mimeType)) return GoogleSlidesIcon
  return TextIcon
}

const isCsv = (mime: string): boolean => {
  return mime === 'text/csv'
}

const isExcel = (mime: string): boolean => {
  return (
    mime === 'application/vnd.ms-excel' ||
    mime === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  )
}

const isImage = (mime: string): boolean => {
  return mime === 'image/png' || mime === 'image/jpeg'
}

const isKeynote = (mime: string): boolean => {
  return mime === MIME_TYPE.APPLE_KEYNOTE || mime === MIME_TYPE.APPLE_KEYNOTE_2
}

export const isLink = (mime: string): boolean => {
  return mime === 'text/uri-list'
}

const isNumbers = (mime: string): boolean => {
  return mime === MIME_TYPE.APPLE_NUMBERS || mime === MIME_TYPE.APPLE_NUMBERS_2
}

const isPages = (mime: string): boolean => {
  return mime === MIME_TYPE.APPLE_PAGES || mime === MIME_TYPE.APPLE_PAGES_2
}

const isPdf = (mime: string): boolean => {
  return mime === 'application/pdf'
}

const isPowerpoint = (mime: string): boolean => {
  return (
    mime === 'application/vnd.ms-powerpoint' ||
    mime ===
      'application/vnd.openxmlformats-officedocument.presentationml.presentation'
  )
}

export const isMarkdown = (mime: string): boolean => {
  return mime === 'text/markdown'
}

const isText = (mime: string): boolean => {
  return (
    mime === 'text/rtf' ||
    mime === 'text/plain' ||
    isMarkdown(mime) ||
    mime === 'application/vnd.oasis.opendocument.text'
  )
}

const isWord = (mime: string): boolean => {
  return (
    mime === 'application/msword' ||
    mime ===
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  )
}

const isGoogleDoc = (mime: string): boolean => {
  return mime === 'application/vnd.google-apps.document'
}

const isGoogleSheets = (mime: string): boolean => {
  return mime === 'application/vnd.google-apps.spreadsheet'
}

const isGoogleSlides = (mime: string): boolean => {
  return mime === 'application/vnd.google-apps.presentation'
}

export const getLinkerIcon = (
  linkerId: LINKER_MODEL_IDS,
  isConnected: boolean,
): SVGComponent => {
  if (!isConnected) {
    return BrokenLinkIcon
  }
  switch (linkerId) {
    case LINKER_MODEL_IDS.GOOGLE_DRIVE:
      return GoogleDriveIcon
    default:
      return TextIcon
  }
}
