import { useCallback, useEffect, useState } from 'react'
import { AttachmentPromiseClient } from '@trustero/trustero-api-web/lib/attachment/attachment_grpc_web_pb'
import {
  Document as EvidenceDocument,
  Document,
  DOCUMENT_TYPE,
  GetDocumentByOIDRequest,
  GetExclusionsResponse,
  GetExclusionsRequest,
  AddDocumentRequest,
} from '@trustero/trustero-api-web/lib/attachment/attachment_pb'
import { MODEL_TYPE } from '@trustero/trustero-api-web/lib/common/model_pb'
import log from 'loglevel'
import { HTTP_METHODS } from 'src/Utils/globalEnums'
import {
  ContentID,
  URL,
} from '@trustero/trustero-api-web/lib/contentstore/contentstore_pb'
import {
  GetEvidenceIssuesRequest,
  GetEvidenceIssuesResponse,
} from '@trustero/trustero-api-web/lib/evidence/testing_pb'
import { TestingPromiseClient } from '@trustero/trustero-api-web/lib/evidence/testing_grpc_web_pb'
import {
  BoolValue,
  StringValue,
} from 'google-protobuf/google/protobuf/wrappers_pb'
import { ToastPrompts } from 'src/Utils/helpers/toast'
import { ParsedPart } from 'src/Utils/globalConstants'
import { ContentStorePromiseClient } from '@trustero/trustero-api-web/lib/contentstore/contentstore_grpc_web_pb'
import { GridColDef, GridValidRowModel } from '@mui/x-data-grid'
import { useInAudit } from 'src/context/AuditContext'
import { isGrpcError } from 'src/Utils/isGrpcError'
import {
  getIsMultiPartMixedMime,
  parseMultiPartResponse,
} from 'src/Utils/globalHelpers'
import {
  getCsvColumns,
  getCsvRows,
  getExcelColumns,
  getExcelRows,
  getExcelSheet,
} from 'src/Utils/Evidence/evidence.helpers'
import { ColumnHeaderTooltip } from 'src/Utils/Evidence/evidence.types'
import { useSwrImmutableGrpc } from '../useSwrImmutableGrpc'
import { GrpcResponse, NewGrpcResponse } from '../hooks/types'
import { useSwrGrpc } from '../useSwrGrpc'
import { useAuthorizedGrpcClient } from '../../../adapter'

// Hook for fetching a single document by ID.
export const useDocument = ({
  documentId,
  includeBody = false,
  shouldFetch = true,
}: {
  documentId: string
  includeBody?: boolean
  shouldFetch?: boolean
}): GrpcResponse<Document> => {
  const { auditId } = useInAudit()

  const request = new GetDocumentByOIDRequest()
    .setId(documentId)
    .setIncludeBody(includeBody)

  if (auditId) {
    request.setAuditId(new StringValue().setValue(auditId))
  }

  const { response } = useSwrGrpc(
    AttachmentPromiseClient.prototype.getDocumentByOID,
    request,
    shouldFetch || !!documentId,
  )
  return NewGrpcResponse(response)
}

export const useAssociateDLREvidence = (): ((
  requestId: string,
  documentId: string,
  fetchDocumentBody: () => Promise<Uint8Array | string | undefined>,
) => Promise<void>) => {
  const client = useAuthorizedGrpcClient(AttachmentPromiseClient)
  const toEvidence = (requestId: string, document: Document) => {
    return new EvidenceDocument()
      .setSubjectmodelid('')
      .setDoctype(DOCUMENT_TYPE.EVIDENCE)
      .setMime(document.getMime())
      .setBody(document.getBody())
      .setCaption(document.getCaption())
      .setActor(document.getActor())
      .setActortype(document.getActortype())
      .setRelevantdate(document.getRelevantdate())
      .setRequestid(requestId)
      .setSubjectmodeltype(MODEL_TYPE.CONTROL)
  }

  return async (
    requestId: string,
    documentId: string,
    fetchDocumentBody: () => Promise<Uint8Array | string | undefined>,
  ) => {
    try {
      const document = await client.getDocumentByOID(
        new GetDocumentByOIDRequest().setId(documentId),
      )
      const body = await fetchDocumentBody()
      document.setBody(body ?? '')
      await client.addDocument(
        new AddDocumentRequest()
          .setDocument(toEvidence(requestId, document))
          .setOverrideActor(new BoolValue().setValue(true))
          .setOverrideActorType(new BoolValue().setValue(true)),
      )
    } catch (error) {
      log.error('Error when adding evidence.', error)
    }
  }
}

export const useGetLinkedDocumentUrl = (
  contentId: string,
): (() => Promise<URL | undefined>) => {
  const { auditId } = useInAudit()
  const client = useAuthorizedGrpcClient(ContentStorePromiseClient)

  return useCallback(async () => {
    if (!contentId) {
      throw Error('No content id provided in useGetDownloadUrl')
    }
    try {
      const request = new ContentID().setContentId(contentId)

      if (auditId) {
        request.setAuditId(new StringValue().setValue(auditId))
      }

      const res = await client.getContentUrl(request)
      return res
    } catch (err) {
      log.error(
        `error getting linked content url, contentId: ${contentId} err:`,
        contentId,
        err,
      )
    }
  }, [auditId, client, contentId])
}

export const useGetDownloadUrl = (
  contentId: string,
): (() => Promise<URL | undefined>) => {
  const { auditId } = useInAudit()
  const client = useAuthorizedGrpcClient(ContentStorePromiseClient)

  return useCallback(async () => {
    if (!contentId) {
      throw Error('No content id provided in useGetDownloadUrl')
    }
    try {
      const request = new ContentID().setContentId(contentId)

      if (auditId) {
        request.setAuditId(new StringValue().setValue(auditId))
      }

      const res = await client.getDownloadURL(request)
      return res
    } catch (err) {
      log.error(
        `error getting content store download url, contentId: ${contentId} err:`,
        contentId,
        err,
      )
    }
  }, [client, contentId, auditId])
}

export const useFetchDocumentBody = (
  contentId: string,
  mime: string,
): (() => Promise<Uint8Array | string | undefined>) => {
  const getDownloadURL = useGetDownloadUrl(contentId)

  return useCallback(async () => {
    const url = await getDownloadURL()
    if (!url) {
      throw new Error(ToastPrompts.DOC_DOWNLOAD_ERROR)
    }
    const headers = url.getHeadersMap().getEntryList()

    const response: Response = await fetch(url.getUrl(), {
      method: HTTP_METHODS.GET,
      headers: new Headers(headers as unknown as HeadersInit),
    })
    if (!response || response.status !== 200) {
      throw new Error(ToastPrompts.DOC_DOWNLOAD_ERROR)
    }
    let body: string | Uint8Array | ParsedPart[]
    if (mime.startsWith('text/') && mime !== 'text/csv') {
      body = await response.text()
    } else {
      const bodyBlob = await response.blob()
      const bodyBytes = new Uint8Array(
        await new Response(bodyBlob).arrayBuffer(),
      )
      body = bodyBytes
    }
    return body
  }, [getDownloadURL, mime])
}

export const useFetchDocumentBodyAllowMultipart = (
  contentId: string,
  mime: string,
): (() => Promise<Uint8Array | string | ParsedPart[] | undefined>) => {
  const getDownloadURL = useGetDownloadUrl(contentId)

  return useCallback(async () => {
    const url = await getDownloadURL()
    if (!url) {
      throw new Error(ToastPrompts.DOC_DOWNLOAD_ERROR)
    }
    const headers = url.getHeadersMap().getEntryList()

    const response: Response = await fetch(url.getUrl(), {
      method: HTTP_METHODS.GET,
      headers: new Headers(headers as unknown as HeadersInit),
    })
    if (!response || response.status !== 200) {
      throw new Error(ToastPrompts.DOC_DOWNLOAD_ERROR)
    }
    let body: string | Uint8Array | ParsedPart[]
    if (getIsMultiPartMixedMime(mime)) {
      if (!response.body) {
        throw new Error(ToastPrompts.DOC_DOWNLOAD_ERROR)
      }
      const parts = await parseMultiPartResponse(response.body, mime)
      body = parts
    } else if (mime.startsWith('text/') && mime !== 'text/csv') {
      body = await response.text()
    } else {
      const bodyBlob = await response.blob()
      const bodyBytes = new Uint8Array(
        await new Response(bodyBlob).arrayBuffer(),
      )
      body = bodyBytes
    }
    return body
  }, [getDownloadURL, mime])
}

export const useEvidenceIssues = (
  evidenceId: string,
  evidenceTestResultId: string,
): GrpcResponse<GetEvidenceIssuesResponse> => {
  const request = new GetEvidenceIssuesRequest()
    .setDocumentid(new StringValue().setValue(evidenceId))
    .setEvidencetestresultid(new StringValue().setValue(evidenceTestResultId))
  const { response } = useSwrImmutableGrpc(
    TestingPromiseClient.prototype.getEvidenceIssues,
    request,
  )
  return NewGrpcResponse(response)
}

export const useExclusions = (
  evidenceId: string,
  shouldFetch = true,
): GrpcResponse<GetExclusionsResponse> => {
  const request = new GetExclusionsRequest().setEvidenceid(
    new StringValue().setValue(evidenceId),
  )
  const { response } = useSwrImmutableGrpc(
    AttachmentPromiseClient.prototype.getExclusions,
    request,
    shouldFetch,
  )
  return NewGrpcResponse(response)
}

export const useEvidenceCsv = (
  contentId: string,
  shouldFetch = true,
  headerTooltip?: ColumnHeaderTooltip,
): {
  rows: GridValidRowModel[]
  columns: GridColDef[]
  isLoading: boolean
  error: string | null
} => {
  const [rows, setRows] = useState<GridValidRowModel[]>([])
  const [columns, setColumns] = useState<GridColDef[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [error, setError] = useState<string | null>(null)
  const getDownloadURL = useGetDownloadUrl(contentId)
  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = await getDownloadURL()
        if (!url) {
          throw new Error(ToastPrompts.DOC_DOWNLOAD_ERROR)
        }
        const headers = url.getHeadersMap().getEntryList()

        const response: Response = await fetch(url.getUrl(), {
          method: HTTP_METHODS.GET,
          headers: new Headers(headers as unknown as HeadersInit),
        })
        if (!response || response.status !== 200) {
          throw new Error(ToastPrompts.DOC_DOWNLOAD_ERROR)
        }
        const csvString = await response.text()
        const rows = getCsvRows(csvString)
        const columns = getCsvColumns(csvString, headerTooltip)
        setRows(rows)
        setColumns(columns)
      } catch (err) {
        setError(
          isGrpcError(err) || err instanceof Error
            ? err.message
            : 'An error occurred while fetching the test table',
        )
      } finally {
        setIsLoading(false)
      }
    }
    shouldFetch && fetchData()
  }, [getDownloadURL, contentId, shouldFetch, headerTooltip])

  return {
    rows,
    columns,
    isLoading,
    error,
  }
}

export const useEvidenceExcel = (
  contentId: string,
  shouldFetch = true,
  headerTooltip?: ColumnHeaderTooltip,
): {
  rows: GridValidRowModel[]
  columns: GridColDef[]
  isLoading: boolean
  error: string | null
} => {
  const [rows, setRows] = useState<GridValidRowModel[]>([])
  const [columns, setColumns] = useState<GridColDef[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [error, setError] = useState<string | null>(null)
  const getDownloadURL = useGetDownloadUrl(contentId)
  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = await getDownloadURL()

        if (!url) {
          throw new Error(ToastPrompts.DOC_DOWNLOAD_ERROR)
        }
        const headers = url.getHeadersMap().getEntryList()

        const response: Response = await fetch(url.getUrl(), {
          method: HTTP_METHODS.GET,
          headers: new Headers(headers as unknown as HeadersInit),
        })

        if (!response || response.status !== 200) {
          throw new Error(ToastPrompts.DOC_DOWNLOAD_ERROR)
        }

        const data = await response.arrayBuffer()
        const bytes = new Uint8Array(data)
        const sheet = getExcelSheet(bytes)

        if (sheet.length === 0) return

        const newColumns = getExcelColumns(sheet)
        const newRows = getExcelRows(sheet, newColumns)

        setColumns(newColumns)
        setRows(newRows)
      } catch (err) {
        setError(
          isGrpcError(err) || err instanceof Error
            ? err.message
            : 'An error occurred while fetching the test table',
        )
      } finally {
        setIsLoading(false)
      }
    }
    shouldFetch && fetchData()
  }, [getDownloadURL, contentId, shouldFetch, headerTooltip])

  return {
    rows,
    columns,
    isLoading,
    error,
  }
}
