import palette from 'src/designSystem/variables/palette'
import styled from 'styled-components/macro'
import { TestTableStatus } from './evidence.constants'
import { EvidenceDataGridContainer } from './ViewEvidenceForm/ViewEvidenceForm.styles'

export const DeleteEvidenceBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const TestTableDescription = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  line-height: 1;
  .markup-section {
    line-height: 1.5;
  }
`

export const TestTableResultText = styled.span<{
  $status?: TestTableStatus
}>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-size: 24px;
  ${({ $status }) =>
    $status === TestTableStatus.PASS && `color: ${palette.green[500]};`}
  ${({ $status }) =>
    $status === TestTableStatus.FAIL && `color: ${palette.magenta[600]};`}
`

export const TestTablePercentage = styled.span`
  font-size: 40px;
`

export const TestTableDescriptionSectionHeader = styled.div`
  font-weight: 500;
  font-size: 16px;
`

export const TestTableDescriptionSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const CsvDataGridContainer = styled(EvidenceDataGridContainer).attrs({
  className: 'csv-data-grid',
})`
  max-height: 400px;
  overflow: auto;

  .MuiDataGrid-row.failed {
    background-color: ${palette.magenta[100]};
    &:hover,
    &:focus,
    &:active,
    &.Mui-selected,
    &.Mui-selected:hover,
    &.Mui-selected:focus,
    &.Mui-selected:active {
      background-color: ${palette.magenta[200]};
    }

`
