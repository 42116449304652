import styled, { createGlobalStyle } from 'styled-components/macro'
import { themeGet } from '@styled-system/theme-get'
import { color } from 'styled-system'
import palette from './designSystem/variables/palette'

export const GlobalStyle = createGlobalStyle`
  body {
    ${color};
    font-family: Poppins, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overscroll-behavior: none;
  }

  p {
    margin: 0;
  }

  a {
    ${themeGet('colorStyles.link.primary')};
  }

  
  .Toastify__toast-container {
    width: fit-content;
    max-width: 50vh;
    padding: 0;
    text-align: left;
    .Toastify__toast {
      margin-bottom: ${themeGet('space.xxs')}px;
      color: ${themeGet('colors.text.default')};
      ${themeGet('textStyles.body.default')};

      &--default {
        .Toastify__progress-bar--default {
          background: ${themeGet('colors.fill.secondary.base')};
        }
      }
      &--info {
        background-color: ${themeGet('colors.bg.neutralDark')};
        .Toastify__progress-bar--info {
          background: ${themeGet('colors.fill.status.info.dark')}
        }
      }
      &--success {
        background-color: ${themeGet('colors.bg.status.success')};
        .Toastify__progress-bar--success {
          background: ${themeGet('colors.fill.status.success.light')}
        }
      }
      &--warning {
        background-color: ${themeGet('colors.bg.status.warning')};
        .Toastify__progress-bar--warning {
          background: ${themeGet('colors.fill.status.warning.extraDark')}
        }
      }
      &--error {
        background-color: ${themeGet('colors.bg.status.error')};
        .Toastify__progress-bar--error {
          background: ${themeGet('colors.fill.status.error.extraDark')}
        }
      }

      .Toastify__progress-bar {
        right: 0;
        left: initial;
        transform-origin: right;
      }
      
      .Toastify__close-button {
        color: ${themeGet('colors.text.med')};
      }
    }
  }

  /* Throbber styles */
  .modal-backdrop.throbber {
    z-index: 10000;
  }
  .fade.modal.show.throbber {
    z-index: 10001;
  }
  .modal-content.throbber {
    align-items: center;
    border: none;
    background: transparent;
  }

  /* Bootstrap dropdown styles */
  .dropdown {
    .dropdown-menu {
      max-width: 500px;
      max-height: 525px;
      overflow: auto;
      cursor: pointer;
      
      .dropdown-item {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        color: ${themeGet('colors.text.default')} !important;
        :hover, :focus {
          background-color: ${palette.neutral['50']};
        }
        :active {
          background-color: ${palette.neutral['100']};
        }
      }
    }
  }

  /* Bootstrap popover styles */
  .popover-body {
    padding: ${themeGet('space.xs')}px;
    font-family: Poppins;
  }
  /* Bootstrap tooltip styles */
  .tooltip {
    transition: opacity 0.15s linear;
    &-inner {
      font-family: Poppins;
    }
  }

  /* Bootstrap check input styles (checkbox, radio, and switches) */
  .form-check-input {
    cursor: pointer;

    &:checked, &[type="checkbox"]:indeterminate {
      border-color: ${themeGet('colors.fill.status.success.light')};
      background-color: ${themeGet('colors.fill.status.success.light')};
    }
    &:hover,
    &:focus {
      border-color: ${themeGet('colors.fill.status.success.light')};
      box-shadow: 0 0 0 0.25rem ${palette.green['100']};
    }
  }
  .form-check-label {
    width: inherit;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .form-check-input:not(:disabled) + .form-check-label {
    cursor: pointer;
  }
`

export const AppContainer = styled.div`
  position: relative;
  width: 100%;
  margin-left: auto;
`

export const AppContent = styled.div<{ $hasMinWidth?: boolean }>`
  min-height: 100vh;
  ${({ $hasMinWidth }) => $hasMinWidth && `min-width: 1184px;`}
  margin-left: 6.75em;
  background-color: white;
`

export const $truncate = `
  overflow: hidden;
  word-break: break-word;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
`

export const TruncatedP = styled.p`
  ${$truncate}
`
