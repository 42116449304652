import { EvidenceGroup } from '@trustero/trustero-api-web/lib/attachment/attachment_pb'
import React, { useContext } from 'react'

type CurrentEvidenceGroupContextType = {
  currentEvidenceGroup: EvidenceGroup | null
  setCurrentEvidenceGroup: React.Dispatch<
    React.SetStateAction<EvidenceGroup | null>
  >
}

export const CurrentEvidenceGroupContext =
  React.createContext<CurrentEvidenceGroupContextType>({
    currentEvidenceGroup: null,
    setCurrentEvidenceGroup: () => null,
  })

export const useCurrentEvidenceGroup = (): CurrentEvidenceGroupContextType =>
  useContext(CurrentEvidenceGroupContext)

export const CurrentEvidenceGroupProvider = ({
  children,
}: React.PropsWithChildren): JSX.Element => {
  const [currentEvidenceGroup, setCurrentEvidenceGroup] =
    React.useState<EvidenceGroup | null>(null)

  return (
    <CurrentEvidenceGroupContext.Provider
      value={{
        currentEvidenceGroup,
        setCurrentEvidenceGroup,
      }}
    >
      {children}
    </CurrentEvidenceGroupContext.Provider>
  )
}
