/**
 * @fileoverview gRPC-Web generated client stub for audit
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.20.3
// source: audit/auditbot.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js')

var audit_audit_pb = require('../audit/audit_pb.js')

var common_model_pb = require('../common/model_pb.js')

var source_source_pb = require('../source/source_pb.js')
const proto = {};
proto.audit = require('./auditbot_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.audit.AuditBotClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.audit.AuditBotPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.audit.StartAuditRunRequest,
 *   !proto.audit.StartAuditRunResponse>}
 */
const methodDescriptor_AuditBot_StartAuditRun = new grpc.web.MethodDescriptor(
  '/audit.AuditBot/StartAuditRun',
  grpc.web.MethodType.UNARY,
  proto.audit.StartAuditRunRequest,
  proto.audit.StartAuditRunResponse,
  /**
   * @param {!proto.audit.StartAuditRunRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.audit.StartAuditRunResponse.deserializeBinary
);


/**
 * @param {!proto.audit.StartAuditRunRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.audit.StartAuditRunResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.audit.StartAuditRunResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.audit.AuditBotClient.prototype.startAuditRun =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/audit.AuditBot/StartAuditRun',
      request,
      metadata || {},
      methodDescriptor_AuditBot_StartAuditRun,
      callback);
};


/**
 * @param {!proto.audit.StartAuditRunRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.audit.StartAuditRunResponse>}
 *     Promise that resolves to the response
 */
proto.audit.AuditBotPromiseClient.prototype.startAuditRun =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/audit.AuditBot/StartAuditRun',
      request,
      metadata || {},
      methodDescriptor_AuditBot_StartAuditRun);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.audit.PreAuditCheckRequest,
 *   !proto.audit.PreAudit>}
 */
const methodDescriptor_AuditBot_PreAuditCheck = new grpc.web.MethodDescriptor(
  '/audit.AuditBot/PreAuditCheck',
  grpc.web.MethodType.UNARY,
  proto.audit.PreAuditCheckRequest,
  proto.audit.PreAudit,
  /**
   * @param {!proto.audit.PreAuditCheckRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.audit.PreAudit.deserializeBinary
);


/**
 * @param {!proto.audit.PreAuditCheckRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.audit.PreAudit)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.audit.PreAudit>|undefined}
 *     The XHR Node Readable Stream
 */
proto.audit.AuditBotClient.prototype.preAuditCheck =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/audit.AuditBot/PreAuditCheck',
      request,
      metadata || {},
      methodDescriptor_AuditBot_PreAuditCheck,
      callback);
};


/**
 * @param {!proto.audit.PreAuditCheckRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.audit.PreAudit>}
 *     Promise that resolves to the response
 */
proto.audit.AuditBotPromiseClient.prototype.preAuditCheck =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/audit.AuditBot/PreAuditCheck',
      request,
      metadata || {},
      methodDescriptor_AuditBot_PreAuditCheck);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.audit.ListAuditRunsRequest,
 *   !proto.audit.AuditRunSummaries>}
 */
const methodDescriptor_AuditBot_ListAuditRuns = new grpc.web.MethodDescriptor(
  '/audit.AuditBot/ListAuditRuns',
  grpc.web.MethodType.UNARY,
  proto.audit.ListAuditRunsRequest,
  proto.audit.AuditRunSummaries,
  /**
   * @param {!proto.audit.ListAuditRunsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.audit.AuditRunSummaries.deserializeBinary
);


/**
 * @param {!proto.audit.ListAuditRunsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.audit.AuditRunSummaries)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.audit.AuditRunSummaries>|undefined}
 *     The XHR Node Readable Stream
 */
proto.audit.AuditBotClient.prototype.listAuditRuns =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/audit.AuditBot/ListAuditRuns',
      request,
      metadata || {},
      methodDescriptor_AuditBot_ListAuditRuns,
      callback);
};


/**
 * @param {!proto.audit.ListAuditRunsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.audit.AuditRunSummaries>}
 *     Promise that resolves to the response
 */
proto.audit.AuditBotPromiseClient.prototype.listAuditRuns =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/audit.AuditBot/ListAuditRuns',
      request,
      metadata || {},
      methodDescriptor_AuditBot_ListAuditRuns);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.audit.GetAuditRunRequest,
 *   !proto.audit.AuditRun>}
 */
const methodDescriptor_AuditBot_GetAuditRun = new grpc.web.MethodDescriptor(
  '/audit.AuditBot/GetAuditRun',
  grpc.web.MethodType.UNARY,
  proto.audit.GetAuditRunRequest,
  proto.audit.AuditRun,
  /**
   * @param {!proto.audit.GetAuditRunRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.audit.AuditRun.deserializeBinary
);


/**
 * @param {!proto.audit.GetAuditRunRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.audit.AuditRun)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.audit.AuditRun>|undefined}
 *     The XHR Node Readable Stream
 */
proto.audit.AuditBotClient.prototype.getAuditRun =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/audit.AuditBot/GetAuditRun',
      request,
      metadata || {},
      methodDescriptor_AuditBot_GetAuditRun,
      callback);
};


/**
 * @param {!proto.audit.GetAuditRunRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.audit.AuditRun>}
 *     Promise that resolves to the response
 */
proto.audit.AuditBotPromiseClient.prototype.getAuditRun =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/audit.AuditBot/GetAuditRun',
      request,
      metadata || {},
      methodDescriptor_AuditBot_GetAuditRun);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.audit.StopAuditRunRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_AuditBot_StopAuditRun = new grpc.web.MethodDescriptor(
  '/audit.AuditBot/StopAuditRun',
  grpc.web.MethodType.UNARY,
  proto.audit.StopAuditRunRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.audit.StopAuditRunRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.audit.StopAuditRunRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.audit.AuditBotClient.prototype.stopAuditRun =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/audit.AuditBot/StopAuditRun',
      request,
      metadata || {},
      methodDescriptor_AuditBot_StopAuditRun,
      callback);
};


/**
 * @param {!proto.audit.StopAuditRunRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.audit.AuditBotPromiseClient.prototype.stopAuditRun =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/audit.AuditBot/StopAuditRun',
      request,
      metadata || {},
      methodDescriptor_AuditBot_StopAuditRun);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.audit.DeleteAuditRunsRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_AuditBot_DeleteAuditRuns = new grpc.web.MethodDescriptor(
  '/audit.AuditBot/DeleteAuditRuns',
  grpc.web.MethodType.UNARY,
  proto.audit.DeleteAuditRunsRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.audit.DeleteAuditRunsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.audit.DeleteAuditRunsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.audit.AuditBotClient.prototype.deleteAuditRuns =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/audit.AuditBot/DeleteAuditRuns',
      request,
      metadata || {},
      methodDescriptor_AuditBot_DeleteAuditRuns,
      callback);
};


/**
 * @param {!proto.audit.DeleteAuditRunsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.audit.AuditBotPromiseClient.prototype.deleteAuditRuns =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/audit.AuditBot/DeleteAuditRuns',
      request,
      metadata || {},
      methodDescriptor_AuditBot_DeleteAuditRuns);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.audit.GetValidAuditsForScanRequest,
 *   !proto.audit.AuditRecords>}
 */
const methodDescriptor_AuditBot_GetValidAuditsForScan = new grpc.web.MethodDescriptor(
  '/audit.AuditBot/GetValidAuditsForScan',
  grpc.web.MethodType.UNARY,
  proto.audit.GetValidAuditsForScanRequest,
  audit_audit_pb.AuditRecords,
  /**
   * @param {!proto.audit.GetValidAuditsForScanRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  audit_audit_pb.AuditRecords.deserializeBinary
);


/**
 * @param {!proto.audit.GetValidAuditsForScanRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.audit.AuditRecords)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.audit.AuditRecords>|undefined}
 *     The XHR Node Readable Stream
 */
proto.audit.AuditBotClient.prototype.getValidAuditsForScan =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/audit.AuditBot/GetValidAuditsForScan',
      request,
      metadata || {},
      methodDescriptor_AuditBot_GetValidAuditsForScan,
      callback);
};


/**
 * @param {!proto.audit.GetValidAuditsForScanRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.audit.AuditRecords>}
 *     Promise that resolves to the response
 */
proto.audit.AuditBotPromiseClient.prototype.getValidAuditsForScan =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/audit.AuditBot/GetValidAuditsForScan',
      request,
      metadata || {},
      methodDescriptor_AuditBot_GetValidAuditsForScan);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.audit.GetHasValidFrameworksForScanRequest,
 *   !proto.google.protobuf.BoolValue>}
 */
const methodDescriptor_AuditBot_GetHasValidFrameworksForScan = new grpc.web.MethodDescriptor(
  '/audit.AuditBot/GetHasValidFrameworksForScan',
  grpc.web.MethodType.UNARY,
  proto.audit.GetHasValidFrameworksForScanRequest,
  google_protobuf_wrappers_pb.BoolValue,
  /**
   * @param {!proto.audit.GetHasValidFrameworksForScanRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_wrappers_pb.BoolValue.deserializeBinary
);


/**
 * @param {!proto.audit.GetHasValidFrameworksForScanRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.BoolValue)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.BoolValue>|undefined}
 *     The XHR Node Readable Stream
 */
proto.audit.AuditBotClient.prototype.getHasValidFrameworksForScan =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/audit.AuditBot/GetHasValidFrameworksForScan',
      request,
      metadata || {},
      methodDescriptor_AuditBot_GetHasValidFrameworksForScan,
      callback);
};


/**
 * @param {!proto.audit.GetHasValidFrameworksForScanRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.BoolValue>}
 *     Promise that resolves to the response
 */
proto.audit.AuditBotPromiseClient.prototype.getHasValidFrameworksForScan =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/audit.AuditBot/GetHasValidFrameworksForScan',
      request,
      metadata || {},
      methodDescriptor_AuditBot_GetHasValidFrameworksForScan);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.audit.GetAuditControlTestRunsRequest,
 *   !proto.audit.GetAuditControlTestRunsResponse>}
 */
const methodDescriptor_AuditBot_GetAuditControlTestRuns = new grpc.web.MethodDescriptor(
  '/audit.AuditBot/GetAuditControlTestRuns',
  grpc.web.MethodType.UNARY,
  proto.audit.GetAuditControlTestRunsRequest,
  proto.audit.GetAuditControlTestRunsResponse,
  /**
   * @param {!proto.audit.GetAuditControlTestRunsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.audit.GetAuditControlTestRunsResponse.deserializeBinary
);


/**
 * @param {!proto.audit.GetAuditControlTestRunsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.audit.GetAuditControlTestRunsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.audit.GetAuditControlTestRunsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.audit.AuditBotClient.prototype.getAuditControlTestRuns =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/audit.AuditBot/GetAuditControlTestRuns',
      request,
      metadata || {},
      methodDescriptor_AuditBot_GetAuditControlTestRuns,
      callback);
};


/**
 * @param {!proto.audit.GetAuditControlTestRunsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.audit.GetAuditControlTestRunsResponse>}
 *     Promise that resolves to the response
 */
proto.audit.AuditBotPromiseClient.prototype.getAuditControlTestRuns =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/audit.AuditBot/GetAuditControlTestRuns',
      request,
      metadata || {},
      methodDescriptor_AuditBot_GetAuditControlTestRuns);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.audit.GetSmartChecksTestInformationRequest,
 *   !proto.audit.GetSmartChecksTestInformationResponse>}
 */
const methodDescriptor_AuditBot_GetSmartChecksTestInformation = new grpc.web.MethodDescriptor(
  '/audit.AuditBot/GetSmartChecksTestInformation',
  grpc.web.MethodType.UNARY,
  proto.audit.GetSmartChecksTestInformationRequest,
  proto.audit.GetSmartChecksTestInformationResponse,
  /**
   * @param {!proto.audit.GetSmartChecksTestInformationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.audit.GetSmartChecksTestInformationResponse.deserializeBinary
);


/**
 * @param {!proto.audit.GetSmartChecksTestInformationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.audit.GetSmartChecksTestInformationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.audit.GetSmartChecksTestInformationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.audit.AuditBotClient.prototype.getSmartChecksTestInformation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/audit.AuditBot/GetSmartChecksTestInformation',
      request,
      metadata || {},
      methodDescriptor_AuditBot_GetSmartChecksTestInformation,
      callback);
};


/**
 * @param {!proto.audit.GetSmartChecksTestInformationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.audit.GetSmartChecksTestInformationResponse>}
 *     Promise that resolves to the response
 */
proto.audit.AuditBotPromiseClient.prototype.getSmartChecksTestInformation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/audit.AuditBot/GetSmartChecksTestInformation',
      request,
      metadata || {},
      methodDescriptor_AuditBot_GetSmartChecksTestInformation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.audit.GetAuditReadinessRequest,
 *   !proto.audit.GetAuditReadinessResponse>}
 */
const methodDescriptor_AuditBot_GetAuditReadiness = new grpc.web.MethodDescriptor(
  '/audit.AuditBot/GetAuditReadiness',
  grpc.web.MethodType.UNARY,
  proto.audit.GetAuditReadinessRequest,
  proto.audit.GetAuditReadinessResponse,
  /**
   * @param {!proto.audit.GetAuditReadinessRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.audit.GetAuditReadinessResponse.deserializeBinary
);


/**
 * @param {!proto.audit.GetAuditReadinessRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.audit.GetAuditReadinessResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.audit.GetAuditReadinessResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.audit.AuditBotClient.prototype.getAuditReadiness =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/audit.AuditBot/GetAuditReadiness',
      request,
      metadata || {},
      methodDescriptor_AuditBot_GetAuditReadiness,
      callback);
};


/**
 * @param {!proto.audit.GetAuditReadinessRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.audit.GetAuditReadinessResponse>}
 *     Promise that resolves to the response
 */
proto.audit.AuditBotPromiseClient.prototype.getAuditReadiness =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/audit.AuditBot/GetAuditReadiness',
      request,
      metadata || {},
      methodDescriptor_AuditBot_GetAuditReadiness);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.audit.ValidateAccountBalanceResponse>}
 */
const methodDescriptor_AuditBot_ValidateAccountBalance = new grpc.web.MethodDescriptor(
  '/audit.AuditBot/ValidateAccountBalance',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.audit.ValidateAccountBalanceResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.audit.ValidateAccountBalanceResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.audit.ValidateAccountBalanceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.audit.ValidateAccountBalanceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.audit.AuditBotClient.prototype.validateAccountBalance =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/audit.AuditBot/ValidateAccountBalance',
      request,
      metadata || {},
      methodDescriptor_AuditBot_ValidateAccountBalance,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.audit.ValidateAccountBalanceResponse>}
 *     Promise that resolves to the response
 */
proto.audit.AuditBotPromiseClient.prototype.validateAccountBalance =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/audit.AuditBot/ValidateAccountBalance',
      request,
      metadata || {},
      methodDescriptor_AuditBot_ValidateAccountBalance);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.audit.GetAccountBalanceResponse>}
 */
const methodDescriptor_AuditBot_GetAccountBalance = new grpc.web.MethodDescriptor(
  '/audit.AuditBot/GetAccountBalance',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.audit.GetAccountBalanceResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.audit.GetAccountBalanceResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.audit.GetAccountBalanceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.audit.GetAccountBalanceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.audit.AuditBotClient.prototype.getAccountBalance =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/audit.AuditBot/GetAccountBalance',
      request,
      metadata || {},
      methodDescriptor_AuditBot_GetAccountBalance,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.audit.GetAccountBalanceResponse>}
 *     Promise that resolves to the response
 */
proto.audit.AuditBotPromiseClient.prototype.getAccountBalance =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/audit.AuditBot/GetAccountBalance',
      request,
      metadata || {},
      methodDescriptor_AuditBot_GetAccountBalance);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.audit.GetAuditRunProgressRequest,
 *   !proto.audit.AuditRunProgress>}
 */
const methodDescriptor_AuditBot_GetAuditRunProgress = new grpc.web.MethodDescriptor(
  '/audit.AuditBot/GetAuditRunProgress',
  grpc.web.MethodType.UNARY,
  proto.audit.GetAuditRunProgressRequest,
  proto.audit.AuditRunProgress,
  /**
   * @param {!proto.audit.GetAuditRunProgressRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.audit.AuditRunProgress.deserializeBinary
);


/**
 * @param {!proto.audit.GetAuditRunProgressRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.audit.AuditRunProgress)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.audit.AuditRunProgress>|undefined}
 *     The XHR Node Readable Stream
 */
proto.audit.AuditBotClient.prototype.getAuditRunProgress =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/audit.AuditBot/GetAuditRunProgress',
      request,
      metadata || {},
      methodDescriptor_AuditBot_GetAuditRunProgress,
      callback);
};


/**
 * @param {!proto.audit.GetAuditRunProgressRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.audit.AuditRunProgress>}
 *     Promise that resolves to the response
 */
proto.audit.AuditBotPromiseClient.prototype.getAuditRunProgress =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/audit.AuditBot/GetAuditRunProgress',
      request,
      metadata || {},
      methodDescriptor_AuditBot_GetAuditRunProgress);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.audit.EditControlTestResultRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_AuditBot_EditControlTestResult = new grpc.web.MethodDescriptor(
  '/audit.AuditBot/EditControlTestResult',
  grpc.web.MethodType.UNARY,
  proto.audit.EditControlTestResultRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.audit.EditControlTestResultRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.audit.EditControlTestResultRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.audit.AuditBotClient.prototype.editControlTestResult =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/audit.AuditBot/EditControlTestResult',
      request,
      metadata || {},
      methodDescriptor_AuditBot_EditControlTestResult,
      callback);
};


/**
 * @param {!proto.audit.EditControlTestResultRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.audit.AuditBotPromiseClient.prototype.editControlTestResult =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/audit.AuditBot/EditControlTestResult',
      request,
      metadata || {},
      methodDescriptor_AuditBot_EditControlTestResult);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.audit.GetOperatingEffectivenessDetailsRequest,
 *   !proto.audit.OperatingEffectivenessDetails>}
 */
const methodDescriptor_AuditBot_GetOperatingEffectivenessDetails = new grpc.web.MethodDescriptor(
  '/audit.AuditBot/GetOperatingEffectivenessDetails',
  grpc.web.MethodType.UNARY,
  proto.audit.GetOperatingEffectivenessDetailsRequest,
  proto.audit.OperatingEffectivenessDetails,
  /**
   * @param {!proto.audit.GetOperatingEffectivenessDetailsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.audit.OperatingEffectivenessDetails.deserializeBinary
);


/**
 * @param {!proto.audit.GetOperatingEffectivenessDetailsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.audit.OperatingEffectivenessDetails)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.audit.OperatingEffectivenessDetails>|undefined}
 *     The XHR Node Readable Stream
 */
proto.audit.AuditBotClient.prototype.getOperatingEffectivenessDetails =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/audit.AuditBot/GetOperatingEffectivenessDetails',
      request,
      metadata || {},
      methodDescriptor_AuditBot_GetOperatingEffectivenessDetails,
      callback);
};


/**
 * @param {!proto.audit.GetOperatingEffectivenessDetailsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.audit.OperatingEffectivenessDetails>}
 *     Promise that resolves to the response
 */
proto.audit.AuditBotPromiseClient.prototype.getOperatingEffectivenessDetails =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/audit.AuditBot/GetOperatingEffectivenessDetails',
      request,
      metadata || {},
      methodDescriptor_AuditBot_GetOperatingEffectivenessDetails);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.audit.GetControlPolicyMatchDetailsRequest,
 *   !proto.audit.ControlPolicyMatchDetails>}
 */
const methodDescriptor_AuditBot_GetControlPolicyMatchDetails = new grpc.web.MethodDescriptor(
  '/audit.AuditBot/GetControlPolicyMatchDetails',
  grpc.web.MethodType.UNARY,
  proto.audit.GetControlPolicyMatchDetailsRequest,
  proto.audit.ControlPolicyMatchDetails,
  /**
   * @param {!proto.audit.GetControlPolicyMatchDetailsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.audit.ControlPolicyMatchDetails.deserializeBinary
);


/**
 * @param {!proto.audit.GetControlPolicyMatchDetailsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.audit.ControlPolicyMatchDetails)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.audit.ControlPolicyMatchDetails>|undefined}
 *     The XHR Node Readable Stream
 */
proto.audit.AuditBotClient.prototype.getControlPolicyMatchDetails =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/audit.AuditBot/GetControlPolicyMatchDetails',
      request,
      metadata || {},
      methodDescriptor_AuditBot_GetControlPolicyMatchDetails,
      callback);
};


/**
 * @param {!proto.audit.GetControlPolicyMatchDetailsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.audit.ControlPolicyMatchDetails>}
 *     Promise that resolves to the response
 */
proto.audit.AuditBotPromiseClient.prototype.getControlPolicyMatchDetails =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/audit.AuditBot/GetControlPolicyMatchDetails',
      request,
      metadata || {},
      methodDescriptor_AuditBot_GetControlPolicyMatchDetails);
};


module.exports = proto.audit;

