import { parseMultipartMessage } from '@apeleghq/multipart-parser'
import log from 'loglevel'
import pluralize from 'pluralize'
import {
  ComplianceFramework,
  ComplianceFrameworks,
} from '@trustero/trustero-api-web/lib/audit/framework_pb'
import {
  Framework,
  Frameworks,
} from '@trustero/trustero-api-web/lib/model/framework_pb'
import {
  ERROR_CODES,
  ERROR_MESSAGES,
  FRAMEWORK_MODEL_IDS,
  MIME_TYPE,
  APPLE_FILE_EXTENSIONS,
} from './globalEnums'
import {
  AI_LOADING_MESSAGES,
  ComplianceFrameworkGroup,
  ParsedPart,
} from './globalConstants'
import { isGrpcError } from './isGrpcError'

export const isFullySupportedFramework = (
  frameworkModelId: FRAMEWORK_MODEL_IDS,
): boolean => {
  return (
    frameworkModelId === FRAMEWORK_MODEL_IDS.ISO27001 ||
    frameworkModelId === FRAMEWORK_MODEL_IDS.SOC1 ||
    frameworkModelId === FRAMEWORK_MODEL_IDS.SOC2 ||
    frameworkModelId === FRAMEWORK_MODEL_IDS.HIPAA ||
    frameworkModelId === FRAMEWORK_MODEL_IDS.PCISAQD ||
    frameworkModelId === FRAMEWORK_MODEL_IDS.PCISAQA ||
    frameworkModelId === FRAMEWORK_MODEL_IDS.HITRUST ||
    frameworkModelId === FRAMEWORK_MODEL_IDS.NISTCSF ||
    frameworkModelId === FRAMEWORK_MODEL_IDS.ISO27701_DC ||
    frameworkModelId === FRAMEWORK_MODEL_IDS.ISO27701_DP ||
    frameworkModelId === FRAMEWORK_MODEL_IDS.GDPR_DC ||
    frameworkModelId === FRAMEWORK_MODEL_IDS.GDPR_DP ||
    frameworkModelId === FRAMEWORK_MODEL_IDS.PIPEDA_DC ||
    frameworkModelId === FRAMEWORK_MODEL_IDS.PIPEDA_DP ||
    frameworkModelId === FRAMEWORK_MODEL_IDS.NYDFS
  )
}

export const isErrorType = (err: unknown, type: ERROR_CODES): boolean =>
  isGrpcError(err) && err.message.includes(type)

export const getErrorCode = (message: string): ERROR_CODES | undefined => {
  const errorCode = Object.values(ERROR_CODES).find((code) =>
    message.includes(code),
  )
  return errorCode
}

export type ErrorMessageResponse = {
  message: string
  shouldLog: boolean
}

export const getErrorMessage = (err: unknown): ErrorMessageResponse => {
  if (!isGrpcError(err)) {
    return {
      message: ERROR_MESSAGES.UNKNOWN,
      shouldLog: true,
    }
  }

  const errorMessageCode = getErrorCode(err.message)
  switch (errorMessageCode) {
    case ERROR_CODES.DUPLICATE_ENTRY:
      return {
        message: ERROR_MESSAGES[ERROR_CODES.DUPLICATE_ENTRY],
        shouldLog: false,
      }
    case ERROR_CODES.TOO_LONG:
      return {
        message: ERROR_MESSAGES[ERROR_CODES.TOO_LONG],
        shouldLog: false,
      }
    default:
      return {
        message: ERROR_MESSAGES.UNKNOWN,
        shouldLog: true,
      }
  }
}

export const getPercentageCompleted = (
  completed: number,
  total: number,
): number => {
  return total > 0 ? Math.round((completed / total) * 100) : 0
}

export const getPercentageRoundingDown = (
  completed: number,
  total: number,
): number => {
  return total > 0 ? Math.floor((completed / total) * 100) : 0
}

export const openInNewTab = (href = ''): void => {
  if (!href.length) return
  Object.assign(document.createElement('a'), {
    target: '_blank',
    rel: 'noopener noreferrer',
    href: href,
  }).click()
}

export const getMimeTypeFromExtension = (name: string): MIME_TYPE => {
  const splitName = name.split('.')
  const extension = splitName[splitName.length - 1]
  switch (extension) {
    case APPLE_FILE_EXTENSIONS.KEY:
      return MIME_TYPE.APPLE_KEYNOTE
    case APPLE_FILE_EXTENSIONS.NUMBERS:
      return MIME_TYPE.APPLE_NUMBERS
    case APPLE_FILE_EXTENSIONS.PAGES:
      return MIME_TYPE.APPLE_PAGES
    default:
      return MIME_TYPE.NULL
  }
}

type messageWithId = {
  getId: () => string
}

export const normalizeById = <T extends messageWithId>(
  messageList: T[],
): Record<string, T> =>
  messageList.reduce((acc, message): Record<string, T> => {
    const controlId = message.getId()
    acc[controlId] = message
    return acc
  }, {} as Record<string, T>)

type messageWithControlId = {
  getControlId: () => string
}

export const normalizeByControlId = <T extends messageWithControlId>(
  messageList: T[],
): Record<string, T> =>
  messageList.reduce((acc, message): Record<string, T> => {
    const controlId = message.getControlId()
    acc[controlId] = message
    return acc
  }, {} as Record<string, T>)

export const getPluralization = (word: string, count: number): string =>
  pluralize(word, count)

// Function to capitalize the first letter of each word
export const capitalizeFirstLetter = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
}

export const isJSON = (str: string): boolean => {
  try {
    JSON.parse(str)
    return true
  } catch (e) {
    return false
  }
}

export const getFileSize = (file: File): number => {
  // Plus sign drops unnecessary zeros at end of number
  return +(file.size / 1000).toFixed(2)
}

export const getAILoadingMessage = (): string => {
  const index = Math.floor(Math.random() * AI_LOADING_MESSAGES.length)
  return AI_LOADING_MESSAGES[index] || AI_LOADING_MESSAGES[0]
}

export const getFrameworkGroups = (
  complianceFrameworks?: ComplianceFrameworks,
  frameworks?: Frameworks,
  includeCustom?: boolean,
): Record<string, ComplianceFrameworkGroup> => {
  if (!complianceFrameworks || (!frameworks && !includeCustom)) return {}

  const bases = complianceFrameworks.getItemsList()
  const ac: { [key: string]: ComplianceFrameworkGroup } = {}
  if (includeCustom) {
    const customBases = bases.filter((base) => base.getIsCustom())
    customBases.forEach((base) => {
      ac[base.getName()] = {
        base: base,
        objectives: [new Framework().setName('True')],
      }
    })
  }

  return frameworks
    ? frameworks?.getItemsList().reduce((groups, framework) => {
        const id = framework.getComplianceFrameworkId()
        const base = bases.find(
          (base) => base.getId() === id,
        ) as ComplianceFramework
        if (!(base.getName() in groups)) {
          groups[base.getName()] = {
            base: base,
            objectives: [],
          }
        }
        groups[base.getName()].objectives.push(framework)
        return groups
      }, ac)
    : {}
}

export const getIsMultiPartMixedMime = (mimeType: string): boolean => {
  return mimeType.includes(MIME_TYPE.MULTIPART_MIXED)
}

export const parseMultiPartResponse = async (
  response: ReadableStream<Uint8Array>,
  mime: string,
): Promise<ParsedPart[]> => {
  const mimeParts = mime.split('boundary=')
  if (mimeParts.length < 2) {
    log.error('Could not locate boundary in MIME type')
    return []
  }
  const boundary = mimeParts[1]
  const decoder = new TextDecoder()

  const parts: ParsedPart[] = []

  for await (const part of parseMultipartMessage(response, boundary)) {
    const headers = part.headers
    if (
      headers.get('content-type')?.includes('text') &&
      headers.get('content-type') !== 'text/csv'
    ) {
      const body =
        part.body instanceof Uint8Array ? decoder.decode(part.body) : ''
      parts.push({ headers, body })
      continue
    }
    parts.push({ headers, body: part.body as Uint8Array })
  }
  return parts
}

export const isParsedPart = (part: unknown): part is ParsedPart => {
  return (
    typeof part === 'object' &&
    part !== null &&
    'headers' in part &&
    'body' in part
  )
}

export const isParsedPartArray = (parts: unknown): parts is ParsedPart[] => {
  return Array.isArray(parts) && parts.every(isParsedPart)
}
