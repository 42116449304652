export { DownloadIcon } from './DownloadIcon'
export { BookIcon } from './BookIcon'

import styled from 'styled-components/macro'
import {
  ColorProps,
  compose,
  margin,
  MarginProps,
  position,
  PositionProps,
  system,
} from 'styled-system'
import { ReactComponent as OriginalDeleteSVG } from '../assets/delete-icon.svg'
import { ReactComponent as OriginalRisksSVG } from './icons_warning_gear.svg'
import { ReactComponent as OriginalControlsSVG } from './icons_controls.svg'
import { DownloadIcon } from './DownloadIcon'
import { ReactComponent as OriginalActivateSVG } from './icons_activate.svg'
import { ReactComponent as OriginalCheckSVG } from './icons_check.svg'
import { ReactComponent as OriginalCircleCheck } from './icons_circle_check.svg'
import { ReactComponent as OriginalChevronSVG } from './icons_chevron.svg'
import { ReactComponent as OriginalFrameworksSVG } from './icons_frameworks.svg'
import { ReactComponent as OriginalAuditSVG } from './icons_audit.svg'
import { ReactComponent as OriginalReceptorsSVG } from './icons_services.svg'
import { ReactComponent as OriginalCompanyInfoSVG } from './icons_company_info.svg'
import { ReactComponent as OriginalDescriptionSVG } from './icons_description.svg'
import { ReactComponent as OriginalDropdownSVG } from './icons_dropdown.svg'
import { ReactComponent as OriginalDropdownDarkSVG } from './icons_dropdown_dark.svg'
import { ReactComponent as OriginalCopySVG } from './icons_copy.svg'
import { ReactComponent as OriginalEditSVG } from './icons_edit.svg'
import { ReactComponent as OriginalErrorSVG } from './icons_error.svg'
import { ReactComponent as OriginalXSVG } from './icons_x.svg'
import { ReactComponent as OriginalEvidenceSVG } from './icons_evidence.svg'
import { ReactComponent as OriginalFixitSVG } from './icons_fixit.svg'
import { ReactComponent as OriginalInfoSVG } from './icons_info.svg'
import { ReactComponent as OriginalInfoFullSVG } from './icons_info_full.svg'
import { ReactComponent as OriginalKeySVG } from './icons_key.svg'
import { ReactComponent as OriginalPoliciesSVG } from './icons_policies.svg'
import { ReactComponent as OriginalRequestsSVG } from './icons_requests.svg'
import { ReactComponent as OriginalSortNoneSVG } from './icons_sort_none.svg'
import { ReactComponent as OriginalSortAscSVG } from './icons_sort_asc.svg'
import { ReactComponent as OriginalSortDescSVG } from './icons_sort_desc.svg'
import { ReactComponent as OriginalTestSVG } from './icons_test.svg'
import { ReactComponent as OriginalAuditBotSVG } from './icons_auditbot.svg'
import { ReactComponent as OriginalCircleCheckFillSVG } from './icons_circle_check_fill.svg'
import { ReactComponent as OriginalCircleMinusSVG } from './icons_circle_minus.svg'
import { ReactComponent as OriginalMinusSVG } from './icons_minus.svg'
import { ReactComponent as OriginalForwardSVG } from './icons_forward.svg'
import { ReactComponent as OriginalQuestionMarkSVG } from './icons_questionmark.svg'
import { ReactComponent as OriginalCircleQuestionMarkSVG } from './icons_circle_question_mark.svg'
import { ReactComponent as OriginalPanelCollapseEastSVG } from './icons_panel_collapse_east.svg'
import { ReactComponent as OriginalPanelCollapseWestSVG } from './icons_panel_collapse_west.svg'
import { ReactComponent as OriginalPanelExpandEastSVG } from './icons_panel_expand_east.svg'
import { ReactComponent as OriginalPanelExpandWestSVG } from './icons_panel_expand_west.svg'
import { ReactComponent as OriginalGrcSVG } from './icons_grc.svg'
import { ReactComponent as OriginalKbDocumentSVG } from './icons_kb_document.svg'
import { ReactComponent as OriginalUploadSVG } from './icons_upload.svg'
import { ReactComponent as OriginalEyeVisible } from './icons_eye.svg'
import { ReactComponent as OriginalEyeInvisible } from './icons_eye_slash.svg'

export { ReactComponent as PoliciesPurpleSVG } from './icons_policies_purple.svg'

type Props = PositionProps & ColorProps & MarginProps

const fill = system({
  fill: {
    property: 'fill',
    scale: 'colors',
  },
})

export const ActivateSVG = styled(OriginalActivateSVG)<Props>`
  ${compose(position, margin)}
  path {
    ${fill}
  }
`

export const CheckSVG = styled(OriginalCheckSVG)<Props>`
  ${compose(position, margin)}
  path {
    ${fill}
  }
`

export const CircleCheckSVG = styled(OriginalCircleCheck)<Props>`
  ${compose(position, margin)}
  path {
    ${fill}
  }
`
export const CircleMinusSVG = styled(OriginalCircleMinusSVG)<Props>`
  ${compose(position, margin)}
  path {
    ${fill}
  }
`

export const ForwardSVG = styled(OriginalForwardSVG)<Props>`
  ${compose(position, margin)}
  path {
    ${fill}
  }
`
export const MinusSVG = styled(OriginalMinusSVG)<Props>`
  ${compose(position, margin)}
  path {
    ${fill}
  }
`
export const QuestionMarkSVG = styled(OriginalQuestionMarkSVG)<Props>`
  ${compose(position, margin)}
  path {
    ${fill}
  }
`
export const CircleQuestionMarkSVG = styled(
  OriginalCircleQuestionMarkSVG,
)<Props>`
  ${compose(position, margin)}
  path {
    ${fill}
  }
`
export const ChevronSVG = styled(OriginalChevronSVG)<Props>`
  ${compose(position, margin)}
  path {
    ${fill}
  }
`
export const ControlsSVG = styled(OriginalControlsSVG)<Props>`
  ${compose(position, margin)}
  path {
    ${fill}
  }
`
export const FrameworksSVG = styled(OriginalFrameworksSVG)<Props>`
  ${compose(position, margin)}
  path {
    ${fill}
  }
`
export const AuditsSVG = styled(OriginalAuditSVG)<Props>`
  ${compose(position, margin)}
  path {
    ${fill}
  }
`
export const ReceptorsSVG = styled(OriginalReceptorsSVG)<Props>`
  ${compose(position, margin)}
  path {
    ${fill}
  }
`
export const CompanyInfoSVG = styled(OriginalCompanyInfoSVG)<Props>`
  ${compose(position, margin)}
  path {
    ${fill}
  }
`
export const DescriptionSVG = styled(OriginalDescriptionSVG)<Props>`
  ${compose(position, margin)}
  path {
    ${fill}
  }
`
export const DownloadSVG = DownloadIcon

export const DropdownSVG = styled(OriginalDropdownSVG)<Props>`
  ${compose(position, margin)}
  path {
    ${fill}
  }
`
export const DropdownDarkSVG = styled(OriginalDropdownDarkSVG)<Props>`
  ${compose(position, margin)}
  path {
    ${fill}
  }
`
export const CopySVG = styled(OriginalCopySVG)<Props>`
  ${compose(position, margin)}
  path {
    ${fill}
  }
`

export const EditSVG = styled(OriginalEditSVG)<Props>`
  ${compose(position, margin)}
  path {
    ${fill}
  }
`
export const ErrorSVG = styled(OriginalErrorSVG)<Props>`
  ${compose(position, margin)}
  path {
    ${fill}
  }
`
export const XSVG = styled(OriginalXSVG)<Props>`
  ${compose(position, margin)}
  path {
    ${fill}
  }
`
export const EvidenceSVG = styled(OriginalEvidenceSVG)<Props>`
  ${compose(position, margin)}
  path {
    ${fill}
  }
`
export const FixitSVG = styled(OriginalFixitSVG)<Props>`
  ${compose(position, margin)}
  path {
    ${fill}
  }
`
export const InfoSVG = styled(OriginalInfoSVG)<Props>`
  ${compose(position, margin)}
  path {
    ${fill}
  }
`
export const InfoFullSVG = styled(OriginalInfoFullSVG)<Props>`
  ${compose(position, margin)}
  path {
    ${fill}
  }
`
export const KeySVG = styled(OriginalKeySVG)<Props>`
  ${compose(position, margin)}
  path {
    ${fill}
  }
`

export const PoliciesSVG = styled(OriginalPoliciesSVG)<Props>`
  ${compose(position, margin)}
  path {
    ${fill}
  }
`

export const RequestsSVG = styled(OriginalRequestsSVG)<Props>`
  ${compose(position, margin)}
  path {
    ${fill}
  }
`

export const RisksSVG = styled(OriginalRisksSVG)<Props>`
  ${compose(position, margin)}
  path {
    ${fill}
  }
`

export const AuditBotSVG = styled(OriginalAuditBotSVG)<Props>`
  ${compose(position, margin)}
  path {
    ${fill}
  }
`

export const TestSVG = styled(OriginalTestSVG)<Props>`
  ${compose(position, margin)}
  path {
    ${fill}
  }
`

export const CircleCheckFillSVG = styled(OriginalCircleCheckFillSVG)<Props>`
  ${compose(position, margin)}
  path {
    ${fill}
  }
`

export const SortNoneSvg = styled(OriginalSortNoneSVG).withConfig({
  shouldForwardProp: (prop) => {
    return !['sortingOrder'].includes(prop)
  },
})<Props>`
  ${compose(position, margin)}
  path {
    ${fill}
  }
`

export const SortAscSvg = styled(OriginalSortAscSVG)<Props>`
  ${compose(position, margin)}
  path {
    ${fill}
  }
`

export const SortDescSvg = styled(OriginalSortDescSVG)<Props>`
  ${compose(position, margin)}
  path {
    ${fill}
  }
`

export const PanelCollapseEastSvg = styled(OriginalPanelCollapseEastSVG)<Props>`
  ${compose(position, margin)}
  path {
    ${fill}
  }
`

export const PanelCollapseWestSvg = styled(OriginalPanelCollapseWestSVG)<Props>`
  ${compose(position, margin)}
  path {
    ${fill}
  }
`

export const PanelExpandEastSvg = styled(OriginalPanelExpandEastSVG)<Props>`
  ${compose(position, margin)}
  path {
    ${fill}
  }
`
export const PanelExpandWestSvg = styled(OriginalPanelExpandWestSVG)<Props>`
  ${compose(position, margin)}
  path {
    ${fill}
  }
`

export const GrcSVG = styled(OriginalGrcSVG)<Props>`
  ${compose(position, margin)}
  path {
    ${fill}
  }
`

export const KbDocumentSVG = styled(OriginalKbDocumentSVG)<Props>`
  ${compose(position, margin)}
  path {
    ${fill}
  }
`

export const UploadSVG = styled(OriginalUploadSVG)<Props>`
  ${compose(position, margin)}
  path {
    ${fill}
  }
`

export const DeleteSVG = styled(OriginalDeleteSVG)<Props>`
  ${compose(position, margin)}
  path {
    ${fill}
  }
`

export const EyeVisibleSVG = styled(OriginalEyeVisible)<Props>`
  ${compose(position, margin)}
  path {
    ${fill}
  }
`

export const EyeInvisibleSVG = styled(OriginalEyeInvisible)<Props>`
  ${compose(position, margin)}
  path {
    ${fill}
  }
`

export const StyledIcon = styled.div<{
  height?: number
  width?: number
  fontSize?: number
}>`
  height: ${({ height }) => (height ? `${height}px` : '24px')};
  width: ${({ width }) => (width ? `${width}px` : '24px')};
  font-family: 'Font Awesome 6 Free';
  font-style: normal;
  font-weight: 900;
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : '24px')};
  line-height: ${({ fontSize }) => (fontSize ? `${fontSize}px` : '24px')};
  color: #586c7f;
  flex: none;
  order: 0;
  flex-grow: 0;
`
