import styled from 'styled-components/macro'
import { compose, padding } from 'styled-system'
import { Dropdown } from 'react-bootstrap'
import { RISK_MATH } from '@trustero/trustero-api-web/lib/risk/risk_pb'
import { GridHeader, GridColumn } from 'src/components/Reusable/Grid'
import palette from 'src/designSystem/variables/palette'
import { FlexRow } from 'src/components/Reusable/Flex'
import { DropdownSVG, ErrorSVG } from 'src/components/Icons/Basic'
import {
  filterBarHeight,
  headerHeight,
} from 'src/components/PageLayout/dimensionUtils'
import { ReactComponent as NoRisksSVG } from '../../components/Icons/EmptyStateIcons/no_risks_icon.svg'

export const RiskStyles = styled.div``

// Index Page
export const RiskContent = styled.article.attrs({
  px: 'l',
})`
  ${padding}
  position: relative;
  background-color: white;
`

export const RiskNav = styled.nav.attrs({ pt: 'xs' })`
  ${compose(padding)}
  display: flex;
  top: ${headerHeight}px;
  height: ${filterBarHeight}px;
  align-items: center;
  position: sticky;
  z-index: 2;
  background-color: white;
`

export const NoRisksIcon = styled(NoRisksSVG)`
  height: 100px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ActionRow = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
`

export const StatusContainer = styled(FlexRow)`
  top: -8px;
  position: relative;
`

// Risk Grid
export const RiskGridContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding-bottom: 10em; // TODO: This is a hack to make the status menu not clip
`

export const StyledRisksGridHeader = styled(GridHeader)`
  min-width: 85px;
  position: sticky;
  top: ${(p) => p.top || 0}px;
  z-index: 1;
`

export const CenteredRiskGridColumn = styled(GridColumn)`
  justify-content: center;
`

export const RiskName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

// Risk Show Page
export const RiskSectionHeader = styled.h6`
  color: ${palette.blue['700']};
`

export const RiskSectionContainer = styled.div`
  margin-bottom: 2em;
`

export const RiskOverviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: start;
  align-items: start;

  label {
    font-size: 14px;
    font-weight: 600;
    margin-top: 2em;
  }
`

export const FirstLabel = styled.label`
  margin-top: 0 !important; // override higher label specificity above
`

export const RiskResponseContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: start;
  align-items: start;

  label {
    font-size: 14px;
    font-weight: 600;
    margin-top: 2em;

    &:first-child {
      margin-top: 0;
    }
  }
`

export const MitigationContainer = styled.div`
  width: 100%;
  margin-top: 2em;
`

export const MitigationHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;
`

export const PolicyGridColumn = styled(GridColumn)`
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`

export const RisksControlsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const RiskMathField = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  margin-bottom: 1em;

  span {
    margin-right: 1em;
  }
`

export const ResidualRiskContainer = styled.div`
  margin-top: 1em;
`

// Linkages
export const RiskOwnerLabel = styled.label`
  margin-left: 0.5em;
`

export const RiskTimestamps = styled.div`
  margin-top: 0.5em;
  font-size: 14px;
  color: ${palette.neutral['600']};
`

// Tabs
export const NoItemsLinked = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 1em;
  background-color: ${palette.neutral['30']};
`

// Risk Departments
export const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const DepartmentLabel = styled.label`
  margin-top: 1em;
  font-size: 14px;
`

// Risk Math Radio Buttons
export const RiskRadioContainer = styled.div`
  width: 180px;
  padding: 0.5em 0.25em;
  text-align: center;
  vertical-align: middle;
  background-color: ${palette.neutral['30']};

  .form-check {
    position: relative;
    top: 4px;
  }
`

// Risk Math
export const RiskMathContainer = styled.div`
  display: flex;
  flex-direction: row;
  font-weight: 500;
  font-size: 12px;
  border-radius: 2px;

  .dropdown-menu {
    min-width: 8em;
  }

  .btn-primary.dropdown-toggle {
    color: black;
    background-color: transparent;
  }

  // Space arrow dropdown
  .dropdown-toggle:after {
    display: none;
  }

  .btn-primary:disabled {
    opacity: 0.7;
    cursor: default;

    &.dropdown-toggle:after {
      display: none;
    }
  }

  // Menu Toggle
  &.risk-math-${RISK_MATH.NOT_SET} .btn-primary {
    color: ${palette.magenta['400']};
    background-color: ${palette.neutral['30']};

    &:after {
      display: none;
    }
  }
  &.risk-math-${RISK_MATH.VERY_LOW} .btn-primary {
    background-color: ${palette.orange['30']};
  }
  &.risk-math-${RISK_MATH.LOW} .btn-primary {
    background-color: ${palette.orange['100']};
  }
  &.risk-math-${RISK_MATH.MODERATE} .btn-primary {
    background-color: ${palette.orange['200']};
  }
  &.risk-math-${RISK_MATH.HIGH} .btn-primary {
    background-color: ${palette.magenta['200']};
  }
  &.risk-math-${RISK_MATH.VERY_HIGH} .btn-primary {
    background-color: ${palette.magenta['400']};
  }

  // Menu Item Options
  .dropdown-menu.show .dropdown-item {
    margin: 0.5em;
    padding: 0.375rem 0.75rem;
    border-radius: 4px;
    width: fit-content;

    &:hover {
      background-color: ${palette.neutral['100']};
    }

    &.risk-math-item-${RISK_MATH.VERY_LOW} {
      background-color: ${palette.neutral['30']};
    }

    &.risk-math-item-${RISK_MATH.LOW} {
      background-color: ${palette.orange['100']};
    }

    &.risk-math-item-${RISK_MATH.MODERATE} {
      background-color: ${palette.orange['200']};
    }

    &.risk-math-item-${RISK_MATH.HIGH} {
      background-color: ${palette.magenta['200']};
    }

    &.risk-math-item-${RISK_MATH.VERY_HIGH} {
      background-color: ${palette.magenta['400']};
    }
  }
`

export const ToggleIcon = styled(DropdownSVG)`
  height: 1.25em;
  width: 1.25em;
  margin-left: 0.5em;
`

export const RiskExclamationIcon = styled(ErrorSVG)`
  height: 1.75em;
  width: 1.75em;
  margin-left: 0;
  margin-right: 0.5em;
  font-size: 0.5em;

  path {
    fill: ${palette.magenta['400']};
  }
`

export const RiskLinkageExclamationIcon = styled(ErrorSVG)`
  margin-right: 0.5em;

  path {
    fill: ${palette.magenta['400']};
  }
`

export const RiskMathToggle = styled(Dropdown.Toggle)<{ hasNoBorder: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: ${({ $hasNoBorder }) => ($hasNoBorder ? 'none' : '1px solid black')};

  &:disabled {
    border-color: black;
  }
`

export const RiskMathRadioButtonRow = styled.div`
  margin: 2em 0.25em 0 0.25em;
  font-size: 14px;
`

export const RiskRadioFirstContainer = styled.div`
  div {
    margin-top: 0em;
  }
`

export const RiskMathRadioContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

// Modals
export const ModalDescription = styled.p`
  padding-bottom: 24px;
`
