import { USER_ROLE } from '@trustero/trustero-api-web/lib/account/account_pb'
import { useEffect, useMemo } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useAccount } from 'src/components/async/Account/account.hooks'
import { useUser } from 'src/components/async/Users'
import { DirectRoutes } from 'src/components/Reusable/RootPage/RootPage.constants'
import { getRoleConfig, PERMISSIONS, RoleConfig } from 'src/config/roleConfig'
import { useInAudit } from 'src/context/AuditContext'
import { useAuth } from 'src/context/authContext'

const checkPath = (routes: string[], path: string): boolean => {
  for (const route of routes) {
    const regex = new RegExp(`^/[a-z0-9:]+/${route}$`)
    if (regex.test(path)) {
      return true
    }
  }
  return false
}

const checkAuditPath = (allowedAudits: string[], path: string): boolean => {
  if (path.length === 0) {
    return false
  }

  for (const audit of allowedAudits) {
    if (path.includes(audit)) {
      return true
    }
  }
  return false
}

export const useIsSuperUser = (): boolean => {
  const { email, role } = useAuth()
  return email.includes('@trustero.com') && role === USER_ROLE.ADMIN
}

export const useValidateRouteAccess = (): void => {
  const {
    routeConfig: { denyRoutes },
  } = useGetRoleAccessConfig()
  const location = useLocation()

  useEffect(() => {
    const path = location.pathname

    if (checkPath(denyRoutes, path)) {
      window.location.href = DirectRoutes.ACCESS_DENIED
    }
  }, [location.pathname, denyRoutes])
}

export const useRestrictAccessToAudit = (): void => {
  const { routeConfig } = useGetRoleAccessConfig()
  const { data } = useUser()
  const params = useParams()
  const location = useLocation()
  const { auditId } = useInAudit()

  useEffect(() => {
    const pageContext = params.pageContext
    if (!pageContext?.includes(data?.getAccountid() || '')) return
    if (!routeConfig.restrictToAuditView || !data || !pageContext) return

    const allowedAudits = data.getAuditIdsList()
    const path = location.pathname
    const onAllowedRoute = checkPath(routeConfig.explicitAllowRoutes, path)
    const inAllowedAuditView = checkAuditPath(allowedAudits, auditId || '')

    if (allowedAudits.length && onAllowedRoute && !inAllowedAuditView) {
      window.location.href = `../${data.getAccountid()}:${allowedAudits[0]}/${
        DirectRoutes.AUDITS
      }`
    }
    if (!onAllowedRoute && !inAllowedAuditView) {
      if (allowedAudits.length > 0) {
        const targetPath = `../${data.getAccountid()}:${allowedAudits[0]}/${
          DirectRoutes.AUDITS
        }`
        if (location.pathname !== targetPath) {
          window.location.href = targetPath
        }
      } else {
        if (location.pathname !== `${DirectRoutes.AUDITS}`) {
          window.location.href = `../${data.getAccountid()}/${
            DirectRoutes.AUDITS
          }`
        }
      }
    }
  }, [routeConfig, data, params.pageContext, auditId, location.pathname])
}

export const useGetRoleAccessConfig = (): RoleConfig => {
  const authCtx = useAuth()
  const { data: user, isLoading } = useUser()

  const { data: account } = useAccount()

  return useMemo(
    () =>
      getRoleConfig({
        userRole: authCtx.role,
        accountPurpose: account?.getPurpose(),
        hasQc: user?.getAllowQc(),
        isLoading,
      }),
    [authCtx.role, account, user, isLoading],
  )
}

export const useHasRequiredPermissions = (
  requiredPermissions: PERMISSIONS[],
): boolean => {
  const { permissionsConfig } = useGetRoleAccessConfig()

  for (const permission of requiredPermissions) {
    if (!permissionsConfig.includes(permission)) {
      return false
    }
  }

  return true
}

export const useHasEditPermissions = (): boolean => {
  return useHasRequiredPermissions([PERMISSIONS.EDIT])
}
