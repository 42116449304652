import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import queryString, { ParsedQuery } from 'query-string'
import {
  ComplianceFrameworkFilter,
  IsCustomizedFilter,
  ListPoliciesRequest,
  PolicySort,
} from '@trustero/trustero-api-web/lib/model/policy_pb'
import { MODEL_TYPE } from '@trustero/trustero-api-web/lib/common/model_pb'
import { NONE_ID } from 'src/Utils/globalConstants'
import { applyFrameworkFilter } from 'src/components/Reusable/IndexPage/FilterBar/FilterBar.utils'
import { DocumentationFilterValues } from 'src/components/Reusable/IndexPage/FilterBar/FilterBar.constants'
import { BoolValue } from 'google-protobuf/google/protobuf/wrappers_pb'
import { FilterParam } from '../../../components/Reusable/IndexPage/FilterBar/FilterBar.types'
import {
  GridColumnSortPolicies,
  GridColumnSortType,
  SORT_ORDER,
} from '../../../components/Reusable/Grid/GridColumnSort/GridColumnSort.constants'
import { Assignee, FRAMEWORK_MODEL_IDS } from '../../../Utils/globalEnums'
import { POLICY_SORT_COLUMNS } from './PoliciesIndexPage.constants'

const applyFilter = (
  listPoliciesReq: ListPoliciesRequest,
  queryParams: ParsedQuery<string>,
  filterType: FilterParam,
) => {
  const filterParams = queryParams[filterType] as string[]
  if (!filterParams?.length) {
    return
  }

  switch (filterType) {
    case FilterParam.DEPARTMENT: {
      const departmentIds = new Set(filterParams)
      listPoliciesReq.setDepartmentIdsList(Array.from(departmentIds))
      break
    }
    case FilterParam.HAS_DOCUMENTATION: {
      if (
        filterParams.includes(DocumentationFilterValues.TRUE) &&
        filterParams.includes(DocumentationFilterValues.FALSE)
      )
        return
      const boolValue: boolean = JSON.parse(
        filterParams.filter(
          (value) =>
            value === DocumentationFilterValues.TRUE ||
            value === DocumentationFilterValues.FALSE,
        )[0],
      )
      listPoliciesReq.setHasDocumentation(new BoolValue().setValue(boolValue))
      break
    }
    case FilterParam.IS_CUSTOMIZED: {
      const isCustomizedOptions = new Set(filterParams)
      const isCustomizedFilter = new IsCustomizedFilter()
      if (isCustomizedOptions.has(DocumentationFilterValues.TRUE)) {
        isCustomizedFilter.setIncludeCustomized(true)
      }
      if (isCustomizedOptions.has(DocumentationFilterValues.FALSE)) {
        isCustomizedFilter.setIncludeNotCustomized(true)
      }
      listPoliciesReq.setIsCustomizedFilter(isCustomizedFilter)
      break
    }
    case FilterParam.OWNER: {
      const emails = [...filterParams]
      const idx = emails.findIndex(
        (email) => email?.toLowerCase() === Assignee.UNASSIGNED_BE,
      )
      if (idx > -1) {
        emails.splice(idx, 1)
        listPoliciesReq.setOwnersList(emails)
        listPoliciesReq.setIncludeUnassigned(new BoolValue().setValue(true))
      } else {
        listPoliciesReq.setOwnersList(emails)
      }
      break
    }
    case FilterParam.SOC2:
      applyFrameworkFilter(
        listPoliciesReq,
        FRAMEWORK_MODEL_IDS.SOC2,
        filterParams,
        MODEL_TYPE.POLICY,
      )
      break
    case FilterParam.ISO:
      applyFrameworkFilter(
        listPoliciesReq,
        FRAMEWORK_MODEL_IDS.ISO27001,
        filterParams,
        MODEL_TYPE.POLICY,
      )
      break
    case FilterParam.TEST:
      applyFrameworkFilter(
        listPoliciesReq,
        FRAMEWORK_MODEL_IDS.TEST,
        filterParams,
        MODEL_TYPE.POLICY,
      )
      break
    case FilterParam.PIPEDA_DC:
      applyFrameworkFilter(
        listPoliciesReq,
        FRAMEWORK_MODEL_IDS.PIPEDA_DC,
        filterParams,
        MODEL_TYPE.POLICY,
      )
      break
    case FilterParam.PIPEDA_DP:
      applyFrameworkFilter(
        listPoliciesReq,
        FRAMEWORK_MODEL_IDS.PIPEDA_DP,
        filterParams,
        MODEL_TYPE.POLICY,
      )
      break
    case FilterParam.NISTCSF:
      applyFrameworkFilter(
        listPoliciesReq,
        FRAMEWORK_MODEL_IDS.NISTCSF,
        filterParams,
        MODEL_TYPE.POLICY,
      )
      break
    case FilterParam.ISO27701_DC:
      applyFrameworkFilter(
        listPoliciesReq,
        FRAMEWORK_MODEL_IDS.ISO27701_DC,
        filterParams,
        MODEL_TYPE.POLICY,
      )
      break
    case FilterParam.ISO27701_DP:
      applyFrameworkFilter(
        listPoliciesReq,
        FRAMEWORK_MODEL_IDS.ISO27701_DP,
        filterParams,
        MODEL_TYPE.POLICY,
      )
      break
    case FilterParam.HITRUST:
      applyFrameworkFilter(
        listPoliciesReq,
        FRAMEWORK_MODEL_IDS.HITRUST,
        filterParams,
        MODEL_TYPE.POLICY,
      )
      break
    case FilterParam.HIPAA:
      applyFrameworkFilter(
        listPoliciesReq,
        FRAMEWORK_MODEL_IDS.HIPAA,
        filterParams,
        MODEL_TYPE.POLICY,
      )
      break
    case FilterParam.GDPR_DC:
      applyFrameworkFilter(
        listPoliciesReq,
        FRAMEWORK_MODEL_IDS.GDPR_DC,
        filterParams,
        MODEL_TYPE.POLICY,
      )
      break
    case FilterParam.GDPR_DP:
      applyFrameworkFilter(
        listPoliciesReq,
        FRAMEWORK_MODEL_IDS.GDPR_DP,
        filterParams,
        MODEL_TYPE.POLICY,
      )
      break
    case FilterParam.AUDIT:
      listPoliciesReq.setAuditIdsList(filterParams)
      break
    case FilterParam.COMPLIANCE_FRAMEWORK: {
      const complianceFrameworkFilter = new ComplianceFrameworkFilter()
      const complianceFrameworkIds = new Set(filterParams)
      if (complianceFrameworkIds.has(NONE_ID)) {
        complianceFrameworkIds.delete(NONE_ID)
        complianceFrameworkFilter.setIncludeNone(new BoolValue().setValue(true))
      }
      complianceFrameworkFilter.setComplianceFrameworkIdsList(
        Array.from(complianceFrameworkIds),
      )
      listPoliciesReq.setComplianceFrameworkFilter(complianceFrameworkFilter)
      break
    }
    case FilterParam.STATUS: {
      const complianceFrameworkFilter = new ComplianceFrameworkFilter()
      const complianceFrameworkIds = new Set(filterParams)
      if (complianceFrameworkIds.has(NONE_ID)) {
        complianceFrameworkIds.delete(NONE_ID)
        complianceFrameworkFilter.setIncludeNone(new BoolValue().setValue(true))
      }
      complianceFrameworkFilter.setComplianceFrameworkIdsList(
        Array.from(complianceFrameworkIds),
      )
      listPoliciesReq.setComplianceFrameworkFilter(complianceFrameworkFilter)
      break
    }
    default: {
      break
    }
  }
}

const applySort = (
  policiesRequest: ListPoliciesRequest,
  queryParams: ParsedQuery<string>,
) => {
  const sortBy =
    ((queryParams.sort_by && queryParams.sort_by[0]) as GridColumnSortType) ||
    GridColumnSortType.ASCENDING
  const sortCol =
    ((queryParams.sort_col &&
      queryParams.sort_col[0]) as GridColumnSortPolicies) ||
    GridColumnSortPolicies.NAME

  const req = new PolicySort()
    .setSortOrder(SORT_ORDER[sortBy])
    .setSortColumn(POLICY_SORT_COLUMNS[sortCol])
  policiesRequest.setPolicySort(req)
}

/**
 * Adds Filter & Sorting information for Policies to pass in a gRPC request
 *
 * @param locSearch
 * @returns ListControlsRequest
 */
const getPoliciesGridFilterRequest = (
  locSearch: string,
): ListPoliciesRequest => {
  const queryParams: ParsedQuery<string> = queryString.parse(locSearch, {
    arrayFormat: 'bracket',
  })
  const listPoliciesReq: ListPoliciesRequest =
    new ListPoliciesRequest().setIsDismissed(
      new BoolValue().setValue(
        JSON.parse(
          queryParams.dismissed?.[0] &&
            (queryParams.dismisssed?.[0] === 'false' ||
              queryParams.dismissed?.[0] === 'true')
            ? queryParams.dismissed[0]
            : 'false',
        ),
      ),
    )
  Object.values(FilterParam).forEach((filterType) =>
    applyFilter(listPoliciesReq, queryParams, filterType),
  )
  applySort(listPoliciesReq, queryParams)
  return listPoliciesReq
}

export const usePolicyRequest = (): ListPoliciesRequest => {
  const location = useLocation()
  return useMemo(
    () => getPoliciesGridFilterRequest(location.search),
    [location.search],
  )
}
