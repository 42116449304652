import React from 'react'
import EroBlinkGif from 'src/components/Icons/Brand/ero_blink.gif'
import {
  AuditBotThrobberIcon,
  AuditBotThrobberText,
} from './AuditBotThrobber.styles'

export const AuditBotThrobber = ({
  text,
}: {
  text: string | string[]
}): JSX.Element => {
  const textArray = Array.isArray(text) ? text : [text]

  return (
    <>
      <AuditBotThrobberIcon src={EroBlinkGif} />
      {textArray.map((text, index) => (
        <AuditBotThrobberText key={index}>{text}</AuditBotThrobberText>
      ))}
    </>
  )
}
