import React from 'react'
import ContentLoader, { IContentLoaderProps } from 'react-content-loader'

export const BoxLoader = (
  props: IContentLoaderProps & {
    height?: string | number
    width?: string | number
  },
): JSX.Element => (
  <div style={{ width: props.width || 225, height: props.height || 275 }}>
    <ContentLoader
      viewBox={`0 0 ${props.width || '225'} ${props.height || '275'}`}
      {...props}
    >
      <rect
        x="0"
        y="0"
        rx="2"
        ry="2"
        width={props.width || '225'}
        height={props.height || '275'}
      />
    </ContentLoader>
  </div>
)
