import {
  AUDIT_RESULT,
  AuditReadinessRecord,
  AuditReadinessTestRecord,
  GetAuditReadinessResponse,
} from '@trustero/trustero-api-web/lib/audit/auditbot_pb'
import { useInAudit } from 'src/context/AuditContext'
import { useAuditReadiness } from 'src/pages/Roadmap_v2/roadmap.hooks'
import { useControls } from 'src/components/async/model/control'
import { useCurrentUserEmail } from 'src/context/authContext'
import isUndefined from 'lodash/isUndefined'
import { CONTROL_TEST_NAMES } from 'src/pages/AuditBot/accordion/subsection/ControlChecks/AuditBotControlChecks.constants'
import { KeyedMutator } from 'swr'
import { useGetURLParams } from '../hooks'
import { ChecklistFilterEnum, DashboardQueryParam } from '../types'
import { ControlChecksWidgetTestOverviewType } from './ControlChecksWidget.constants'

const getBaseOverview = (): ControlChecksWidgetTestOverviewType[] => [
  {
    testName: CONTROL_TEST_NAMES.POLICY_MATCH,
  },
  {
    testName: CONTROL_TEST_NAMES.COMPLETENESS,
  },
  {
    testName: CONTROL_TEST_NAMES.SPOT_CHECK,
  },
]

const getOverviewsFromAuditReadiness = (
  auditReadinessList: AuditReadinessRecord[],
): ControlChecksWidgetTestOverviewType[] => {
  const base = getBaseOverview()

  const applicableAuditReadinessList = auditReadinessList.filter(
    (item) => !item.getControlNa(),
  )

  for (const item of applicableAuditReadinessList) {
    const controlTestList = item.getControlTestsList()

    for (const test of controlTestList) {
      const index = base.findIndex(
        (overview) => overview.testName === test.getTestName(),
      )

      if (index !== -1) {
        const curr = base[index]
        if (isUndefined(curr.controlCount))
          curr.controlCount = applicableAuditReadinessList.length
        if (isUndefined(curr.staleCount)) curr.staleCount = 0
        if (isUndefined(curr.issueCount)) curr.issueCount = 0
        if (isUndefined(curr.notRunCount))
          curr.notRunCount = applicableAuditReadinessList.length

        if (test.getIsStale()) {
          curr.staleCount = (curr.staleCount || 0) + 1
        }

        if (test.getResult() !== AUDIT_RESULT.PASS) {
          curr.issueCount = (curr.issueCount || 0) + 1
        }
        curr.notRunCount -= 1
      }
    }
  }

  return base
}

export const useControlCheckOverviews =
  (): ControlChecksWidgetTestOverviewType[] => {
    const { auditId } = useInAudit()
    const { data } = useAuditReadiness({
      auditId,
      relevantTests: [
        CONTROL_TEST_NAMES.POLICY_MATCH,
        CONTROL_TEST_NAMES.COMPLETENESS,
        CONTROL_TEST_NAMES.SPOT_CHECK,
      ],
      calculateStaleness: true,
    })

    const params = useGetURLParams()
    const userEmail = useCurrentUserEmail()
    const { data: controls } = useControls()

    let auditReadinessList = data?.getAuditReadinessList() || []

    if (
      params[DashboardQueryParam.COMPLIANCE_CHECKLIST_TAB] ===
      ChecklistFilterEnum.YOUR_WORK
    ) {
      const controlIds = new Set(
        controls
          ?.getItemsList()
          .filter((control) => control.getOwnerEmail() === userEmail)
          .map((control) => control.getId()),
      )

      auditReadinessList = auditReadinessList.filter((test) =>
        controlIds.has(test.getControlId()),
      )
    }

    return data
      ? getOverviewsFromAuditReadiness(auditReadinessList)
      : getBaseOverview()
  }

/*
The philosophy behind this ranking is that every step up in importance must be equal to the maximum value of the prev step + 1

So, because operating effectiveness check is greater than stale, it must be equal to (3x) 1pt + 1px
Evidence Completeness must be equal to the max value of stale + operating effectiveness check + 1pt = 8
and so on.

This allows ranking within a step while insuring the minimum possible score of the next step will always have a higher score 
*/
const ControlCheckScores: Record<string, number> = {
  Stale: 1,
  [CONTROL_TEST_NAMES.SPOT_CHECK]: 4,
  [CONTROL_TEST_NAMES.COMPLETENESS]: 8,
  [CONTROL_TEST_NAMES.POLICY_MATCH]: 16,
}

const getControlCheckScore = (item: AuditReadinessRecord): number => {
  let score = 0
  const tests = item.getControlTestsList()
  const isNotApplicable = item.getControlNa()

  for (const test of tests) {
    if (isNotApplicable) return 0
    if (test.getIsStale()) {
      score += ControlCheckScores.Stale
    }

    // Currently not sure how other states rank. This needs to be discussed.
    if (test.getResult() === AUDIT_RESULT.FAIL) {
      if (test.getTestName() in ControlCheckScores) {
        score += ControlCheckScores[test.getTestName()]
      }
    }
  }

  return score
}

export const useGetControlCheckWidgetControls = (
  limit = 5,
): string[] | null => {
  const { auditId } = useInAudit()
  const params = useGetURLParams()
  const userEmail = useCurrentUserEmail()
  const { data: controls, isLoading: controlsLoading } = useControls()
  const { data, isLoading } = useAuditReadiness({
    auditId,
    relevantTests: [
      CONTROL_TEST_NAMES.POLICY_MATCH,
      CONTROL_TEST_NAMES.COMPLETENESS,
      CONTROL_TEST_NAMES.SPOT_CHECK,
    ],
    calculateStaleness: true,
  })

  if (isLoading || controlsLoading) return null

  let auditReadinessList = data?.getAuditReadinessList() || []

  if (
    params[DashboardQueryParam.COMPLIANCE_CHECKLIST_TAB] ===
    ChecklistFilterEnum.YOUR_WORK
  ) {
    const controlIds = new Set(
      controls
        ?.getItemsList()
        .filter((control) => control.getOwnerEmail() === userEmail)
        .map((control) => control.getId()),
    )

    auditReadinessList = auditReadinessList.filter((test) =>
      controlIds.has(test.getControlId()),
    )
  }

  const sorted = auditReadinessList.sort(
    (a, b) => getControlCheckScore(b) - getControlCheckScore(a),
  )

  return sorted.slice(0, limit).map((test) => test.getControlId())
}

export const useGetControlChecks = (
  controlId: string,
  calculateStaleness: boolean,
): {
  tests: AuditReadinessTestRecord[] | undefined
  mutate: KeyedMutator<GetAuditReadinessResponse>
} => {
  const { auditId } = useInAudit()
  const { data, mutate } = useAuditReadiness({
    auditId,
    relevantTests: [
      CONTROL_TEST_NAMES.POLICY_MATCH,
      CONTROL_TEST_NAMES.COMPLETENESS,
      CONTROL_TEST_NAMES.SPOT_CHECK,
    ],
    controlId,
    calculateStaleness,
  })

  if (!data) return { tests: undefined, mutate }

  const auditReadinessList = data.getAuditReadinessList()
  let control = auditReadinessList.find(
    (auditReadiness) => auditReadiness.getControlId() === controlId,
  )
  // fallback to attempt model id lookup
  if (!control) {
    control = auditReadinessList.find(
      (auditReadiness) => auditReadiness.getControlModelId() === controlId,
    )
  }

  return { tests: control?.getControlTestsList(), mutate }
}
