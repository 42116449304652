import React, { useMemo, useState } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useCognitoContext } from 'src/context/FormContext/CognitoContext'
import { getCognitoAuthGrant, getProviderName } from 'src/Utils/oidc'
import {
  StandardButtonSize,
  StandardButtonVariant,
} from 'src/components/Reusable/Buttons'
import { FlexAlign, FlexRow } from 'src/components/Reusable/Flex'
import { TextInput } from 'src/components/Reusable/Inputs'
import { isValidEmail } from 'src/Utils/validators'
import {
  LoginPageContainer,
  LoginPageContent,
  LoginPageGrid,
  TrusteroLine,
  TrusteroLogo,
} from '../LoginPage.styles'
import { LoginProviders } from '../LoginPage.constants'
import { OIDCEmailButton, OIDCEmailText } from './OIDCEmailPage.styles'

export const OIDCEmailPage = (): JSX.Element => {
  const location = useLocation()
  const { initialCognitoAuth } = useCognitoContext()
  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false)
  const [email, setEmail] = useState<string>('')
  const [error, setError] = useState<string>('')
  const [provider, setProvider] = useState<LoginProviders | null>(null)

  const isValid = useMemo(() => {
    const isEmailValid = isValidEmail(email)
    if (!isEmailValid) {
      hasSubmitted && setError('Please enter a valid email')
      return false
    }
    const domain = email.split('@')[1]
    const prov = getProviderName(domain)
    if (!prov) {
      hasSubmitted &&
        setError('Provider not found. Please reach out to support@trustero.com')
      return false
    }
    setProvider(prov)
    return true
  }, [email, hasSubmitted])

  if (hasSubmitted && isValid && provider) {
    return (
      <LoginPageContainer isInApp={false}>
        <LoginPageGrid>
          <LoginPageContent>
            <OIDCEmailText>Loading...</OIDCEmailText>
          </LoginPageContent>
        </LoginPageGrid>
      </LoginPageContainer>
    )
  }
  if (!initialCognitoAuth) {
    return <Navigate to={location.state?.from || '/login'} replace />
  }

  const onSubmit = () => {
    setHasSubmitted(true)
    if (!isValid || !provider) {
      return
    }
    getCognitoAuthGrant({
      ...initialCognitoAuth,
      provider: provider,
    })
  }

  return (
    <LoginPageContainer isInApp={false}>
      <LoginPageGrid>
        <LoginPageContent>
          <TrusteroLogo width="100%" height="100%" />
          <TrusteroLine />
          <OIDCEmailText>
            Enter your email address to continue logging in with{' '}
            {initialCognitoAuth.provider}
          </OIDCEmailText>
          <FlexRow gap={16} align={FlexAlign.CENTER}>
            <TextInput
              label="Email"
              name="email"
              initVal={email}
              onChangeCb={(value) => {
                setHasSubmitted(false)
                setError('')
                setEmail(value)
              }}
              placeholder="Please enter your email"
              required
              errorMessage={error}
              isValid={isValid}
            />
            <OIDCEmailButton
              buttonSize={StandardButtonSize.MEDIUM}
              variant={StandardButtonVariant.SECONDARY}
              onClick={onSubmit}
            >
              Continue
            </OIDCEmailButton>
          </FlexRow>
        </LoginPageContent>
      </LoginPageGrid>
    </LoginPageContainer>
  )
}
