import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react'
import { NavigateFunction } from 'react-router-dom'
import { ParsedQuery } from 'query-string'

export type NavigateState = {
  navigate?: NavigateFunction
  parsedQueryParams?: ParsedQuery
  locationSearchString?: string
}

export const initialNavigateState: NavigateState = {
  navigate: undefined,
  parsedQueryParams: undefined,
  locationSearchString: undefined,
}

export type NavigateContextType = {
  navigateState: NavigateState
  setNavigateState: Dispatch<SetStateAction<NavigateState>>
}

export const NavigateContext = createContext<NavigateContextType>({
  navigateState: initialNavigateState,
  setNavigateState: () => null,
})

export const useNavigateContext = (): NavigateContextType =>
  useContext(NavigateContext)

export type NavigateProviderProps = React.PropsWithChildren<{
  navigate: NavigateFunction
  parsedQueryParams: ParsedQuery
  locationSearchString: string
}>

export const NavigateProvider = ({
  children,
  navigate,
  parsedQueryParams,
  locationSearchString,
}: NavigateProviderProps): JSX.Element => {
  const [navigateState, setNavigateState] = useState<NavigateState>({
    navigate,
    parsedQueryParams,
    locationSearchString,
  })

  useEffect(() => {
    setNavigateState({
      navigate,
      parsedQueryParams,
      locationSearchString,
    })
  }, [navigate, parsedQueryParams, locationSearchString])

  return (
    <NavigateContext.Provider value={{ navigateState, setNavigateState }}>
      {children}
    </NavigateContext.Provider>
  )
}
