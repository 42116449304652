import React, { useCallback } from 'react'
import { AttachmentPromiseClient } from '@trustero/trustero-api-web/lib/attachment/attachment_grpc_web_pb'
import {
  AddDocumentRequest,
  DeleteDocumentRequest,
  Document,
  DOCUMENT_TYPE,
  Documents,
  GetDocumentByCaptionRequest,
  GetDocumentByOIDRequest,
  URL,
} from '@trustero/trustero-api-web/lib/attachment/attachment_pb'
import { Empty } from 'google-protobuf/google/protobuf/empty_pb'
import log from 'loglevel'
import { useAuthorizedGrpcClient } from 'src/adapter/grpcClient'
import { GrpcResponse, NewGrpcResponse } from 'src/components/async/hooks/types'
import { useSwrImmutableGrpc } from 'src/components/async/useSwrImmutableGrpc'
import { useConfirmationModal } from 'src/components/ModalForms/useConfirmationModal'
import { FlexAlign, FlexColumn } from 'src/components/Reusable/Flex'
import { useSwrGrpc } from 'src/components/async/useSwrGrpc'
import { HTTP_METHODS } from 'src/Utils/globalEnums'
import { ToastPrompts } from 'src/Utils/helpers/toast'

export const useGetGrcDownloadUrl = (
  documentId: string,
): (() => Promise<URL | undefined>) => {
  const client = useAuthorizedGrpcClient(AttachmentPromiseClient)

  return useCallback(async () => {
    try {
      const request = new GetDocumentByOIDRequest().setId(documentId)

      const res = await client.getGrcDocumentDownloadURL(request)
      return res
    } catch (err) {
      log.error(
        `error getting grc document download url, documentId: ${documentId} err:`,
        documentId,
        err,
      )
    }
  }, [documentId, client])
}

export const useFetchGrcDocumentBody = (
  documentId: string,
  mime: string,
): (() => Promise<Uint8Array | string | undefined>) => {
  const getDownloadURL = useGetGrcDownloadUrl(documentId)

  return useCallback(async () => {
    const url = await getDownloadURL()
    if (!url) {
      throw new Error(ToastPrompts.DOC_DOWNLOAD_ERROR)
    }
    const headers = url.getHeadersMap().getEntryList()

    const response: Response = await fetch(url.getUrl(), {
      method: HTTP_METHODS.GET,
      headers: new Headers(headers as unknown as HeadersInit),
    })
    if (!response || response.status !== 200) {
      throw new Error(ToastPrompts.DOC_DOWNLOAD_ERROR)
    }
    let body: string | Uint8Array
    if (mime.startsWith('text/') && mime !== 'text/csv') {
      body = await response.text()
    } else {
      const bodyBlob = await response.blob()
      const bodyBytes = new Uint8Array(
        await new Response(bodyBlob).arrayBuffer(),
      )
      body = bodyBytes
    }
    return body
  }, [getDownloadURL, mime])
}

export const useGetGrcDocument = ({
  caption,
}: {
  caption: string
}): GrpcResponse<Document> => {
  const request = new GetDocumentByCaptionRequest().setCaption(caption)

  const { response } = useSwrGrpc(
    AttachmentPromiseClient.prototype.getGrcDocumentByCaption,
    request,
    !!caption,
  )
  return NewGrpcResponse(response)
}

export const useListGrcDocuments = (
  shouldFetch = true,
): GrpcResponse<Documents> => {
  const { response } = useSwrImmutableGrpc(
    AttachmentPromiseClient.prototype.listGrcDocuments,
    new Empty(),
    shouldFetch,
  )

  return NewGrpcResponse(response)
}

export const useDeleteGrcDocument = (): ((id: string) => Promise<void>) => {
  const attachmentClient = useAuthorizedGrpcClient(AttachmentPromiseClient)

  return async (id: string) => {
    const req = new DeleteDocumentRequest().setDocumentId(id)
    await attachmentClient.deleteGrcDocument(req)
  }
}

const title = 'Delete this GRC Document?'
const confirmText = 'Delete GRC Document'

export const useDeleteGrcDocumentModal = ({
  document,
}: {
  document?: Document
}): (() => void) => {
  const deleteGrcDocument = useDeleteGrcDocument()
  const { mutate } = useListGrcDocuments()

  const onConfirmCB = async () => {
    try {
      await deleteGrcDocument(document?.getId() || '')
      await mutate()
    } catch (err) {
      log.error('Error while deleting GRC Document', err)
    }
  }

  return useConfirmationModal({
    title,
    body: (
      <FlexColumn gap={10} align={FlexAlign.FLEX_START}>
        <p>Caption: {document?.getCaption()}</p>
        <p>
          <b>
            This GRC Document will be deleted for all accounts and users. This
            action cannot be undone.
          </b>
        </p>
      </FlexColumn>
    ),
    confirmText,
    onConfirmCB,
    throbber: {
      color: 'primary',
      size: 'xl',
    },
  })
}

type CreateDocumentArgs = {
  mime: string
  bodyBytes: Uint8Array
  caption: string
  description: string
}

export const useCreateGrcDocument = (): (({
  mime,
  bodyBytes,
  caption,
  description,
}: CreateDocumentArgs) => Promise<void>) => {
  const { mutate } = useListGrcDocuments()
  const attachmentClient = useAuthorizedGrpcClient(AttachmentPromiseClient)

  return useCallback(
    async ({ mime, bodyBytes, caption, description }: CreateDocumentArgs) => {
      const newEvidence = new Document()
        .setDoctype(DOCUMENT_TYPE.GRC_DOCUMENT)
        .setMime(mime)
        .setBody(bodyBytes)
        .setCaption(caption.trim())
        .setDescription(description.trim())

      await attachmentClient.addGrcDocument(
        new AddDocumentRequest().setDocument(newEvidence),
      )
      await mutate()
    },
    [attachmentClient, mutate],
  )
}
