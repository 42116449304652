import styled from 'styled-components/macro'

export const InfoLinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const InfoLinkSection = styled.div<{ withMargin?: boolean }>`
  font-weight: 400;
  margin-top: ${({ withMargin }) => (withMargin ? '8px' : '0')};
  a {
    text-decoration: none;
    font-weight: 500;
  }
`
