import React from 'react'
import { Tooltip } from 'src/components/Reusable/Tooltip'
import {
  SecondaryButton,
  IconButton,
  IconButtonVariant,
} from 'src/components/Reusable/Buttons'
import { ErrorSVG, CircleCheckFillSVG, DownloadIcon } from '../../Icons/Basic'
import { Spinner } from '../../../Throbber'
import { TestIds } from '../../../Utils/testIds'
import {
  DownloadContainer,
  LoadingUploadContainer,
  StyledP,
  UploadContainer,
  UploadHeader,
} from './UploadCSVModal.styles'
import { MAX_ERRORS, UPLOAD_TEXT } from './UploadCSVModal.constants'

export const UploadedFile = ({
  errors,
  dataLength,
  showSpinner,
  uploadType,
  cancelUpload,
}: {
  errors: string[]
  dataLength: number
  uploadType: string
  showSpinner?: boolean
  cancelUpload?: () => void
}): JSX.Element => {
  if (errors.length) {
    return (
      <UploadContainer>
        <UploadHeader>
          <ErrorSVG /> {UPLOAD_TEXT.errorTitle}
        </UploadHeader>
        <ul>
          {errors.slice(0, MAX_ERRORS).map((error, idx) => (
            <li key={`${error + idx.toString()}`}>{error}</li>
          ))}
          {errors.length > MAX_ERRORS && <p>{UPLOAD_TEXT.moreErrors}</p>}
        </ul>
        {cancelUpload && (
          <SecondaryButton
            text="Cancel and Upload New File"
            onClick={cancelUpload}
          />
        )}
      </UploadContainer>
    )
  } else if (!dataLength || showSpinner) {
    return (
      <LoadingUploadContainer>
        <Spinner data-testid={TestIds.SPINNER} color="primary" size="l" />
      </LoadingUploadContainer>
    )
  } else {
    return (
      <UploadContainer>
        <UploadHeader>
          <CircleCheckFillSVG /> {UPLOAD_TEXT.successTitle}
        </UploadHeader>
        <h3>
          {dataLength} {uploadType} found.
        </h3>
      </UploadContainer>
    )
  }
}

export const DownloadFileWithIcon = ({
  downloadFunc,
  text,
}: {
  downloadFunc: () => void
  text?: string
}): JSX.Element => (
  <DownloadContainer onClick={downloadFunc}>
    <DownloadIcon />
    <StyledP>{text}</StyledP>
  </DownloadContainer>
)

export const DownloadFileWithIconTooltip = ({
  downloadFunc,
  text,
  id,
  isDisabled,
}: {
  downloadFunc: () => void
  id: string
  text?: string
  isDisabled?: boolean
}): JSX.Element => (
  <Tooltip id={`download-tooltip-${id}`} placement="top" tooltipBody={text}>
    <IconButton disabled={isDisabled} variant={IconButtonVariant.icon}>
      <DownloadFileWithIcon downloadFunc={downloadFunc} />
    </IconButton>
  </Tooltip>
)
