import { QuestionnaireServicePromiseClient } from '@trustero/trustero-api-web/lib/questionnaire/questionnaire_grpc_web_pb'
import {
  DeleteQuestionnaireRequest,
  GetKnowledgeBasesResponse,
  KnowledgeBase,
  UploadKnowledgeBaseRequest,
} from '@trustero/trustero-api-web/lib/questionnaire/questionnaire_pb'
import { Empty } from 'google-protobuf/google/protobuf/empty_pb'
import { useAuthorizedGrpcClient } from 'src/adapter/grpcClient'
import { GrpcResponse, NewGrpcResponse } from 'src/components/async/hooks/types'
import { useSwrImmutableGrpc } from 'src/components/async/useSwrImmutableGrpc'
import FileSaver from 'file-saver'
import log from 'loglevel'
import { MIME_TYPE } from 'src/Utils/globalEnums'
import { UploadKB } from './knowledgeBase.constants'

export const useGetKnowledgeBases = (
  preventFetch?: boolean,
): GrpcResponse<GetKnowledgeBasesResponse> => {
  const { response } = useSwrImmutableGrpc(
    QuestionnaireServicePromiseClient.prototype.getKnowledgeBases,
    new Empty(),
    !preventFetch,
  )

  return NewGrpcResponse(response)
}

export const useDeleteKnowledgeBase = (): ((id: string) => Promise<void>) => {
  const questionnaireClient = useAuthorizedGrpcClient(
    QuestionnaireServicePromiseClient,
  )

  return async (id: string) => {
    const req = new DeleteQuestionnaireRequest().setId(id)
    await questionnaireClient.deleteKnowledgeBase(req)
  }
}

export const useUploadKnowledgeBase = (): (({
  questionCount,
  answerCount,
  fileName,
  file,
}: UploadKB) => Promise<void>) => {
  const questionnaireClient = useAuthorizedGrpcClient(
    QuestionnaireServicePromiseClient,
  )

  return async ({ questionCount, answerCount, fileName, file }: UploadKB) => {
    const request = new UploadKnowledgeBaseRequest()
    request.setQuestionCount(questionCount)
    request.setAnswerCount(answerCount)
    request.setFileName(fileName)
    request.setFile(file)

    await questionnaireClient.uploadKnowledgeBase(request)
  }
}

export const downloadKnowledgeBase = async (
  kb: KnowledgeBase,
  fetcher: () => Promise<string | Uint8Array | void>,
): Promise<void> => {
  try {
    const body = await fetcher()
    if (!body) return
    const file = new File([body], kb.getFileName(), {
      type: MIME_TYPE.TEXT_CSV,
    })
    FileSaver.saveAs(file)
  } catch (err) {
    log.error(
      `Error Downloading Knowledge Base Content for ID: ${kb.getId()} Error:`,
      err,
    )
  }
}
