export enum ROOT_ID_PARAM_TYPE {
  REPORT_SCAN_ID = 'reportScanId',
  REQUEST_ID = 'requestId',
  CONTROL_MODEL_ID = 'controlModelId', // can be control ID or control (custom) model ID
  POLICY_MODEL_ID = 'policyModelId',
  RISK_ID = 'riskId', // can be risk ID or risk custom ID
  AUDITBOT_RUN_ID = 'auditRunId',
  SECURITY_QUESTIONNAIRE = 'questionnaireId',
  SERVICE_ID = 'serviceId',
  RECEPTOR_ID = 'receptorId',
  VENDOR_ID = 'vendorId',
  EVIDENCE_ID = 'evidenceId',
  CHAT_ID = 'chatId',
}

export enum ControlsAbsoluteRoutes {
  ROOT = 'controls',
  INDEX = 'controls/index',
  INDEX_PAGE = 'controls/index?dismissed[]=false',
  SHOW = 'controls/show',
}

export enum ControlsSubRoutes {
  INDEX = 'index',
  SHOW = 'show',
}

export enum PoliciesAbsoluteRoutes {
  ROOT = 'policies',
  INDEX = 'policies/index',
  INDEX_PAGE = 'policies/index?dismissed[]=false',
  SHOW = 'policies/show',
}

export enum PoliciesSubRoutes {
  INDEX = 'index',
  SHOW = 'show',
}

export enum RisksAbsoluteRoutes {
  ROOT = 'risks',
  INDEX = 'risks/index',
  INDEX_PAGE = 'risks/index',
  SHOW = 'risks/show',
}

export enum ServicesAbsoluteRoutes {
  ROOT = 'services',
  SERVICES = 'services/services',
  RECEPTORS = 'services/receptors',
  INDEX = 'services/index',
  INDEX_PAGE = 'services/index',
  SHOW = 'services/show',
}

export enum ReceptorsAbsoluteRoutes {
  ROOT = 'receptors',
  INDEX = 'receptors/index',
  SHOW = 'receptors/show',
}

// Deprecated in favor of ExamineAndTestAbsoluteRoutes
// Keeping for redirect purposes
export enum AuditBotAbsoluteRoutes {
  ROOT = 'auditbot',
  INDEX = 'auditbot/index',
  INDEX_PAGE = 'auditbot/index',
  SHOW = 'auditbot/show',
}

export enum ExamineAndTestAbsoluteRoutes {
  ROOT = 'ai_examination_and_test',
  INDEX = 'ai_examination_and_test/index',
  INDEX_PAGE = 'ai_examination_and_test/index?page=1',
  SHOW = 'ai_examination_and_test/show',
}

export enum RoadmapAbsoluteRoutes {
  ROOT = 'roadmap',
  INDEX = 'roadmap/index',
  INDEX_PAGE = 'roadmap/index',
  SHOW = 'roadmap/show',
}

// deprecated in favor of AiGrcQaAbsoluteRoutes
// keeping for redirect purposes
export enum SecurityQuestionnaireAbsoluteRoutes {
  ROOT = 'security_questionnaire',
  INDEX = 'security_questionnaire/index',
  SHOW = 'security_questionnaire/show',
}

export enum AiGrcQaAbsoluteRoutes {
  ROOT = 'ai_grc_qa',
  INDEX = 'ai_grc_qa/index',
  SHOW = 'ai_grc_qa/show',
}

export enum TrusteroIntelligenceAbsoluteRoutes {
  ROOT = 'trustero_intelligence',
  INDEX = 'trustero_intelligence/index',
  SHOW = 'trustero_intelligence/show',
}

export enum VendorsAbsoluteRoutes {
  ROOT = 'vendors',
  INDEX = 'vendors/index',
  INDEX_PAGE = 'vendors/index',
  SHOW = 'vendors/show',
}

export enum EvidenceAbsoluteRoutes {
  ROOT = 'evidence',
  INDEX = 'evidence/index',
  INDEX_PAGE = 'evidence/index?page=1',
  SHOW = 'evidence/show',
}

export enum RequestsAbsoluteRoutes {
  ROOT = 'requests',
  INDEX = 'requests/index',
  SHOW = 'requests/show',
}

export enum SubRoutes {
  INDEX = 'index',
  SHOW = 'show',
}

export enum ScopeAbsoluteRoutes {
  ROOT = 'scope',
}

export enum DirectRoutes {
  DASHBOARD = 'dashboard',
  BUYER_DASHBOARD = 'buyer_dashboard',
  USERS = 'users',
  PROFILE = 'profile',
  COMPANY_INFO = 'company_info',
  ACCESS_DENIED = '/access_denied',
  AUDITS = 'audits',
  GENERIC_SIGNUP = 'signup',
  REPORT_SCAN_SIGNUP = 'scan_report',
  AI_SOC2_REPORT_SUMMARY_SIGNUP = 'ai_soc2_report_summary',
  SECURITY_QUESTIONNAIRE_SIGNUP = 'answer_questionnaire',
  AI_GRC_QA_SIGNUP = 'answer_grc_questions',
  SCOPE = 'scope',
  ACCOUNT_SETTINGS = 'account_settings',
  ACCOUNTS = 'accounts',
  MANAGE_DOCUMENTS = 'manage_documents',
  USAGE = 'usage',
  DOCUMENT_MANAGEMENT = 'document_management',
  GRC_PLATFORMS = 'grc_platforms',
  OIDC_EMAIL = 'oidc_email',
}

// deprecated in favor of AiSoc2ReportSummaryAbsoluteRoutes
// keeping for redirect purposes
export enum ReportScanAbsoluteRoutes {
  ROOT = 'report_scan',
  INDEX = 'report_scan/index',
  SHOW = 'report_scan/show',
}

export enum AiSoc2ReportSummaryAbsoluteRoutes {
  ROOT = 'ai_soc2_report_summary',
  INDEX = 'ai_soc2_report_summary/index',
  SHOW = 'ai_soc2_report_summary/show',
}
