// source: chatcontext/chatcontext.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
goog.exportSymbol('proto.chatcontext.ChatCitation', null, global);
goog.exportSymbol('proto.chatcontext.ChatContext', null, global);
goog.exportSymbol('proto.chatcontext.ChatContextSource', null, global);
goog.exportSymbol('proto.chatcontext.ChatContextSource.SourceCase', null, global);
goog.exportSymbol('proto.chatcontext.ChatContextSourceEvidenceFor', null, global);
goog.exportSymbol('proto.chatcontext.ChatContextSourceIncludedIds', null, global);
goog.exportSymbol('proto.chatcontext.ChatContextSourceType', null, global);
goog.exportSymbol('proto.chatcontext.ControlIds', null, global);
goog.exportSymbol('proto.chatcontext.CreateChatContextRequest', null, global);
goog.exportSymbol('proto.chatcontext.CreateChatContextResponse', null, global);
goog.exportSymbol('proto.chatcontext.EvidenceId', null, global);
goog.exportSymbol('proto.chatcontext.EvidenceIds', null, global);
goog.exportSymbol('proto.chatcontext.GetChatContextRequest', null, global);
goog.exportSymbol('proto.chatcontext.GetChatContextResponse', null, global);
goog.exportSymbol('proto.chatcontext.GetSourcesTotalsRequest', null, global);
goog.exportSymbol('proto.chatcontext.GetSourcesTotalsRequest.SourceIdTypeCase', null, global);
goog.exportSymbol('proto.chatcontext.GetSourcesTotalsResponse', null, global);
goog.exportSymbol('proto.chatcontext.IncludedIds', null, global);
goog.exportSymbol('proto.chatcontext.IncludedIds.SourceIdTypeCase', null, global);
goog.exportSymbol('proto.chatcontext.SourceTotal', null, global);
goog.exportSymbol('proto.chatcontext.UpdateChatContextRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chatcontext.EvidenceId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.chatcontext.EvidenceId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.chatcontext.EvidenceId.displayName = 'proto.chatcontext.EvidenceId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chatcontext.EvidenceIds = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.chatcontext.EvidenceIds.repeatedFields_, null);
};
goog.inherits(proto.chatcontext.EvidenceIds, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.chatcontext.EvidenceIds.displayName = 'proto.chatcontext.EvidenceIds';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chatcontext.ChatContextSourceIncludedIds = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.chatcontext.ChatContextSourceIncludedIds.repeatedFields_, null);
};
goog.inherits(proto.chatcontext.ChatContextSourceIncludedIds, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.chatcontext.ChatContextSourceIncludedIds.displayName = 'proto.chatcontext.ChatContextSourceIncludedIds';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chatcontext.IncludedIds = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.chatcontext.IncludedIds.oneofGroups_);
};
goog.inherits(proto.chatcontext.IncludedIds, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.chatcontext.IncludedIds.displayName = 'proto.chatcontext.IncludedIds';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chatcontext.ChatContextSource = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.chatcontext.ChatContextSource.oneofGroups_);
};
goog.inherits(proto.chatcontext.ChatContextSource, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.chatcontext.ChatContextSource.displayName = 'proto.chatcontext.ChatContextSource';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chatcontext.ChatContext = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.chatcontext.ChatContext.repeatedFields_, null);
};
goog.inherits(proto.chatcontext.ChatContext, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.chatcontext.ChatContext.displayName = 'proto.chatcontext.ChatContext';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chatcontext.GetChatContextRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.chatcontext.GetChatContextRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.chatcontext.GetChatContextRequest.displayName = 'proto.chatcontext.GetChatContextRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chatcontext.GetChatContextResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.chatcontext.GetChatContextResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.chatcontext.GetChatContextResponse.displayName = 'proto.chatcontext.GetChatContextResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chatcontext.CreateChatContextRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.chatcontext.CreateChatContextRequest.repeatedFields_, null);
};
goog.inherits(proto.chatcontext.CreateChatContextRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.chatcontext.CreateChatContextRequest.displayName = 'proto.chatcontext.CreateChatContextRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chatcontext.CreateChatContextResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.chatcontext.CreateChatContextResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.chatcontext.CreateChatContextResponse.displayName = 'proto.chatcontext.CreateChatContextResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chatcontext.UpdateChatContextRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.chatcontext.UpdateChatContextRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.chatcontext.UpdateChatContextRequest.displayName = 'proto.chatcontext.UpdateChatContextRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chatcontext.ControlIds = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.chatcontext.ControlIds.repeatedFields_, null);
};
goog.inherits(proto.chatcontext.ControlIds, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.chatcontext.ControlIds.displayName = 'proto.chatcontext.ControlIds';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chatcontext.GetSourcesTotalsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.chatcontext.GetSourcesTotalsRequest.oneofGroups_);
};
goog.inherits(proto.chatcontext.GetSourcesTotalsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.chatcontext.GetSourcesTotalsRequest.displayName = 'proto.chatcontext.GetSourcesTotalsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chatcontext.SourceTotal = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.chatcontext.SourceTotal, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.chatcontext.SourceTotal.displayName = 'proto.chatcontext.SourceTotal';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chatcontext.GetSourcesTotalsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.chatcontext.GetSourcesTotalsResponse.repeatedFields_, null);
};
goog.inherits(proto.chatcontext.GetSourcesTotalsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.chatcontext.GetSourcesTotalsResponse.displayName = 'proto.chatcontext.GetSourcesTotalsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chatcontext.ChatCitation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.chatcontext.ChatCitation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.chatcontext.ChatCitation.displayName = 'proto.chatcontext.ChatCitation';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chatcontext.EvidenceId.prototype.toObject = function(opt_includeInstance) {
  return proto.chatcontext.EvidenceId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chatcontext.EvidenceId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chatcontext.EvidenceId.toObject = function(includeInstance, msg) {
  var f, obj = {
    pb_for: jspb.Message.getFieldWithDefault(msg, 1, 0),
    id: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chatcontext.EvidenceId}
 */
proto.chatcontext.EvidenceId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chatcontext.EvidenceId;
  return proto.chatcontext.EvidenceId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chatcontext.EvidenceId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chatcontext.EvidenceId}
 */
proto.chatcontext.EvidenceId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.chatcontext.ChatContextSourceEvidenceFor} */ (reader.readEnum());
      msg.setFor(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chatcontext.EvidenceId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.chatcontext.EvidenceId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.chatcontext.EvidenceId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chatcontext.EvidenceId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFor();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional ChatContextSourceEvidenceFor for = 1;
 * @return {!proto.chatcontext.ChatContextSourceEvidenceFor}
 */
proto.chatcontext.EvidenceId.prototype.getFor = function() {
  return /** @type {!proto.chatcontext.ChatContextSourceEvidenceFor} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.chatcontext.ChatContextSourceEvidenceFor} value
 * @return {!proto.chatcontext.EvidenceId} returns this
 */
proto.chatcontext.EvidenceId.prototype.setFor = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string id = 2;
 * @return {string}
 */
proto.chatcontext.EvidenceId.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.chatcontext.EvidenceId} returns this
 */
proto.chatcontext.EvidenceId.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.chatcontext.EvidenceIds.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chatcontext.EvidenceIds.prototype.toObject = function(opt_includeInstance) {
  return proto.chatcontext.EvidenceIds.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chatcontext.EvidenceIds} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chatcontext.EvidenceIds.toObject = function(includeInstance, msg) {
  var f, obj = {
    idsList: jspb.Message.toObjectList(msg.getIdsList(),
    proto.chatcontext.EvidenceId.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chatcontext.EvidenceIds}
 */
proto.chatcontext.EvidenceIds.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chatcontext.EvidenceIds;
  return proto.chatcontext.EvidenceIds.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chatcontext.EvidenceIds} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chatcontext.EvidenceIds}
 */
proto.chatcontext.EvidenceIds.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.chatcontext.EvidenceId;
      reader.readMessage(value,proto.chatcontext.EvidenceId.deserializeBinaryFromReader);
      msg.addIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chatcontext.EvidenceIds.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.chatcontext.EvidenceIds.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.chatcontext.EvidenceIds} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chatcontext.EvidenceIds.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.chatcontext.EvidenceId.serializeBinaryToWriter
    );
  }
};


/**
 * repeated EvidenceId ids = 1;
 * @return {!Array<!proto.chatcontext.EvidenceId>}
 */
proto.chatcontext.EvidenceIds.prototype.getIdsList = function() {
  return /** @type{!Array<!proto.chatcontext.EvidenceId>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.chatcontext.EvidenceId, 1));
};


/**
 * @param {!Array<!proto.chatcontext.EvidenceId>} value
 * @return {!proto.chatcontext.EvidenceIds} returns this
*/
proto.chatcontext.EvidenceIds.prototype.setIdsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.chatcontext.EvidenceId=} opt_value
 * @param {number=} opt_index
 * @return {!proto.chatcontext.EvidenceId}
 */
proto.chatcontext.EvidenceIds.prototype.addIds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.chatcontext.EvidenceId, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.chatcontext.EvidenceIds} returns this
 */
proto.chatcontext.EvidenceIds.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.chatcontext.ChatContextSourceIncludedIds.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chatcontext.ChatContextSourceIncludedIds.prototype.toObject = function(opt_includeInstance) {
  return proto.chatcontext.ChatContextSourceIncludedIds.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chatcontext.ChatContextSourceIncludedIds} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chatcontext.ChatContextSourceIncludedIds.toObject = function(includeInstance, msg) {
  var f, obj = {
    idsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chatcontext.ChatContextSourceIncludedIds}
 */
proto.chatcontext.ChatContextSourceIncludedIds.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chatcontext.ChatContextSourceIncludedIds;
  return proto.chatcontext.ChatContextSourceIncludedIds.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chatcontext.ChatContextSourceIncludedIds} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chatcontext.ChatContextSourceIncludedIds}
 */
proto.chatcontext.ChatContextSourceIncludedIds.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chatcontext.ChatContextSourceIncludedIds.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.chatcontext.ChatContextSourceIncludedIds.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.chatcontext.ChatContextSourceIncludedIds} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chatcontext.ChatContextSourceIncludedIds.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string ids = 1;
 * @return {!Array<string>}
 */
proto.chatcontext.ChatContextSourceIncludedIds.prototype.getIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.chatcontext.ChatContextSourceIncludedIds} returns this
 */
proto.chatcontext.ChatContextSourceIncludedIds.prototype.setIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.chatcontext.ChatContextSourceIncludedIds} returns this
 */
proto.chatcontext.ChatContextSourceIncludedIds.prototype.addIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.chatcontext.ChatContextSourceIncludedIds} returns this
 */
proto.chatcontext.ChatContextSourceIncludedIds.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.chatcontext.IncludedIds.oneofGroups_ = [[3,4]];

/**
 * @enum {number}
 */
proto.chatcontext.IncludedIds.SourceIdTypeCase = {
  SOURCE_ID_TYPE_NOT_SET: 0,
  ID_TYPE: 3,
  EVIDENCE_TYPE: 4
};

/**
 * @return {proto.chatcontext.IncludedIds.SourceIdTypeCase}
 */
proto.chatcontext.IncludedIds.prototype.getSourceIdTypeCase = function() {
  return /** @type {proto.chatcontext.IncludedIds.SourceIdTypeCase} */(jspb.Message.computeOneofCase(this, proto.chatcontext.IncludedIds.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chatcontext.IncludedIds.prototype.toObject = function(opt_includeInstance) {
  return proto.chatcontext.IncludedIds.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chatcontext.IncludedIds} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chatcontext.IncludedIds.toObject = function(includeInstance, msg) {
  var f, obj = {
    idType: (f = msg.getIdType()) && proto.chatcontext.ChatContextSourceIncludedIds.toObject(includeInstance, f),
    evidenceType: (f = msg.getEvidenceType()) && proto.chatcontext.EvidenceIds.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chatcontext.IncludedIds}
 */
proto.chatcontext.IncludedIds.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chatcontext.IncludedIds;
  return proto.chatcontext.IncludedIds.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chatcontext.IncludedIds} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chatcontext.IncludedIds}
 */
proto.chatcontext.IncludedIds.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = new proto.chatcontext.ChatContextSourceIncludedIds;
      reader.readMessage(value,proto.chatcontext.ChatContextSourceIncludedIds.deserializeBinaryFromReader);
      msg.setIdType(value);
      break;
    case 4:
      var value = new proto.chatcontext.EvidenceIds;
      reader.readMessage(value,proto.chatcontext.EvidenceIds.deserializeBinaryFromReader);
      msg.setEvidenceType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chatcontext.IncludedIds.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.chatcontext.IncludedIds.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.chatcontext.IncludedIds} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chatcontext.IncludedIds.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdType();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.chatcontext.ChatContextSourceIncludedIds.serializeBinaryToWriter
    );
  }
  f = message.getEvidenceType();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.chatcontext.EvidenceIds.serializeBinaryToWriter
    );
  }
};


/**
 * optional ChatContextSourceIncludedIds id_type = 3;
 * @return {?proto.chatcontext.ChatContextSourceIncludedIds}
 */
proto.chatcontext.IncludedIds.prototype.getIdType = function() {
  return /** @type{?proto.chatcontext.ChatContextSourceIncludedIds} */ (
    jspb.Message.getWrapperField(this, proto.chatcontext.ChatContextSourceIncludedIds, 3));
};


/**
 * @param {?proto.chatcontext.ChatContextSourceIncludedIds|undefined} value
 * @return {!proto.chatcontext.IncludedIds} returns this
*/
proto.chatcontext.IncludedIds.prototype.setIdType = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.chatcontext.IncludedIds.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.chatcontext.IncludedIds} returns this
 */
proto.chatcontext.IncludedIds.prototype.clearIdType = function() {
  return this.setIdType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.chatcontext.IncludedIds.prototype.hasIdType = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional EvidenceIds evidence_type = 4;
 * @return {?proto.chatcontext.EvidenceIds}
 */
proto.chatcontext.IncludedIds.prototype.getEvidenceType = function() {
  return /** @type{?proto.chatcontext.EvidenceIds} */ (
    jspb.Message.getWrapperField(this, proto.chatcontext.EvidenceIds, 4));
};


/**
 * @param {?proto.chatcontext.EvidenceIds|undefined} value
 * @return {!proto.chatcontext.IncludedIds} returns this
*/
proto.chatcontext.IncludedIds.prototype.setEvidenceType = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.chatcontext.IncludedIds.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.chatcontext.IncludedIds} returns this
 */
proto.chatcontext.IncludedIds.prototype.clearEvidenceType = function() {
  return this.setEvidenceType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.chatcontext.IncludedIds.prototype.hasEvidenceType = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.chatcontext.ChatContextSource.oneofGroups_ = [[2,3]];

/**
 * @enum {number}
 */
proto.chatcontext.ChatContextSource.SourceCase = {
  SOURCE_NOT_SET: 0,
  INCLUDE_ALL: 2,
  INCLUDED_IDS: 3
};

/**
 * @return {proto.chatcontext.ChatContextSource.SourceCase}
 */
proto.chatcontext.ChatContextSource.prototype.getSourceCase = function() {
  return /** @type {proto.chatcontext.ChatContextSource.SourceCase} */(jspb.Message.computeOneofCase(this, proto.chatcontext.ChatContextSource.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chatcontext.ChatContextSource.prototype.toObject = function(opt_includeInstance) {
  return proto.chatcontext.ChatContextSource.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chatcontext.ChatContextSource} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chatcontext.ChatContextSource.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    includeAll: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    includedIds: (f = msg.getIncludedIds()) && proto.chatcontext.IncludedIds.toObject(includeInstance, f),
    referenceId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chatcontext.ChatContextSource}
 */
proto.chatcontext.ChatContextSource.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chatcontext.ChatContextSource;
  return proto.chatcontext.ChatContextSource.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chatcontext.ChatContextSource} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chatcontext.ChatContextSource}
 */
proto.chatcontext.ChatContextSource.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.chatcontext.ChatContextSourceType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeAll(value);
      break;
    case 3:
      var value = new proto.chatcontext.IncludedIds;
      reader.readMessage(value,proto.chatcontext.IncludedIds.deserializeBinaryFromReader);
      msg.setIncludedIds(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setReferenceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chatcontext.ChatContextSource.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.chatcontext.ChatContextSource.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.chatcontext.ChatContextSource} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chatcontext.ChatContextSource.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getIncludedIds();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.chatcontext.IncludedIds.serializeBinaryToWriter
    );
  }
  f = message.getReferenceId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional ChatContextSourceType type = 1;
 * @return {!proto.chatcontext.ChatContextSourceType}
 */
proto.chatcontext.ChatContextSource.prototype.getType = function() {
  return /** @type {!proto.chatcontext.ChatContextSourceType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.chatcontext.ChatContextSourceType} value
 * @return {!proto.chatcontext.ChatContextSource} returns this
 */
proto.chatcontext.ChatContextSource.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional bool include_all = 2;
 * @return {boolean}
 */
proto.chatcontext.ChatContextSource.prototype.getIncludeAll = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.chatcontext.ChatContextSource} returns this
 */
proto.chatcontext.ChatContextSource.prototype.setIncludeAll = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.chatcontext.ChatContextSource.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.chatcontext.ChatContextSource} returns this
 */
proto.chatcontext.ChatContextSource.prototype.clearIncludeAll = function() {
  return jspb.Message.setOneofField(this, 2, proto.chatcontext.ChatContextSource.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.chatcontext.ChatContextSource.prototype.hasIncludeAll = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional IncludedIds included_ids = 3;
 * @return {?proto.chatcontext.IncludedIds}
 */
proto.chatcontext.ChatContextSource.prototype.getIncludedIds = function() {
  return /** @type{?proto.chatcontext.IncludedIds} */ (
    jspb.Message.getWrapperField(this, proto.chatcontext.IncludedIds, 3));
};


/**
 * @param {?proto.chatcontext.IncludedIds|undefined} value
 * @return {!proto.chatcontext.ChatContextSource} returns this
*/
proto.chatcontext.ChatContextSource.prototype.setIncludedIds = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.chatcontext.ChatContextSource.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.chatcontext.ChatContextSource} returns this
 */
proto.chatcontext.ChatContextSource.prototype.clearIncludedIds = function() {
  return this.setIncludedIds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.chatcontext.ChatContextSource.prototype.hasIncludedIds = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string reference_id = 4;
 * @return {string}
 */
proto.chatcontext.ChatContextSource.prototype.getReferenceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.chatcontext.ChatContextSource} returns this
 */
proto.chatcontext.ChatContextSource.prototype.setReferenceId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.chatcontext.ChatContext.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chatcontext.ChatContext.prototype.toObject = function(opt_includeInstance) {
  return proto.chatcontext.ChatContext.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chatcontext.ChatContext} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chatcontext.ChatContext.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sourcesList: jspb.Message.toObjectList(msg.getSourcesList(),
    proto.chatcontext.ChatContextSource.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chatcontext.ChatContext}
 */
proto.chatcontext.ChatContext.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chatcontext.ChatContext;
  return proto.chatcontext.ChatContext.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chatcontext.ChatContext} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chatcontext.ChatContext}
 */
proto.chatcontext.ChatContext.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.chatcontext.ChatContextSource;
      reader.readMessage(value,proto.chatcontext.ChatContextSource.deserializeBinaryFromReader);
      msg.addSources(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chatcontext.ChatContext.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.chatcontext.ChatContext.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.chatcontext.ChatContext} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chatcontext.ChatContext.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSourcesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.chatcontext.ChatContextSource.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.chatcontext.ChatContext.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.chatcontext.ChatContext} returns this
 */
proto.chatcontext.ChatContext.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated ChatContextSource sources = 2;
 * @return {!Array<!proto.chatcontext.ChatContextSource>}
 */
proto.chatcontext.ChatContext.prototype.getSourcesList = function() {
  return /** @type{!Array<!proto.chatcontext.ChatContextSource>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.chatcontext.ChatContextSource, 2));
};


/**
 * @param {!Array<!proto.chatcontext.ChatContextSource>} value
 * @return {!proto.chatcontext.ChatContext} returns this
*/
proto.chatcontext.ChatContext.prototype.setSourcesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.chatcontext.ChatContextSource=} opt_value
 * @param {number=} opt_index
 * @return {!proto.chatcontext.ChatContextSource}
 */
proto.chatcontext.ChatContext.prototype.addSources = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.chatcontext.ChatContextSource, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.chatcontext.ChatContext} returns this
 */
proto.chatcontext.ChatContext.prototype.clearSourcesList = function() {
  return this.setSourcesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chatcontext.GetChatContextRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.chatcontext.GetChatContextRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chatcontext.GetChatContextRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chatcontext.GetChatContextRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chatcontext.GetChatContextRequest}
 */
proto.chatcontext.GetChatContextRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chatcontext.GetChatContextRequest;
  return proto.chatcontext.GetChatContextRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chatcontext.GetChatContextRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chatcontext.GetChatContextRequest}
 */
proto.chatcontext.GetChatContextRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chatcontext.GetChatContextRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.chatcontext.GetChatContextRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.chatcontext.GetChatContextRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chatcontext.GetChatContextRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.chatcontext.GetChatContextRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.chatcontext.GetChatContextRequest} returns this
 */
proto.chatcontext.GetChatContextRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chatcontext.GetChatContextResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.chatcontext.GetChatContextResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chatcontext.GetChatContextResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chatcontext.GetChatContextResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    context: (f = msg.getContext()) && proto.chatcontext.ChatContext.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chatcontext.GetChatContextResponse}
 */
proto.chatcontext.GetChatContextResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chatcontext.GetChatContextResponse;
  return proto.chatcontext.GetChatContextResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chatcontext.GetChatContextResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chatcontext.GetChatContextResponse}
 */
proto.chatcontext.GetChatContextResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.chatcontext.ChatContext;
      reader.readMessage(value,proto.chatcontext.ChatContext.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chatcontext.GetChatContextResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.chatcontext.GetChatContextResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.chatcontext.GetChatContextResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chatcontext.GetChatContextResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.chatcontext.ChatContext.serializeBinaryToWriter
    );
  }
};


/**
 * optional ChatContext context = 1;
 * @return {?proto.chatcontext.ChatContext}
 */
proto.chatcontext.GetChatContextResponse.prototype.getContext = function() {
  return /** @type{?proto.chatcontext.ChatContext} */ (
    jspb.Message.getWrapperField(this, proto.chatcontext.ChatContext, 1));
};


/**
 * @param {?proto.chatcontext.ChatContext|undefined} value
 * @return {!proto.chatcontext.GetChatContextResponse} returns this
*/
proto.chatcontext.GetChatContextResponse.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.chatcontext.GetChatContextResponse} returns this
 */
proto.chatcontext.GetChatContextResponse.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.chatcontext.GetChatContextResponse.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.chatcontext.CreateChatContextRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chatcontext.CreateChatContextRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.chatcontext.CreateChatContextRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chatcontext.CreateChatContextRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chatcontext.CreateChatContextRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    sourcesList: jspb.Message.toObjectList(msg.getSourcesList(),
    proto.chatcontext.ChatContextSource.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chatcontext.CreateChatContextRequest}
 */
proto.chatcontext.CreateChatContextRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chatcontext.CreateChatContextRequest;
  return proto.chatcontext.CreateChatContextRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chatcontext.CreateChatContextRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chatcontext.CreateChatContextRequest}
 */
proto.chatcontext.CreateChatContextRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new proto.chatcontext.ChatContextSource;
      reader.readMessage(value,proto.chatcontext.ChatContextSource.deserializeBinaryFromReader);
      msg.addSources(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chatcontext.CreateChatContextRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.chatcontext.CreateChatContextRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.chatcontext.CreateChatContextRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chatcontext.CreateChatContextRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSourcesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.chatcontext.ChatContextSource.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ChatContextSource sources = 2;
 * @return {!Array<!proto.chatcontext.ChatContextSource>}
 */
proto.chatcontext.CreateChatContextRequest.prototype.getSourcesList = function() {
  return /** @type{!Array<!proto.chatcontext.ChatContextSource>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.chatcontext.ChatContextSource, 2));
};


/**
 * @param {!Array<!proto.chatcontext.ChatContextSource>} value
 * @return {!proto.chatcontext.CreateChatContextRequest} returns this
*/
proto.chatcontext.CreateChatContextRequest.prototype.setSourcesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.chatcontext.ChatContextSource=} opt_value
 * @param {number=} opt_index
 * @return {!proto.chatcontext.ChatContextSource}
 */
proto.chatcontext.CreateChatContextRequest.prototype.addSources = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.chatcontext.ChatContextSource, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.chatcontext.CreateChatContextRequest} returns this
 */
proto.chatcontext.CreateChatContextRequest.prototype.clearSourcesList = function() {
  return this.setSourcesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chatcontext.CreateChatContextResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.chatcontext.CreateChatContextResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chatcontext.CreateChatContextResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chatcontext.CreateChatContextResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    context: (f = msg.getContext()) && proto.chatcontext.ChatContext.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chatcontext.CreateChatContextResponse}
 */
proto.chatcontext.CreateChatContextResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chatcontext.CreateChatContextResponse;
  return proto.chatcontext.CreateChatContextResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chatcontext.CreateChatContextResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chatcontext.CreateChatContextResponse}
 */
proto.chatcontext.CreateChatContextResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.chatcontext.ChatContext;
      reader.readMessage(value,proto.chatcontext.ChatContext.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chatcontext.CreateChatContextResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.chatcontext.CreateChatContextResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.chatcontext.CreateChatContextResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chatcontext.CreateChatContextResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.chatcontext.ChatContext.serializeBinaryToWriter
    );
  }
};


/**
 * optional ChatContext context = 1;
 * @return {?proto.chatcontext.ChatContext}
 */
proto.chatcontext.CreateChatContextResponse.prototype.getContext = function() {
  return /** @type{?proto.chatcontext.ChatContext} */ (
    jspb.Message.getWrapperField(this, proto.chatcontext.ChatContext, 1));
};


/**
 * @param {?proto.chatcontext.ChatContext|undefined} value
 * @return {!proto.chatcontext.CreateChatContextResponse} returns this
*/
proto.chatcontext.CreateChatContextResponse.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.chatcontext.CreateChatContextResponse} returns this
 */
proto.chatcontext.CreateChatContextResponse.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.chatcontext.CreateChatContextResponse.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chatcontext.UpdateChatContextRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.chatcontext.UpdateChatContextRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chatcontext.UpdateChatContextRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chatcontext.UpdateChatContextRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    context: (f = msg.getContext()) && proto.chatcontext.ChatContext.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chatcontext.UpdateChatContextRequest}
 */
proto.chatcontext.UpdateChatContextRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chatcontext.UpdateChatContextRequest;
  return proto.chatcontext.UpdateChatContextRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chatcontext.UpdateChatContextRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chatcontext.UpdateChatContextRequest}
 */
proto.chatcontext.UpdateChatContextRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.chatcontext.ChatContext;
      reader.readMessage(value,proto.chatcontext.ChatContext.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chatcontext.UpdateChatContextRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.chatcontext.UpdateChatContextRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.chatcontext.UpdateChatContextRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chatcontext.UpdateChatContextRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.chatcontext.ChatContext.serializeBinaryToWriter
    );
  }
};


/**
 * optional ChatContext context = 1;
 * @return {?proto.chatcontext.ChatContext}
 */
proto.chatcontext.UpdateChatContextRequest.prototype.getContext = function() {
  return /** @type{?proto.chatcontext.ChatContext} */ (
    jspb.Message.getWrapperField(this, proto.chatcontext.ChatContext, 1));
};


/**
 * @param {?proto.chatcontext.ChatContext|undefined} value
 * @return {!proto.chatcontext.UpdateChatContextRequest} returns this
*/
proto.chatcontext.UpdateChatContextRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.chatcontext.UpdateChatContextRequest} returns this
 */
proto.chatcontext.UpdateChatContextRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.chatcontext.UpdateChatContextRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.chatcontext.ControlIds.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chatcontext.ControlIds.prototype.toObject = function(opt_includeInstance) {
  return proto.chatcontext.ControlIds.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chatcontext.ControlIds} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chatcontext.ControlIds.toObject = function(includeInstance, msg) {
  var f, obj = {
    idsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chatcontext.ControlIds}
 */
proto.chatcontext.ControlIds.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chatcontext.ControlIds;
  return proto.chatcontext.ControlIds.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chatcontext.ControlIds} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chatcontext.ControlIds}
 */
proto.chatcontext.ControlIds.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chatcontext.ControlIds.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.chatcontext.ControlIds.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.chatcontext.ControlIds} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chatcontext.ControlIds.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string ids = 1;
 * @return {!Array<string>}
 */
proto.chatcontext.ControlIds.prototype.getIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.chatcontext.ControlIds} returns this
 */
proto.chatcontext.ControlIds.prototype.setIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.chatcontext.ControlIds} returns this
 */
proto.chatcontext.ControlIds.prototype.addIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.chatcontext.ControlIds} returns this
 */
proto.chatcontext.ControlIds.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.chatcontext.GetSourcesTotalsRequest.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.chatcontext.GetSourcesTotalsRequest.SourceIdTypeCase = {
  SOURCE_ID_TYPE_NOT_SET: 0,
  CHAT_CONTEXT_ID: 1,
  CONTROL_IDS: 2
};

/**
 * @return {proto.chatcontext.GetSourcesTotalsRequest.SourceIdTypeCase}
 */
proto.chatcontext.GetSourcesTotalsRequest.prototype.getSourceIdTypeCase = function() {
  return /** @type {proto.chatcontext.GetSourcesTotalsRequest.SourceIdTypeCase} */(jspb.Message.computeOneofCase(this, proto.chatcontext.GetSourcesTotalsRequest.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chatcontext.GetSourcesTotalsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.chatcontext.GetSourcesTotalsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chatcontext.GetSourcesTotalsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chatcontext.GetSourcesTotalsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    chatContextId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    controlIds: (f = msg.getControlIds()) && proto.chatcontext.ControlIds.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chatcontext.GetSourcesTotalsRequest}
 */
proto.chatcontext.GetSourcesTotalsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chatcontext.GetSourcesTotalsRequest;
  return proto.chatcontext.GetSourcesTotalsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chatcontext.GetSourcesTotalsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chatcontext.GetSourcesTotalsRequest}
 */
proto.chatcontext.GetSourcesTotalsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setChatContextId(value);
      break;
    case 2:
      var value = new proto.chatcontext.ControlIds;
      reader.readMessage(value,proto.chatcontext.ControlIds.deserializeBinaryFromReader);
      msg.setControlIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chatcontext.GetSourcesTotalsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.chatcontext.GetSourcesTotalsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.chatcontext.GetSourcesTotalsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chatcontext.GetSourcesTotalsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getControlIds();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.chatcontext.ControlIds.serializeBinaryToWriter
    );
  }
};


/**
 * optional string chat_context_id = 1;
 * @return {string}
 */
proto.chatcontext.GetSourcesTotalsRequest.prototype.getChatContextId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.chatcontext.GetSourcesTotalsRequest} returns this
 */
proto.chatcontext.GetSourcesTotalsRequest.prototype.setChatContextId = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.chatcontext.GetSourcesTotalsRequest.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.chatcontext.GetSourcesTotalsRequest} returns this
 */
proto.chatcontext.GetSourcesTotalsRequest.prototype.clearChatContextId = function() {
  return jspb.Message.setOneofField(this, 1, proto.chatcontext.GetSourcesTotalsRequest.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.chatcontext.GetSourcesTotalsRequest.prototype.hasChatContextId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ControlIds control_ids = 2;
 * @return {?proto.chatcontext.ControlIds}
 */
proto.chatcontext.GetSourcesTotalsRequest.prototype.getControlIds = function() {
  return /** @type{?proto.chatcontext.ControlIds} */ (
    jspb.Message.getWrapperField(this, proto.chatcontext.ControlIds, 2));
};


/**
 * @param {?proto.chatcontext.ControlIds|undefined} value
 * @return {!proto.chatcontext.GetSourcesTotalsRequest} returns this
*/
proto.chatcontext.GetSourcesTotalsRequest.prototype.setControlIds = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.chatcontext.GetSourcesTotalsRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.chatcontext.GetSourcesTotalsRequest} returns this
 */
proto.chatcontext.GetSourcesTotalsRequest.prototype.clearControlIds = function() {
  return this.setControlIds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.chatcontext.GetSourcesTotalsRequest.prototype.hasControlIds = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chatcontext.SourceTotal.prototype.toObject = function(opt_includeInstance) {
  return proto.chatcontext.SourceTotal.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chatcontext.SourceTotal} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chatcontext.SourceTotal.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    total: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chatcontext.SourceTotal}
 */
proto.chatcontext.SourceTotal.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chatcontext.SourceTotal;
  return proto.chatcontext.SourceTotal.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chatcontext.SourceTotal} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chatcontext.SourceTotal}
 */
proto.chatcontext.SourceTotal.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.chatcontext.ChatContextSourceType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chatcontext.SourceTotal.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.chatcontext.SourceTotal.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.chatcontext.SourceTotal} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chatcontext.SourceTotal.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional ChatContextSourceType type = 1;
 * @return {!proto.chatcontext.ChatContextSourceType}
 */
proto.chatcontext.SourceTotal.prototype.getType = function() {
  return /** @type {!proto.chatcontext.ChatContextSourceType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.chatcontext.ChatContextSourceType} value
 * @return {!proto.chatcontext.SourceTotal} returns this
 */
proto.chatcontext.SourceTotal.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional int32 total = 2;
 * @return {number}
 */
proto.chatcontext.SourceTotal.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.chatcontext.SourceTotal} returns this
 */
proto.chatcontext.SourceTotal.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.chatcontext.GetSourcesTotalsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chatcontext.GetSourcesTotalsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.chatcontext.GetSourcesTotalsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chatcontext.GetSourcesTotalsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chatcontext.GetSourcesTotalsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalsList: jspb.Message.toObjectList(msg.getTotalsList(),
    proto.chatcontext.SourceTotal.toObject, includeInstance),
    totalEvidenceForControls: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chatcontext.GetSourcesTotalsResponse}
 */
proto.chatcontext.GetSourcesTotalsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chatcontext.GetSourcesTotalsResponse;
  return proto.chatcontext.GetSourcesTotalsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chatcontext.GetSourcesTotalsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chatcontext.GetSourcesTotalsResponse}
 */
proto.chatcontext.GetSourcesTotalsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.chatcontext.SourceTotal;
      reader.readMessage(value,proto.chatcontext.SourceTotal.deserializeBinaryFromReader);
      msg.addTotals(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalEvidenceForControls(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chatcontext.GetSourcesTotalsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.chatcontext.GetSourcesTotalsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.chatcontext.GetSourcesTotalsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chatcontext.GetSourcesTotalsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.chatcontext.SourceTotal.serializeBinaryToWriter
    );
  }
  f = message.getTotalEvidenceForControls();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * repeated SourceTotal totals = 1;
 * @return {!Array<!proto.chatcontext.SourceTotal>}
 */
proto.chatcontext.GetSourcesTotalsResponse.prototype.getTotalsList = function() {
  return /** @type{!Array<!proto.chatcontext.SourceTotal>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.chatcontext.SourceTotal, 1));
};


/**
 * @param {!Array<!proto.chatcontext.SourceTotal>} value
 * @return {!proto.chatcontext.GetSourcesTotalsResponse} returns this
*/
proto.chatcontext.GetSourcesTotalsResponse.prototype.setTotalsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.chatcontext.SourceTotal=} opt_value
 * @param {number=} opt_index
 * @return {!proto.chatcontext.SourceTotal}
 */
proto.chatcontext.GetSourcesTotalsResponse.prototype.addTotals = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.chatcontext.SourceTotal, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.chatcontext.GetSourcesTotalsResponse} returns this
 */
proto.chatcontext.GetSourcesTotalsResponse.prototype.clearTotalsList = function() {
  return this.setTotalsList([]);
};


/**
 * optional int32 total_evidence_for_controls = 2;
 * @return {number}
 */
proto.chatcontext.GetSourcesTotalsResponse.prototype.getTotalEvidenceForControls = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.chatcontext.GetSourcesTotalsResponse} returns this
 */
proto.chatcontext.GetSourcesTotalsResponse.prototype.setTotalEvidenceForControls = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chatcontext.ChatCitation.prototype.toObject = function(opt_includeInstance) {
  return proto.chatcontext.ChatCitation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chatcontext.ChatCitation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chatcontext.ChatCitation.toObject = function(includeInstance, msg) {
  var f, obj = {
    referenceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    caption: jspb.Message.getFieldWithDefault(msg, 2, ""),
    mimetype: jspb.Message.getFieldWithDefault(msg, 3, ""),
    relevantDate: jspb.Message.getFieldWithDefault(msg, 4, ""),
    type: jspb.Message.getFieldWithDefault(msg, 5, 0),
    referenceOffset: jspb.Message.getFieldWithDefault(msg, 6, ""),
    contentHash: jspb.Message.getFieldWithDefault(msg, 7, ""),
    chunkHash: jspb.Message.getFieldWithDefault(msg, 8, ""),
    score: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    content: jspb.Message.getFieldWithDefault(msg, 10, ""),
    isDeleted: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    contentId: jspb.Message.getFieldWithDefault(msg, 12, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chatcontext.ChatCitation}
 */
proto.chatcontext.ChatCitation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chatcontext.ChatCitation;
  return proto.chatcontext.ChatCitation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chatcontext.ChatCitation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chatcontext.ChatCitation}
 */
proto.chatcontext.ChatCitation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReferenceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCaption(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMimetype(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setRelevantDate(value);
      break;
    case 5:
      var value = /** @type {!proto.chatcontext.ChatContextSourceType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setReferenceOffset(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setContentHash(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setChunkHash(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setScore(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setContent(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDeleted(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setContentId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chatcontext.ChatCitation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.chatcontext.ChatCitation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.chatcontext.ChatCitation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chatcontext.ChatCitation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReferenceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCaption();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMimetype();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRelevantDate();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getReferenceOffset();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getContentHash();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getChunkHash();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getScore();
  if (f !== 0.0) {
    writer.writeFloat(
      9,
      f
    );
  }
  f = message.getContent();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getIsDeleted();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getContentId();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
};


/**
 * optional string reference_id = 1;
 * @return {string}
 */
proto.chatcontext.ChatCitation.prototype.getReferenceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.chatcontext.ChatCitation} returns this
 */
proto.chatcontext.ChatCitation.prototype.setReferenceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string caption = 2;
 * @return {string}
 */
proto.chatcontext.ChatCitation.prototype.getCaption = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.chatcontext.ChatCitation} returns this
 */
proto.chatcontext.ChatCitation.prototype.setCaption = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string mimetype = 3;
 * @return {string}
 */
proto.chatcontext.ChatCitation.prototype.getMimetype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.chatcontext.ChatCitation} returns this
 */
proto.chatcontext.ChatCitation.prototype.setMimetype = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string relevant_date = 4;
 * @return {string}
 */
proto.chatcontext.ChatCitation.prototype.getRelevantDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.chatcontext.ChatCitation} returns this
 */
proto.chatcontext.ChatCitation.prototype.setRelevantDate = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional ChatContextSourceType type = 5;
 * @return {!proto.chatcontext.ChatContextSourceType}
 */
proto.chatcontext.ChatCitation.prototype.getType = function() {
  return /** @type {!proto.chatcontext.ChatContextSourceType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.chatcontext.ChatContextSourceType} value
 * @return {!proto.chatcontext.ChatCitation} returns this
 */
proto.chatcontext.ChatCitation.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional string reference_offset = 6;
 * @return {string}
 */
proto.chatcontext.ChatCitation.prototype.getReferenceOffset = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.chatcontext.ChatCitation} returns this
 */
proto.chatcontext.ChatCitation.prototype.setReferenceOffset = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string content_hash = 7;
 * @return {string}
 */
proto.chatcontext.ChatCitation.prototype.getContentHash = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.chatcontext.ChatCitation} returns this
 */
proto.chatcontext.ChatCitation.prototype.setContentHash = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string chunk_hash = 8;
 * @return {string}
 */
proto.chatcontext.ChatCitation.prototype.getChunkHash = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.chatcontext.ChatCitation} returns this
 */
proto.chatcontext.ChatCitation.prototype.setChunkHash = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional float score = 9;
 * @return {number}
 */
proto.chatcontext.ChatCitation.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.chatcontext.ChatCitation} returns this
 */
proto.chatcontext.ChatCitation.prototype.setScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional string content = 10;
 * @return {string}
 */
proto.chatcontext.ChatCitation.prototype.getContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.chatcontext.ChatCitation} returns this
 */
proto.chatcontext.ChatCitation.prototype.setContent = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional bool is_deleted = 11;
 * @return {boolean}
 */
proto.chatcontext.ChatCitation.prototype.getIsDeleted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.chatcontext.ChatCitation} returns this
 */
proto.chatcontext.ChatCitation.prototype.setIsDeleted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional string content_id = 12;
 * @return {string}
 */
proto.chatcontext.ChatCitation.prototype.getContentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.chatcontext.ChatCitation} returns this
 */
proto.chatcontext.ChatCitation.prototype.setContentId = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * @enum {number}
 */
proto.chatcontext.ChatContextSourceType = {
  KNOWLEDGE_BASE: 0,
  POLICY: 1,
  CONTROL: 2,
  DOCUMENT_GROUP: 3,
  GRC_FRAMEWORK: 4,
  RECEPTOR: 5,
  SUPPORT_DOCUMENT: 6,
  COMPANY: 7,
  EVIDENCE: 8
};

/**
 * @enum {number}
 */
proto.chatcontext.ChatContextSourceEvidenceFor = {
  FOR_SELF: 0,
  FOR_CONTROL: 1,
  FOR_RECEPTOR: 2
};

goog.object.extend(exports, proto.chatcontext);
