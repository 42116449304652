import React, { RefObject, useEffect, useMemo, useRef, useState } from 'react'
import log from 'loglevel'
import styled from 'styled-components/macro'
import { SpaceProps } from 'styled-system'
import {
  UserRecord,
  UserState,
} from '@trustero/trustero-api-web/lib/account/account_pb'
import { Owner } from '@trustero/trustero-api-web/lib/model/model_pb'
import { ModelPromiseClient } from '@trustero/trustero-api-web/lib/model/model_grpc_web_pb'
import { Identifier } from '@trustero/trustero-api-web/lib/common/model_pb'
import { MODEL_TYPE } from '@trustero/trustero-api-web/lib/common/model_pb'
import { useCreateOrUpdateRisks } from 'src/pages/Risks/risks.hooks'
import { Risk } from '@trustero/trustero-api-web/lib/risk/risk_pb'
import { Questionnaire } from '@trustero/trustero-api-web/lib/questionnaire/questionnaire_pb'
import { useUpdateQuestionnaire } from 'src/pages/SecurityQuestionnaire/securityQuestionnaire.hooks'
import { AttestationRecord } from '@trustero/trustero-api-web/lib/vendormanagement/attestation_pb'
import { PERMISSIONS } from 'src/config/roleConfig'
import { UpdateVendorRequest } from '@trustero/trustero-api-web/lib/vendormanagement/vendormanagement_pb'
import { StringValue } from 'google-protobuf/google/protobuf/wrappers_pb'
import { useHasRequiredPermissions } from 'src/app/AppAuth/AppAuth.hooks'
import { useUpdateVendors } from 'src/pages/Vendors/vendors.hooks'
import useClickOutside from '../../../../Utils/useClickOutside'
import * as keyDown from '../../Dropdown/keyboardUtils'
import {
  DropdownItem,
  DropdownItemUser,
  DropdownMenu,
  DropdownSelectedIcon,
} from '../../Dropdown/styles'
import { Tooltip } from '../../Tooltip'
import { IconButton, IconButtonVariant } from '../IconButton'
import { Gravatar } from '../../../Gravatar'
import { useAuthorizedGrpcClientWithContentUpdate } from '../../../../adapter/grpcClient'
import { modelIdToType } from '../../../../adapter'
import { useUsers } from '../../../async/Users/useUsers'
import { Assignee } from '../../../../Utils/globalEnums'

const OwnerAssignmentContainer = styled.div`
  position: relative;
`

type OwnerAssignmentButtonProps = SpaceProps & {
  model?: Risk | Questionnaire | AttestationRecord
  modelId: string
  modelType?: MODEL_TYPE // Used to discern Risks/AuditBot Runs from Controls/Policies
  email: string | null
  requiredPermissions?: PERMISSIONS[]
  isDisabled?: boolean
  onUpdate?: () => void
  id?: string
}

export const OwnerAssignmentButton = ({
  model,
  modelId,
  modelType,
  email = Assignee.UNASSIGNED,
  requiredPermissions = [PERMISSIONS.READ, PERMISSIONS.EDIT],
  isDisabled,
  onUpdate,
  ...spaceProps
}: OwnerAssignmentButtonProps): JSX.Element => {
  const hasPermission = useHasRequiredPermissions(requiredPermissions)
  const response = useUsers()
  const [menuIsToggled, setMenuIsToggled] = useState(false)
  const [showTooltip, setShowTooltip] = useState(false)
  const containerRef = useClickOutside(() => {
    setMenuIsToggled(false)
  }) as RefObject<HTMLDivElement>

  const activeUsers = useMemo(() => {
    const activeUsers: UserRecord.AsObject[] = [
      {
        name: Assignee.UNASSIGNED,
        email: Assignee.UNASSIGNED,
      } as UserRecord.AsObject,
    ]
    if (response.data) {
      activeUsers.push(
        ...response.data
          .getUsersList()
          .map((userMsg) => {
            return userMsg.toObject()
          })
          .filter((user) => user.state === UserState.USER_ACTIVE),
      )
    }
    return activeUsers
  }, [response.data])

  // List of nodes
  const nodesRef = useRef<HTMLButtonElement[]>([])
  // If the list of users change, update the length of nodesRef
  useEffect(() => {
    nodesRef.current = nodesRef.current.slice(0, activeUsers.length)
  }, [activeUsers])

  const DropdownUser = ({
    user,
    idx,
  }: {
    user: UserRecord.AsObject
    idx: number
  }) => {
    const createOrUpdateRisks = useCreateOrUpdateRisks()
    const updateQuestionnaire = useUpdateQuestionnaire()
    const updateVendors = useUpdateVendors()
    const modelClient =
      useAuthorizedGrpcClientWithContentUpdate(ModelPromiseClient)

    const updateUser = async (e: React.MouseEvent): Promise<void> => {
      e.preventDefault()
      e.stopPropagation()
      setMenuIsToggled(false)
      const email = e.currentTarget.getAttribute('data-email') ?? ''

      // Handle updating various model types
      switch (modelType) {
        case MODEL_TYPE.RISK: {
          if (!model) {
            return
          }
          const risk: Risk = model as Risk
          risk.setOwnerEmail(email)
          try {
            await createOrUpdateRisks([risk])
          } catch (err) {
            log.error(`Error setting owner for Risk ID ${modelId}`, err)
          }
          break
        }
        case MODEL_TYPE.QUESTIONNAIRE: {
          if (!model) {
            return
          }
          const questionnaire = model as Questionnaire
          questionnaire.setAssignee(email) as Questionnaire
          try {
            await updateQuestionnaire(questionnaire)
          } catch (err) {
            log.error(
              `Error setting owner for AI GRC Q&A result ID ${modelId}`,
              err,
            )
          }
          break
        }
        case MODEL_TYPE.VENDOR: {
          if (!modelId) {
            return
          }
          const request = new UpdateVendorRequest()
            .setId(modelId)
            .setOwnerEmail(new StringValue().setValue(email))
          try {
            await updateVendors([request])
          } catch (err) {
            log.error(`Error setting owner for Vendor ID ${modelId}`, err)
          }
          break
        }
        case MODEL_TYPE.CONTROL:
        case MODEL_TYPE.POLICY: {
          // Controls/Policies
          const identifierMessage = new Identifier()
            .setModeltype(modelIdToType(modelId))
            .setModelid(modelId)
          const ownerMessage = new Owner()
            .setId(identifierMessage)
            .setOwneremail(email)
          try {
            await modelClient.setOwner(ownerMessage)
            onUpdate?.()
          } catch (err) {
            log.error(`Error setting owner for ModelId: ${modelId}`, err)
          }
          break
        }
        default:
          return
      }
    }

    return (
      <DropdownItem
        key={user.email}
        ref={(node) => {
          if (node && node !== nodesRef.current[idx]) {
            nodesRef.current[idx] = node
          }
        }}
        data-email={user.email}
        onKeyDown={keyDown.item(idx, nodesRef, setMenuIsToggled)}
        onClick={updateUser}
      >
        <DropdownItemUser>
          <Gravatar email={user.email} />
          <p>{user.name || user.email}</p>
        </DropdownItemUser>
        {user.email === email ? <DropdownSelectedIcon /> : <></>}
      </DropdownItem>
    )
  }
  const tooltipBody = email
    ? email
    : isDisabled || !hasPermission
    ? 'You do not have permission to change the owner'
    : 'Change Owner'

  return (
    <>
      <Tooltip
        id={`owner-assignment-tooltip-${modelId}`}
        show={showTooltip}
        onToggle={(nextShow) => {
          setShowTooltip(menuIsToggled ? false : nextShow)
        }}
        placement="top"
        tooltipBody={tooltipBody}
      >
        <OwnerAssignmentContainer ref={containerRef}>
          <IconButton
            disabled={isDisabled || !hasPermission}
            variant={IconButtonVariant.icon}
            onClick={(e) => {
              e.preventDefault()
              setShowTooltip(false)
              setMenuIsToggled((state) => !state)
            }}
            onKeyDown={keyDown.toggle(nodesRef, setMenuIsToggled)}
            {...spaceProps}
          >
            <Gravatar email={email} />
          </IconButton>
          <DropdownMenu
            aria-expanded={menuIsToggled}
            role="list"
            isToggled={menuIsToggled}
            right={0}
            width="fit-content"
            maxWidth="350px"
          >
            {activeUsers.map((user, idx) => (
              <DropdownUser
                key={`${modelId}-${user.id}`}
                user={user}
                idx={idx}
              />
            ))}
          </DropdownMenu>
        </OwnerAssignmentContainer>
      </Tooltip>
    </>
  )
}
