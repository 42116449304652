// source: source/source.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var auditbotapi_auditbotapi_pb = require('../auditbotapi/auditbotapi_pb.js');
goog.object.extend(proto, auditbotapi_auditbotapi_pb);
goog.exportSymbol('proto.source.DocumentSourceInfo', null, global);
goog.exportSymbol('proto.source.SOURCE_RESULT', null, global);
goog.exportSymbol('proto.source.SOURCE_TYPE', null, global);
goog.exportSymbol('proto.source.Source', null, global);
goog.exportSymbol('proto.source.TestTableInfo', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.source.Source = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.source.Source, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.source.Source.displayName = 'proto.source.Source';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.source.DocumentSourceInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.source.DocumentSourceInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.source.DocumentSourceInfo.displayName = 'proto.source.DocumentSourceInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.source.TestTableInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.source.TestTableInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.source.TestTableInfo.displayName = 'proto.source.TestTableInfo';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.source.Source.prototype.toObject = function(opt_includeInstance) {
  return proto.source.Source.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.source.Source} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.source.Source.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    summary: jspb.Message.getFieldWithDefault(msg, 4, ""),
    documentSourceInfo: (f = msg.getDocumentSourceInfo()) && proto.source.DocumentSourceInfo.toObject(includeInstance, f),
    testTable: (f = msg.getTestTable()) && proto.source.TestTableInfo.toObject(includeInstance, f),
    result: jspb.Message.getFieldWithDefault(msg, 7, 0),
    testTableExplanation: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.source.Source}
 */
proto.source.Source.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.source.Source;
  return proto.source.Source.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.source.Source} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.source.Source}
 */
proto.source.Source.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.source.SOURCE_TYPE} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSummary(value);
      break;
    case 5:
      var value = new proto.source.DocumentSourceInfo;
      reader.readMessage(value,proto.source.DocumentSourceInfo.deserializeBinaryFromReader);
      msg.setDocumentSourceInfo(value);
      break;
    case 6:
      var value = new proto.source.TestTableInfo;
      reader.readMessage(value,proto.source.TestTableInfo.deserializeBinaryFromReader);
      msg.setTestTable(value);
      break;
    case 7:
      var value = /** @type {!proto.source.SOURCE_RESULT} */ (reader.readEnum());
      msg.setResult(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setTestTableExplanation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.source.Source.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.source.Source.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.source.Source} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.source.Source.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSummary();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDocumentSourceInfo();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.source.DocumentSourceInfo.serializeBinaryToWriter
    );
  }
  f = message.getTestTable();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.source.TestTableInfo.serializeBinaryToWriter
    );
  }
  f = message.getResult();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getTestTableExplanation();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.source.Source.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.source.Source} returns this
 */
proto.source.Source.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional SOURCE_TYPE type = 2;
 * @return {!proto.source.SOURCE_TYPE}
 */
proto.source.Source.prototype.getType = function() {
  return /** @type {!proto.source.SOURCE_TYPE} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.source.SOURCE_TYPE} value
 * @return {!proto.source.Source} returns this
 */
proto.source.Source.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.source.Source.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.source.Source} returns this
 */
proto.source.Source.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string summary = 4;
 * @return {string}
 */
proto.source.Source.prototype.getSummary = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.source.Source} returns this
 */
proto.source.Source.prototype.setSummary = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional DocumentSourceInfo document_source_info = 5;
 * @return {?proto.source.DocumentSourceInfo}
 */
proto.source.Source.prototype.getDocumentSourceInfo = function() {
  return /** @type{?proto.source.DocumentSourceInfo} */ (
    jspb.Message.getWrapperField(this, proto.source.DocumentSourceInfo, 5));
};


/**
 * @param {?proto.source.DocumentSourceInfo|undefined} value
 * @return {!proto.source.Source} returns this
*/
proto.source.Source.prototype.setDocumentSourceInfo = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.source.Source} returns this
 */
proto.source.Source.prototype.clearDocumentSourceInfo = function() {
  return this.setDocumentSourceInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.source.Source.prototype.hasDocumentSourceInfo = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional TestTableInfo test_table = 6;
 * @return {?proto.source.TestTableInfo}
 */
proto.source.Source.prototype.getTestTable = function() {
  return /** @type{?proto.source.TestTableInfo} */ (
    jspb.Message.getWrapperField(this, proto.source.TestTableInfo, 6));
};


/**
 * @param {?proto.source.TestTableInfo|undefined} value
 * @return {!proto.source.Source} returns this
*/
proto.source.Source.prototype.setTestTable = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.source.Source} returns this
 */
proto.source.Source.prototype.clearTestTable = function() {
  return this.setTestTable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.source.Source.prototype.hasTestTable = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional SOURCE_RESULT result = 7;
 * @return {!proto.source.SOURCE_RESULT}
 */
proto.source.Source.prototype.getResult = function() {
  return /** @type {!proto.source.SOURCE_RESULT} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.source.SOURCE_RESULT} value
 * @return {!proto.source.Source} returns this
 */
proto.source.Source.prototype.setResult = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional string test_table_explanation = 8;
 * @return {string}
 */
proto.source.Source.prototype.getTestTableExplanation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.source.Source} returns this
 */
proto.source.Source.prototype.setTestTableExplanation = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.source.DocumentSourceInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.source.DocumentSourceInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.source.DocumentSourceInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.source.DocumentSourceInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    contentId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    discoveryId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    mime: jspb.Message.getFieldWithDefault(msg, 4, ""),
    isAutomated: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    relevantDate: (f = msg.getRelevantDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    chunkHash: jspb.Message.getFieldWithDefault(msg, 7, ""),
    isDeleted: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    documentId: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.source.DocumentSourceInfo}
 */
proto.source.DocumentSourceInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.source.DocumentSourceInfo;
  return proto.source.DocumentSourceInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.source.DocumentSourceInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.source.DocumentSourceInfo}
 */
proto.source.DocumentSourceInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setContentId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDiscoveryId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMime(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsAutomated(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setRelevantDate(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setChunkHash(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDeleted(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.source.DocumentSourceInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.source.DocumentSourceInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.source.DocumentSourceInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.source.DocumentSourceInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getContentId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDiscoveryId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMime();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getIsAutomated();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getRelevantDate();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getChunkHash();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getIsDeleted();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getDocumentId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.source.DocumentSourceInfo.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.source.DocumentSourceInfo} returns this
 */
proto.source.DocumentSourceInfo.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string content_id = 2;
 * @return {string}
 */
proto.source.DocumentSourceInfo.prototype.getContentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.source.DocumentSourceInfo} returns this
 */
proto.source.DocumentSourceInfo.prototype.setContentId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string discovery_id = 3;
 * @return {string}
 */
proto.source.DocumentSourceInfo.prototype.getDiscoveryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.source.DocumentSourceInfo} returns this
 */
proto.source.DocumentSourceInfo.prototype.setDiscoveryId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string mime = 4;
 * @return {string}
 */
proto.source.DocumentSourceInfo.prototype.getMime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.source.DocumentSourceInfo} returns this
 */
proto.source.DocumentSourceInfo.prototype.setMime = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool is_automated = 5;
 * @return {boolean}
 */
proto.source.DocumentSourceInfo.prototype.getIsAutomated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.source.DocumentSourceInfo} returns this
 */
proto.source.DocumentSourceInfo.prototype.setIsAutomated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional google.protobuf.Timestamp relevant_date = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.source.DocumentSourceInfo.prototype.getRelevantDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.source.DocumentSourceInfo} returns this
*/
proto.source.DocumentSourceInfo.prototype.setRelevantDate = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.source.DocumentSourceInfo} returns this
 */
proto.source.DocumentSourceInfo.prototype.clearRelevantDate = function() {
  return this.setRelevantDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.source.DocumentSourceInfo.prototype.hasRelevantDate = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string chunk_hash = 7;
 * @return {string}
 */
proto.source.DocumentSourceInfo.prototype.getChunkHash = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.source.DocumentSourceInfo} returns this
 */
proto.source.DocumentSourceInfo.prototype.setChunkHash = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional bool is_deleted = 8;
 * @return {boolean}
 */
proto.source.DocumentSourceInfo.prototype.getIsDeleted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.source.DocumentSourceInfo} returns this
 */
proto.source.DocumentSourceInfo.prototype.setIsDeleted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional string document_id = 9;
 * @return {string}
 */
proto.source.DocumentSourceInfo.prototype.getDocumentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.source.DocumentSourceInfo} returns this
 */
proto.source.DocumentSourceInfo.prototype.setDocumentId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.source.TestTableInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.source.TestTableInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.source.TestTableInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.source.TestTableInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    test: jspb.Message.getFieldWithDefault(msg, 2, ""),
    contentId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    threshold: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    operator: jspb.Message.getFieldWithDefault(msg, 6, 0),
    mode: jspb.Message.getFieldWithDefault(msg, 7, 0),
    count: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    result: jspb.Message.getFieldWithDefault(msg, 9, 0),
    summary: jspb.Message.getFieldWithDefault(msg, 10, ""),
    highlightCondition: jspb.Message.getFieldWithDefault(msg, 11, 0),
    explanation: jspb.Message.getFieldWithDefault(msg, 12, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.source.TestTableInfo}
 */
proto.source.TestTableInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.source.TestTableInfo;
  return proto.source.TestTableInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.source.TestTableInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.source.TestTableInfo}
 */
proto.source.TestTableInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTest(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setContentId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setThreshold(value);
      break;
    case 6:
      var value = /** @type {!proto.auditbotapi.ThresholdOperator} */ (reader.readEnum());
      msg.setOperator(value);
      break;
    case 7:
      var value = /** @type {!proto.auditbotapi.EnforcementMode} */ (reader.readEnum());
      msg.setMode(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setCount(value);
      break;
    case 9:
      var value = /** @type {!proto.source.SOURCE_RESULT} */ (reader.readEnum());
      msg.setResult(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setSummary(value);
      break;
    case 11:
      var value = /** @type {!proto.auditbotapi.HighlightCondition} */ (reader.readEnum());
      msg.setHighlightCondition(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setExplanation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.source.TestTableInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.source.TestTableInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.source.TestTableInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.source.TestTableInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTest();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getContentId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getThreshold();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getOperator();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getMode();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getCount();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getResult();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getSummary();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getHighlightCondition();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
  f = message.getExplanation();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.source.TestTableInfo.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.source.TestTableInfo} returns this
 */
proto.source.TestTableInfo.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string test = 2;
 * @return {string}
 */
proto.source.TestTableInfo.prototype.getTest = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.source.TestTableInfo} returns this
 */
proto.source.TestTableInfo.prototype.setTest = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string content_id = 3;
 * @return {string}
 */
proto.source.TestTableInfo.prototype.getContentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.source.TestTableInfo} returns this
 */
proto.source.TestTableInfo.prototype.setContentId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional float threshold = 5;
 * @return {number}
 */
proto.source.TestTableInfo.prototype.getThreshold = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.source.TestTableInfo} returns this
 */
proto.source.TestTableInfo.prototype.setThreshold = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional auditbotapi.ThresholdOperator operator = 6;
 * @return {!proto.auditbotapi.ThresholdOperator}
 */
proto.source.TestTableInfo.prototype.getOperator = function() {
  return /** @type {!proto.auditbotapi.ThresholdOperator} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.auditbotapi.ThresholdOperator} value
 * @return {!proto.source.TestTableInfo} returns this
 */
proto.source.TestTableInfo.prototype.setOperator = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional auditbotapi.EnforcementMode mode = 7;
 * @return {!proto.auditbotapi.EnforcementMode}
 */
proto.source.TestTableInfo.prototype.getMode = function() {
  return /** @type {!proto.auditbotapi.EnforcementMode} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.auditbotapi.EnforcementMode} value
 * @return {!proto.source.TestTableInfo} returns this
 */
proto.source.TestTableInfo.prototype.setMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional float count = 8;
 * @return {number}
 */
proto.source.TestTableInfo.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.source.TestTableInfo} returns this
 */
proto.source.TestTableInfo.prototype.setCount = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional SOURCE_RESULT result = 9;
 * @return {!proto.source.SOURCE_RESULT}
 */
proto.source.TestTableInfo.prototype.getResult = function() {
  return /** @type {!proto.source.SOURCE_RESULT} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.source.SOURCE_RESULT} value
 * @return {!proto.source.TestTableInfo} returns this
 */
proto.source.TestTableInfo.prototype.setResult = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional string summary = 10;
 * @return {string}
 */
proto.source.TestTableInfo.prototype.getSummary = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.source.TestTableInfo} returns this
 */
proto.source.TestTableInfo.prototype.setSummary = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional auditbotapi.HighlightCondition highlight_condition = 11;
 * @return {!proto.auditbotapi.HighlightCondition}
 */
proto.source.TestTableInfo.prototype.getHighlightCondition = function() {
  return /** @type {!proto.auditbotapi.HighlightCondition} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.auditbotapi.HighlightCondition} value
 * @return {!proto.source.TestTableInfo} returns this
 */
proto.source.TestTableInfo.prototype.setHighlightCondition = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};


/**
 * optional string explanation = 12;
 * @return {string}
 */
proto.source.TestTableInfo.prototype.getExplanation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.source.TestTableInfo} returns this
 */
proto.source.TestTableInfo.prototype.setExplanation = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * @enum {number}
 */
proto.source.SOURCE_RESULT = {
  PASS: 0,
  FAIL: 1
};

/**
 * @enum {number}
 */
proto.source.SOURCE_TYPE = {
  UNKNOWN_SOURCE: 0,
  POLICY_DOC_SOURCE: 1,
  EVIDENCE_SOURCE: 2,
  KNOWLEDGE_BASE_SOURCE: 3
};

goog.object.extend(exports, proto.source);
