import React, { useContext, useState } from 'react'
import { CognitoAuthGrant } from 'src/Utils/oidc'

type CognitoContextType = {
  initialCognitoAuth: CognitoAuthGrant | null
  setInitialCognitoAuth: React.Dispatch<
    React.SetStateAction<CognitoAuthGrant | null>
  >
}

const CognitoContext = React.createContext<CognitoContextType>({
  initialCognitoAuth: null,
  setInitialCognitoAuth: () => null,
})

export const useCognitoContext = (): CognitoContextType =>
  useContext(CognitoContext)

export const CognitoContextProvider = ({
  children,
}: {
  children: JSX.Element
}): JSX.Element => {
  const [initialCognitoAuth, setInitialCognitoAuth] =
    useState<CognitoAuthGrant | null>(null)

  return (
    <CognitoContext.Provider
      value={{
        initialCognitoAuth,
        setInitialCognitoAuth,
      }}
    >
      {children}
    </CognitoContext.Provider>
  )
}
