import React from 'react'
import Modal, { ModalProps } from 'react-bootstrap/Modal'
import { StyledModalDialog, StyledModalSheet } from './ModalForm.styles'
import './ModalForm.styles.css'

export type SheetOrientation = 'left' | 'right'

export type SheetProps = {
  sheetOrientation?: SheetOrientation
  sheetWidth?: string
}

export type CustomModalProps = {
  fullHeight?: boolean
}

export type ModalContainerProps = ModalProps & SheetProps & CustomModalProps

export const ModalContainer = ({
  children,
  enforceFocus,
  sheetOrientation,
  sheetWidth,
  fullHeight,
  ...props
}: ModalContainerProps): JSX.Element => {
  if (sheetOrientation) {
    return (
      <Modal
        {...props}
        enforceFocus={enforceFocus}
        dialogAs={StyledModalSheet}
        $sheetOrientation={sheetOrientation}
        $sheetWidth={sheetWidth}
        $fullHeight={fullHeight}
        className={`modal-sheet-${sheetOrientation}-${
          props.show ? 'enter' : 'exit'
        }`}
      >
        {children}
      </Modal>
    )
  }

  return (
    <Modal
      {...props}
      enforceFocus={enforceFocus}
      dialogAs={StyledModalDialog}
      show={props.show}
      $fullHeight={fullHeight}
    >
      {children}
    </Modal>
  )
}
