import React, { ReactElement } from 'react'
import {
  Tooltip,
  TooltipOverlayType,
  TooltipPositions,
} from '../Reusable/Tooltip/Tooltip'
import { HeaderBanner } from '../Reusable/Banners/HeaderBanner'
import { InfoButton } from './InfoButton'
import {
  HeaderText,
  HeaderTitle,
  MainHeaderContent,
  PageHeaderStyle,
} from './PageLayout.styles'

type Props = {
  title: string | JSX.Element
  infoTooltipBody?: string
  subHeader?: ReactElement
  customEl?: JSX.Element
  showBottomBorder?: boolean
  nowrap?: boolean
}

export const PageHeader = ({
  title,
  infoTooltipBody,
  subHeader,
  customEl,
  showBottomBorder,
  nowrap,
}: Props): ReactElement => {
  const content = (
    <>
      <HeaderText $nowrap={nowrap}>
        <HeaderTitle>{title}</HeaderTitle>
        {infoTooltipBody && (
          <Tooltip
            id="page-header-info-tooltip"
            placement={TooltipPositions.right}
            overlayType={TooltipOverlayType.popover}
            tooltipBody={infoTooltipBody}
          >
            <InfoButton as="div" alignSelf="center" />
          </Tooltip>
        )}
      </HeaderText>
      {customEl}
    </>
  )

  return (
    <>
      <PageHeaderStyle showBottomBorder={showBottomBorder}>
        <HeaderBanner />
        <MainHeaderContent>{content}</MainHeaderContent>
        {subHeader}
      </PageHeaderStyle>
    </>
  )
}
