// source: auditbotapi/auditbotapi.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.auditbotapi.AuditRequest', null, global);
goog.exportSymbol('proto.auditbotapi.AuditResponse', null, global);
goog.exportSymbol('proto.auditbotapi.AuditResults', null, global);
goog.exportSymbol('proto.auditbotapi.Completion', null, global);
goog.exportSymbol('proto.auditbotapi.ControlMetadata', null, global);
goog.exportSymbol('proto.auditbotapi.EnforcementMode', null, global);
goog.exportSymbol('proto.auditbotapi.HighlightCondition', null, global);
goog.exportSymbol('proto.auditbotapi.ResultDetails', null, global);
goog.exportSymbol('proto.auditbotapi.Sources', null, global);
goog.exportSymbol('proto.auditbotapi.TestTable', null, global);
goog.exportSymbol('proto.auditbotapi.TestTableThresholds', null, global);
goog.exportSymbol('proto.auditbotapi.ThresholdOperator', null, global);
goog.exportSymbol('proto.auditbotapi.TokenDetails', null, global);
goog.exportSymbol('proto.auditbotapi.Tokens', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.auditbotapi.AuditRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.auditbotapi.AuditRequest.repeatedFields_, null);
};
goog.inherits(proto.auditbotapi.AuditRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.auditbotapi.AuditRequest.displayName = 'proto.auditbotapi.AuditRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.auditbotapi.AuditResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.auditbotapi.AuditResponse.repeatedFields_, null);
};
goog.inherits(proto.auditbotapi.AuditResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.auditbotapi.AuditResponse.displayName = 'proto.auditbotapi.AuditResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.auditbotapi.TestTableThresholds = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.auditbotapi.TestTableThresholds, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.auditbotapi.TestTableThresholds.displayName = 'proto.auditbotapi.TestTableThresholds';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.auditbotapi.TestTable = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.auditbotapi.TestTable.repeatedFields_, null);
};
goog.inherits(proto.auditbotapi.TestTable, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.auditbotapi.TestTable.displayName = 'proto.auditbotapi.TestTable';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.auditbotapi.AuditResults = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.auditbotapi.AuditResults.repeatedFields_, null);
};
goog.inherits(proto.auditbotapi.AuditResults, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.auditbotapi.AuditResults.displayName = 'proto.auditbotapi.AuditResults';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.auditbotapi.ResultDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.auditbotapi.ResultDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.auditbotapi.ResultDetails.displayName = 'proto.auditbotapi.ResultDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.auditbotapi.Tokens = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.auditbotapi.Tokens, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.auditbotapi.Tokens.displayName = 'proto.auditbotapi.Tokens';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.auditbotapi.TokenDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.auditbotapi.TokenDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.auditbotapi.TokenDetails.displayName = 'proto.auditbotapi.TokenDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.auditbotapi.Sources = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.auditbotapi.Sources, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.auditbotapi.Sources.displayName = 'proto.auditbotapi.Sources';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.auditbotapi.Completion = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.auditbotapi.Completion, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.auditbotapi.Completion.displayName = 'proto.auditbotapi.Completion';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.auditbotapi.ControlMetadata = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.auditbotapi.ControlMetadata.repeatedFields_, null);
};
goog.inherits(proto.auditbotapi.ControlMetadata, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.auditbotapi.ControlMetadata.displayName = 'proto.auditbotapi.ControlMetadata';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.auditbotapi.AuditRequest.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.auditbotapi.AuditRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.auditbotapi.AuditRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.auditbotapi.AuditRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.auditbotapi.AuditRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    controlId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    auditId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    startTime: jspb.Message.getFieldWithDefault(msg, 4, ""),
    endTime: jspb.Message.getFieldWithDefault(msg, 5, ""),
    validateImageTimestamp: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    auditControlTestsList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.auditbotapi.AuditRequest}
 */
proto.auditbotapi.AuditRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.auditbotapi.AuditRequest;
  return proto.auditbotapi.AuditRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.auditbotapi.AuditRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.auditbotapi.AuditRequest}
 */
proto.auditbotapi.AuditRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setControlId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuditId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartTime(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndTime(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setValidateImageTimestamp(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.addAuditControlTests(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.auditbotapi.AuditRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.auditbotapi.AuditRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.auditbotapi.AuditRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.auditbotapi.AuditRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getControlId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAuditId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStartTime();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEndTime();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getValidateImageTimestamp();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getAuditControlTestsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      7,
      f
    );
  }
};


/**
 * optional string account_id = 1;
 * @return {string}
 */
proto.auditbotapi.AuditRequest.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.auditbotapi.AuditRequest} returns this
 */
proto.auditbotapi.AuditRequest.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string control_id = 2;
 * @return {string}
 */
proto.auditbotapi.AuditRequest.prototype.getControlId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.auditbotapi.AuditRequest} returns this
 */
proto.auditbotapi.AuditRequest.prototype.setControlId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string audit_id = 3;
 * @return {string}
 */
proto.auditbotapi.AuditRequest.prototype.getAuditId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.auditbotapi.AuditRequest} returns this
 */
proto.auditbotapi.AuditRequest.prototype.setAuditId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string start_time = 4;
 * @return {string}
 */
proto.auditbotapi.AuditRequest.prototype.getStartTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.auditbotapi.AuditRequest} returns this
 */
proto.auditbotapi.AuditRequest.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string end_time = 5;
 * @return {string}
 */
proto.auditbotapi.AuditRequest.prototype.getEndTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.auditbotapi.AuditRequest} returns this
 */
proto.auditbotapi.AuditRequest.prototype.setEndTime = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional bool validate_image_timestamp = 6;
 * @return {boolean}
 */
proto.auditbotapi.AuditRequest.prototype.getValidateImageTimestamp = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.auditbotapi.AuditRequest} returns this
 */
proto.auditbotapi.AuditRequest.prototype.setValidateImageTimestamp = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * repeated string audit_control_tests = 7;
 * @return {!Array<string>}
 */
proto.auditbotapi.AuditRequest.prototype.getAuditControlTestsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.auditbotapi.AuditRequest} returns this
 */
proto.auditbotapi.AuditRequest.prototype.setAuditControlTestsList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.auditbotapi.AuditRequest} returns this
 */
proto.auditbotapi.AuditRequest.prototype.addAuditControlTests = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.auditbotapi.AuditRequest} returns this
 */
proto.auditbotapi.AuditRequest.prototype.clearAuditControlTestsList = function() {
  return this.setAuditControlTestsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.auditbotapi.AuditResponse.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.auditbotapi.AuditResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.auditbotapi.AuditResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.auditbotapi.AuditResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.auditbotapi.AuditResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    controlId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    auditId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    startTime: jspb.Message.getFieldWithDefault(msg, 3, ""),
    endTime: jspb.Message.getFieldWithDefault(msg, 4, ""),
    resultsList: jspb.Message.toObjectList(msg.getResultsList(),
    proto.auditbotapi.AuditResults.toObject, includeInstance),
    metadata: (f = msg.getMetadata()) && proto.auditbotapi.ControlMetadata.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.auditbotapi.AuditResponse}
 */
proto.auditbotapi.AuditResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.auditbotapi.AuditResponse;
  return proto.auditbotapi.AuditResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.auditbotapi.AuditResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.auditbotapi.AuditResponse}
 */
proto.auditbotapi.AuditResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setControlId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuditId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartTime(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndTime(value);
      break;
    case 5:
      var value = new proto.auditbotapi.AuditResults;
      reader.readMessage(value,proto.auditbotapi.AuditResults.deserializeBinaryFromReader);
      msg.addResults(value);
      break;
    case 6:
      var value = new proto.auditbotapi.ControlMetadata;
      reader.readMessage(value,proto.auditbotapi.ControlMetadata.deserializeBinaryFromReader);
      msg.setMetadata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.auditbotapi.AuditResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.auditbotapi.AuditResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.auditbotapi.AuditResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.auditbotapi.AuditResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getControlId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAuditId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStartTime();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEndTime();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getResultsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.auditbotapi.AuditResults.serializeBinaryToWriter
    );
  }
  f = message.getMetadata();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.auditbotapi.ControlMetadata.serializeBinaryToWriter
    );
  }
};


/**
 * optional string control_id = 1;
 * @return {string}
 */
proto.auditbotapi.AuditResponse.prototype.getControlId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.auditbotapi.AuditResponse} returns this
 */
proto.auditbotapi.AuditResponse.prototype.setControlId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string audit_id = 2;
 * @return {string}
 */
proto.auditbotapi.AuditResponse.prototype.getAuditId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.auditbotapi.AuditResponse} returns this
 */
proto.auditbotapi.AuditResponse.prototype.setAuditId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string start_time = 3;
 * @return {string}
 */
proto.auditbotapi.AuditResponse.prototype.getStartTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.auditbotapi.AuditResponse} returns this
 */
proto.auditbotapi.AuditResponse.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string end_time = 4;
 * @return {string}
 */
proto.auditbotapi.AuditResponse.prototype.getEndTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.auditbotapi.AuditResponse} returns this
 */
proto.auditbotapi.AuditResponse.prototype.setEndTime = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated AuditResults results = 5;
 * @return {!Array<!proto.auditbotapi.AuditResults>}
 */
proto.auditbotapi.AuditResponse.prototype.getResultsList = function() {
  return /** @type{!Array<!proto.auditbotapi.AuditResults>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.auditbotapi.AuditResults, 5));
};


/**
 * @param {!Array<!proto.auditbotapi.AuditResults>} value
 * @return {!proto.auditbotapi.AuditResponse} returns this
*/
proto.auditbotapi.AuditResponse.prototype.setResultsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.auditbotapi.AuditResults=} opt_value
 * @param {number=} opt_index
 * @return {!proto.auditbotapi.AuditResults}
 */
proto.auditbotapi.AuditResponse.prototype.addResults = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.auditbotapi.AuditResults, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.auditbotapi.AuditResponse} returns this
 */
proto.auditbotapi.AuditResponse.prototype.clearResultsList = function() {
  return this.setResultsList([]);
};


/**
 * optional ControlMetadata metadata = 6;
 * @return {?proto.auditbotapi.ControlMetadata}
 */
proto.auditbotapi.AuditResponse.prototype.getMetadata = function() {
  return /** @type{?proto.auditbotapi.ControlMetadata} */ (
    jspb.Message.getWrapperField(this, proto.auditbotapi.ControlMetadata, 6));
};


/**
 * @param {?proto.auditbotapi.ControlMetadata|undefined} value
 * @return {!proto.auditbotapi.AuditResponse} returns this
*/
proto.auditbotapi.AuditResponse.prototype.setMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.auditbotapi.AuditResponse} returns this
 */
proto.auditbotapi.AuditResponse.prototype.clearMetadata = function() {
  return this.setMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.auditbotapi.AuditResponse.prototype.hasMetadata = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.auditbotapi.TestTableThresholds.prototype.toObject = function(opt_includeInstance) {
  return proto.auditbotapi.TestTableThresholds.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.auditbotapi.TestTableThresholds} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.auditbotapi.TestTableThresholds.toObject = function(includeInstance, msg) {
  var f, obj = {
    threshold: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    operator: jspb.Message.getFieldWithDefault(msg, 2, 0),
    mode: jspb.Message.getFieldWithDefault(msg, 3, 0),
    highlight: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.auditbotapi.TestTableThresholds}
 */
proto.auditbotapi.TestTableThresholds.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.auditbotapi.TestTableThresholds;
  return proto.auditbotapi.TestTableThresholds.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.auditbotapi.TestTableThresholds} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.auditbotapi.TestTableThresholds}
 */
proto.auditbotapi.TestTableThresholds.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setThreshold(value);
      break;
    case 2:
      var value = /** @type {!proto.auditbotapi.ThresholdOperator} */ (reader.readEnum());
      msg.setOperator(value);
      break;
    case 3:
      var value = /** @type {!proto.auditbotapi.EnforcementMode} */ (reader.readEnum());
      msg.setMode(value);
      break;
    case 4:
      var value = /** @type {!proto.auditbotapi.HighlightCondition} */ (reader.readEnum());
      msg.setHighlight(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.auditbotapi.TestTableThresholds.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.auditbotapi.TestTableThresholds.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.auditbotapi.TestTableThresholds} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.auditbotapi.TestTableThresholds.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getThreshold();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getOperator();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getMode();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getHighlight();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional float threshold = 1;
 * @return {number}
 */
proto.auditbotapi.TestTableThresholds.prototype.getThreshold = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.auditbotapi.TestTableThresholds} returns this
 */
proto.auditbotapi.TestTableThresholds.prototype.setThreshold = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional ThresholdOperator operator = 2;
 * @return {!proto.auditbotapi.ThresholdOperator}
 */
proto.auditbotapi.TestTableThresholds.prototype.getOperator = function() {
  return /** @type {!proto.auditbotapi.ThresholdOperator} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.auditbotapi.ThresholdOperator} value
 * @return {!proto.auditbotapi.TestTableThresholds} returns this
 */
proto.auditbotapi.TestTableThresholds.prototype.setOperator = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional EnforcementMode mode = 3;
 * @return {!proto.auditbotapi.EnforcementMode}
 */
proto.auditbotapi.TestTableThresholds.prototype.getMode = function() {
  return /** @type {!proto.auditbotapi.EnforcementMode} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.auditbotapi.EnforcementMode} value
 * @return {!proto.auditbotapi.TestTableThresholds} returns this
 */
proto.auditbotapi.TestTableThresholds.prototype.setMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional HighlightCondition highlight = 4;
 * @return {!proto.auditbotapi.HighlightCondition}
 */
proto.auditbotapi.TestTableThresholds.prototype.getHighlight = function() {
  return /** @type {!proto.auditbotapi.HighlightCondition} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.auditbotapi.HighlightCondition} value
 * @return {!proto.auditbotapi.TestTableThresholds} returns this
 */
proto.auditbotapi.TestTableThresholds.prototype.setHighlight = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.auditbotapi.TestTable.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.auditbotapi.TestTable.prototype.toObject = function(opt_includeInstance) {
  return proto.auditbotapi.TestTable.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.auditbotapi.TestTable} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.auditbotapi.TestTable.toObject = function(includeInstance, msg) {
  var f, obj = {
    test: jspb.Message.getFieldWithDefault(msg, 1, ""),
    caption: jspb.Message.getFieldWithDefault(msg, 2, ""),
    contentId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    table: jspb.Message.getFieldWithDefault(msg, 4, ""),
    queriesList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    explanation: jspb.Message.getFieldWithDefault(msg, 6, ""),
    passed: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    thresholds: (f = msg.getThresholds()) && proto.auditbotapi.TestTableThresholds.toObject(includeInstance, f),
    count: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    summary: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.auditbotapi.TestTable}
 */
proto.auditbotapi.TestTable.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.auditbotapi.TestTable;
  return proto.auditbotapi.TestTable.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.auditbotapi.TestTable} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.auditbotapi.TestTable}
 */
proto.auditbotapi.TestTable.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTest(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCaption(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setContentId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTable(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addQueries(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setExplanation(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPassed(value);
      break;
    case 8:
      var value = new proto.auditbotapi.TestTableThresholds;
      reader.readMessage(value,proto.auditbotapi.TestTableThresholds.deserializeBinaryFromReader);
      msg.setThresholds(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setCount(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setSummary(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.auditbotapi.TestTable.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.auditbotapi.TestTable.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.auditbotapi.TestTable} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.auditbotapi.TestTable.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTest();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCaption();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getContentId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTable();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getQueriesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getExplanation();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPassed();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getThresholds();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.auditbotapi.TestTableThresholds.serializeBinaryToWriter
    );
  }
  f = message.getCount();
  if (f !== 0.0) {
    writer.writeFloat(
      9,
      f
    );
  }
  f = message.getSummary();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional string test = 1;
 * @return {string}
 */
proto.auditbotapi.TestTable.prototype.getTest = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.auditbotapi.TestTable} returns this
 */
proto.auditbotapi.TestTable.prototype.setTest = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string caption = 2;
 * @return {string}
 */
proto.auditbotapi.TestTable.prototype.getCaption = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.auditbotapi.TestTable} returns this
 */
proto.auditbotapi.TestTable.prototype.setCaption = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string content_id = 3;
 * @return {string}
 */
proto.auditbotapi.TestTable.prototype.getContentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.auditbotapi.TestTable} returns this
 */
proto.auditbotapi.TestTable.prototype.setContentId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string table = 4;
 * @return {string}
 */
proto.auditbotapi.TestTable.prototype.getTable = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.auditbotapi.TestTable} returns this
 */
proto.auditbotapi.TestTable.prototype.setTable = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated string queries = 5;
 * @return {!Array<string>}
 */
proto.auditbotapi.TestTable.prototype.getQueriesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.auditbotapi.TestTable} returns this
 */
proto.auditbotapi.TestTable.prototype.setQueriesList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.auditbotapi.TestTable} returns this
 */
proto.auditbotapi.TestTable.prototype.addQueries = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.auditbotapi.TestTable} returns this
 */
proto.auditbotapi.TestTable.prototype.clearQueriesList = function() {
  return this.setQueriesList([]);
};


/**
 * optional string explanation = 6;
 * @return {string}
 */
proto.auditbotapi.TestTable.prototype.getExplanation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.auditbotapi.TestTable} returns this
 */
proto.auditbotapi.TestTable.prototype.setExplanation = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional bool passed = 7;
 * @return {boolean}
 */
proto.auditbotapi.TestTable.prototype.getPassed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.auditbotapi.TestTable} returns this
 */
proto.auditbotapi.TestTable.prototype.setPassed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional TestTableThresholds thresholds = 8;
 * @return {?proto.auditbotapi.TestTableThresholds}
 */
proto.auditbotapi.TestTable.prototype.getThresholds = function() {
  return /** @type{?proto.auditbotapi.TestTableThresholds} */ (
    jspb.Message.getWrapperField(this, proto.auditbotapi.TestTableThresholds, 8));
};


/**
 * @param {?proto.auditbotapi.TestTableThresholds|undefined} value
 * @return {!proto.auditbotapi.TestTable} returns this
*/
proto.auditbotapi.TestTable.prototype.setThresholds = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.auditbotapi.TestTable} returns this
 */
proto.auditbotapi.TestTable.prototype.clearThresholds = function() {
  return this.setThresholds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.auditbotapi.TestTable.prototype.hasThresholds = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional float count = 9;
 * @return {number}
 */
proto.auditbotapi.TestTable.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.auditbotapi.TestTable} returns this
 */
proto.auditbotapi.TestTable.prototype.setCount = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional string summary = 10;
 * @return {string}
 */
proto.auditbotapi.TestTable.prototype.getSummary = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.auditbotapi.TestTable} returns this
 */
proto.auditbotapi.TestTable.prototype.setSummary = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.auditbotapi.AuditResults.repeatedFields_ = [2,5,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.auditbotapi.AuditResults.prototype.toObject = function(opt_includeInstance) {
  return proto.auditbotapi.AuditResults.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.auditbotapi.AuditResults} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.auditbotapi.AuditResults.toObject = function(includeInstance, msg) {
  var f, obj = {
    test: jspb.Message.getFieldWithDefault(msg, 1, ""),
    resultsList: jspb.Message.toObjectList(msg.getResultsList(),
    proto.auditbotapi.ResultDetails.toObject, includeInstance),
    tokens: (f = msg.getTokens()) && proto.auditbotapi.Tokens.toObject(includeInstance, f),
    sources: (f = msg.getSources()) && proto.auditbotapi.Sources.toObject(includeInstance, f),
    completionsList: jspb.Message.toObjectList(msg.getCompletionsList(),
    proto.auditbotapi.Completion.toObject, includeInstance),
    sourceDetails: jspb.Message.getFieldWithDefault(msg, 6, ""),
    testTablesList: jspb.Message.toObjectList(msg.getTestTablesList(),
    proto.auditbotapi.TestTable.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.auditbotapi.AuditResults}
 */
proto.auditbotapi.AuditResults.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.auditbotapi.AuditResults;
  return proto.auditbotapi.AuditResults.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.auditbotapi.AuditResults} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.auditbotapi.AuditResults}
 */
proto.auditbotapi.AuditResults.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTest(value);
      break;
    case 2:
      var value = new proto.auditbotapi.ResultDetails;
      reader.readMessage(value,proto.auditbotapi.ResultDetails.deserializeBinaryFromReader);
      msg.addResults(value);
      break;
    case 3:
      var value = new proto.auditbotapi.Tokens;
      reader.readMessage(value,proto.auditbotapi.Tokens.deserializeBinaryFromReader);
      msg.setTokens(value);
      break;
    case 4:
      var value = new proto.auditbotapi.Sources;
      reader.readMessage(value,proto.auditbotapi.Sources.deserializeBinaryFromReader);
      msg.setSources(value);
      break;
    case 5:
      var value = new proto.auditbotapi.Completion;
      reader.readMessage(value,proto.auditbotapi.Completion.deserializeBinaryFromReader);
      msg.addCompletions(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSourceDetails(value);
      break;
    case 7:
      var value = new proto.auditbotapi.TestTable;
      reader.readMessage(value,proto.auditbotapi.TestTable.deserializeBinaryFromReader);
      msg.addTestTables(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.auditbotapi.AuditResults.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.auditbotapi.AuditResults.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.auditbotapi.AuditResults} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.auditbotapi.AuditResults.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTest();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getResultsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.auditbotapi.ResultDetails.serializeBinaryToWriter
    );
  }
  f = message.getTokens();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.auditbotapi.Tokens.serializeBinaryToWriter
    );
  }
  f = message.getSources();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.auditbotapi.Sources.serializeBinaryToWriter
    );
  }
  f = message.getCompletionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.auditbotapi.Completion.serializeBinaryToWriter
    );
  }
  f = message.getSourceDetails();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getTestTablesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.auditbotapi.TestTable.serializeBinaryToWriter
    );
  }
};


/**
 * optional string test = 1;
 * @return {string}
 */
proto.auditbotapi.AuditResults.prototype.getTest = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.auditbotapi.AuditResults} returns this
 */
proto.auditbotapi.AuditResults.prototype.setTest = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated ResultDetails results = 2;
 * @return {!Array<!proto.auditbotapi.ResultDetails>}
 */
proto.auditbotapi.AuditResults.prototype.getResultsList = function() {
  return /** @type{!Array<!proto.auditbotapi.ResultDetails>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.auditbotapi.ResultDetails, 2));
};


/**
 * @param {!Array<!proto.auditbotapi.ResultDetails>} value
 * @return {!proto.auditbotapi.AuditResults} returns this
*/
proto.auditbotapi.AuditResults.prototype.setResultsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.auditbotapi.ResultDetails=} opt_value
 * @param {number=} opt_index
 * @return {!proto.auditbotapi.ResultDetails}
 */
proto.auditbotapi.AuditResults.prototype.addResults = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.auditbotapi.ResultDetails, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.auditbotapi.AuditResults} returns this
 */
proto.auditbotapi.AuditResults.prototype.clearResultsList = function() {
  return this.setResultsList([]);
};


/**
 * optional Tokens tokens = 3;
 * @return {?proto.auditbotapi.Tokens}
 */
proto.auditbotapi.AuditResults.prototype.getTokens = function() {
  return /** @type{?proto.auditbotapi.Tokens} */ (
    jspb.Message.getWrapperField(this, proto.auditbotapi.Tokens, 3));
};


/**
 * @param {?proto.auditbotapi.Tokens|undefined} value
 * @return {!proto.auditbotapi.AuditResults} returns this
*/
proto.auditbotapi.AuditResults.prototype.setTokens = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.auditbotapi.AuditResults} returns this
 */
proto.auditbotapi.AuditResults.prototype.clearTokens = function() {
  return this.setTokens(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.auditbotapi.AuditResults.prototype.hasTokens = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Sources sources = 4;
 * @return {?proto.auditbotapi.Sources}
 */
proto.auditbotapi.AuditResults.prototype.getSources = function() {
  return /** @type{?proto.auditbotapi.Sources} */ (
    jspb.Message.getWrapperField(this, proto.auditbotapi.Sources, 4));
};


/**
 * @param {?proto.auditbotapi.Sources|undefined} value
 * @return {!proto.auditbotapi.AuditResults} returns this
*/
proto.auditbotapi.AuditResults.prototype.setSources = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.auditbotapi.AuditResults} returns this
 */
proto.auditbotapi.AuditResults.prototype.clearSources = function() {
  return this.setSources(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.auditbotapi.AuditResults.prototype.hasSources = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated Completion completions = 5;
 * @return {!Array<!proto.auditbotapi.Completion>}
 */
proto.auditbotapi.AuditResults.prototype.getCompletionsList = function() {
  return /** @type{!Array<!proto.auditbotapi.Completion>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.auditbotapi.Completion, 5));
};


/**
 * @param {!Array<!proto.auditbotapi.Completion>} value
 * @return {!proto.auditbotapi.AuditResults} returns this
*/
proto.auditbotapi.AuditResults.prototype.setCompletionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.auditbotapi.Completion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.auditbotapi.Completion}
 */
proto.auditbotapi.AuditResults.prototype.addCompletions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.auditbotapi.Completion, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.auditbotapi.AuditResults} returns this
 */
proto.auditbotapi.AuditResults.prototype.clearCompletionsList = function() {
  return this.setCompletionsList([]);
};


/**
 * optional string source_details = 6;
 * @return {string}
 */
proto.auditbotapi.AuditResults.prototype.getSourceDetails = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.auditbotapi.AuditResults} returns this
 */
proto.auditbotapi.AuditResults.prototype.setSourceDetails = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated TestTable test_tables = 7;
 * @return {!Array<!proto.auditbotapi.TestTable>}
 */
proto.auditbotapi.AuditResults.prototype.getTestTablesList = function() {
  return /** @type{!Array<!proto.auditbotapi.TestTable>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.auditbotapi.TestTable, 7));
};


/**
 * @param {!Array<!proto.auditbotapi.TestTable>} value
 * @return {!proto.auditbotapi.AuditResults} returns this
*/
proto.auditbotapi.AuditResults.prototype.setTestTablesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.auditbotapi.TestTable=} opt_value
 * @param {number=} opt_index
 * @return {!proto.auditbotapi.TestTable}
 */
proto.auditbotapi.AuditResults.prototype.addTestTables = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.auditbotapi.TestTable, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.auditbotapi.AuditResults} returns this
 */
proto.auditbotapi.AuditResults.prototype.clearTestTablesList = function() {
  return this.setTestTablesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.auditbotapi.ResultDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.auditbotapi.ResultDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.auditbotapi.ResultDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.auditbotapi.ResultDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    satisfied: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    reason: jspb.Message.getFieldWithDefault(msg, 2, ""),
    error: jspb.Message.getFieldWithDefault(msg, 3, ""),
    sampleDate: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.auditbotapi.ResultDetails}
 */
proto.auditbotapi.ResultDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.auditbotapi.ResultDetails;
  return proto.auditbotapi.ResultDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.auditbotapi.ResultDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.auditbotapi.ResultDetails}
 */
proto.auditbotapi.ResultDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSatisfied(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setReason(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSampleDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.auditbotapi.ResultDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.auditbotapi.ResultDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.auditbotapi.ResultDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.auditbotapi.ResultDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSatisfied();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getReason();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSampleDate();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional bool satisfied = 1;
 * @return {boolean}
 */
proto.auditbotapi.ResultDetails.prototype.getSatisfied = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.auditbotapi.ResultDetails} returns this
 */
proto.auditbotapi.ResultDetails.prototype.setSatisfied = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string reason = 2;
 * @return {string}
 */
proto.auditbotapi.ResultDetails.prototype.getReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.auditbotapi.ResultDetails} returns this
 */
proto.auditbotapi.ResultDetails.prototype.setReason = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string error = 3;
 * @return {string}
 */
proto.auditbotapi.ResultDetails.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.auditbotapi.ResultDetails} returns this
 */
proto.auditbotapi.ResultDetails.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string sample_date = 4;
 * @return {string}
 */
proto.auditbotapi.ResultDetails.prototype.getSampleDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.auditbotapi.ResultDetails} returns this
 */
proto.auditbotapi.ResultDetails.prototype.setSampleDate = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.auditbotapi.Tokens.prototype.toObject = function(opt_includeInstance) {
  return proto.auditbotapi.Tokens.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.auditbotapi.Tokens} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.auditbotapi.Tokens.toObject = function(includeInstance, msg) {
  var f, obj = {
    applicableTokens: (f = msg.getApplicableTokens()) && proto.auditbotapi.TokenDetails.toObject(includeInstance, f),
    populationTokens: (f = msg.getPopulationTokens()) && proto.auditbotapi.TokenDetails.toObject(includeInstance, f),
    validationTokens: (f = msg.getValidationTokens()) && proto.auditbotapi.TokenDetails.toObject(includeInstance, f),
    totalTokens: (f = msg.getTotalTokens()) && proto.auditbotapi.TokenDetails.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.auditbotapi.Tokens}
 */
proto.auditbotapi.Tokens.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.auditbotapi.Tokens;
  return proto.auditbotapi.Tokens.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.auditbotapi.Tokens} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.auditbotapi.Tokens}
 */
proto.auditbotapi.Tokens.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.auditbotapi.TokenDetails;
      reader.readMessage(value,proto.auditbotapi.TokenDetails.deserializeBinaryFromReader);
      msg.setApplicableTokens(value);
      break;
    case 2:
      var value = new proto.auditbotapi.TokenDetails;
      reader.readMessage(value,proto.auditbotapi.TokenDetails.deserializeBinaryFromReader);
      msg.setPopulationTokens(value);
      break;
    case 3:
      var value = new proto.auditbotapi.TokenDetails;
      reader.readMessage(value,proto.auditbotapi.TokenDetails.deserializeBinaryFromReader);
      msg.setValidationTokens(value);
      break;
    case 4:
      var value = new proto.auditbotapi.TokenDetails;
      reader.readMessage(value,proto.auditbotapi.TokenDetails.deserializeBinaryFromReader);
      msg.setTotalTokens(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.auditbotapi.Tokens.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.auditbotapi.Tokens.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.auditbotapi.Tokens} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.auditbotapi.Tokens.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getApplicableTokens();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.auditbotapi.TokenDetails.serializeBinaryToWriter
    );
  }
  f = message.getPopulationTokens();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.auditbotapi.TokenDetails.serializeBinaryToWriter
    );
  }
  f = message.getValidationTokens();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.auditbotapi.TokenDetails.serializeBinaryToWriter
    );
  }
  f = message.getTotalTokens();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.auditbotapi.TokenDetails.serializeBinaryToWriter
    );
  }
};


/**
 * optional TokenDetails applicable_tokens = 1;
 * @return {?proto.auditbotapi.TokenDetails}
 */
proto.auditbotapi.Tokens.prototype.getApplicableTokens = function() {
  return /** @type{?proto.auditbotapi.TokenDetails} */ (
    jspb.Message.getWrapperField(this, proto.auditbotapi.TokenDetails, 1));
};


/**
 * @param {?proto.auditbotapi.TokenDetails|undefined} value
 * @return {!proto.auditbotapi.Tokens} returns this
*/
proto.auditbotapi.Tokens.prototype.setApplicableTokens = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.auditbotapi.Tokens} returns this
 */
proto.auditbotapi.Tokens.prototype.clearApplicableTokens = function() {
  return this.setApplicableTokens(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.auditbotapi.Tokens.prototype.hasApplicableTokens = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional TokenDetails population_tokens = 2;
 * @return {?proto.auditbotapi.TokenDetails}
 */
proto.auditbotapi.Tokens.prototype.getPopulationTokens = function() {
  return /** @type{?proto.auditbotapi.TokenDetails} */ (
    jspb.Message.getWrapperField(this, proto.auditbotapi.TokenDetails, 2));
};


/**
 * @param {?proto.auditbotapi.TokenDetails|undefined} value
 * @return {!proto.auditbotapi.Tokens} returns this
*/
proto.auditbotapi.Tokens.prototype.setPopulationTokens = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.auditbotapi.Tokens} returns this
 */
proto.auditbotapi.Tokens.prototype.clearPopulationTokens = function() {
  return this.setPopulationTokens(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.auditbotapi.Tokens.prototype.hasPopulationTokens = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional TokenDetails validation_tokens = 3;
 * @return {?proto.auditbotapi.TokenDetails}
 */
proto.auditbotapi.Tokens.prototype.getValidationTokens = function() {
  return /** @type{?proto.auditbotapi.TokenDetails} */ (
    jspb.Message.getWrapperField(this, proto.auditbotapi.TokenDetails, 3));
};


/**
 * @param {?proto.auditbotapi.TokenDetails|undefined} value
 * @return {!proto.auditbotapi.Tokens} returns this
*/
proto.auditbotapi.Tokens.prototype.setValidationTokens = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.auditbotapi.Tokens} returns this
 */
proto.auditbotapi.Tokens.prototype.clearValidationTokens = function() {
  return this.setValidationTokens(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.auditbotapi.Tokens.prototype.hasValidationTokens = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional TokenDetails total_tokens = 4;
 * @return {?proto.auditbotapi.TokenDetails}
 */
proto.auditbotapi.Tokens.prototype.getTotalTokens = function() {
  return /** @type{?proto.auditbotapi.TokenDetails} */ (
    jspb.Message.getWrapperField(this, proto.auditbotapi.TokenDetails, 4));
};


/**
 * @param {?proto.auditbotapi.TokenDetails|undefined} value
 * @return {!proto.auditbotapi.Tokens} returns this
*/
proto.auditbotapi.Tokens.prototype.setTotalTokens = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.auditbotapi.Tokens} returns this
 */
proto.auditbotapi.Tokens.prototype.clearTotalTokens = function() {
  return this.setTotalTokens(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.auditbotapi.Tokens.prototype.hasTotalTokens = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.auditbotapi.TokenDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.auditbotapi.TokenDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.auditbotapi.TokenDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.auditbotapi.TokenDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    prompt: jspb.Message.getFieldWithDefault(msg, 1, 0),
    completion: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.auditbotapi.TokenDetails}
 */
proto.auditbotapi.TokenDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.auditbotapi.TokenDetails;
  return proto.auditbotapi.TokenDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.auditbotapi.TokenDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.auditbotapi.TokenDetails}
 */
proto.auditbotapi.TokenDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPrompt(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompletion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.auditbotapi.TokenDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.auditbotapi.TokenDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.auditbotapi.TokenDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.auditbotapi.TokenDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPrompt();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCompletion();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 prompt = 1;
 * @return {number}
 */
proto.auditbotapi.TokenDetails.prototype.getPrompt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.auditbotapi.TokenDetails} returns this
 */
proto.auditbotapi.TokenDetails.prototype.setPrompt = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 completion = 2;
 * @return {number}
 */
proto.auditbotapi.TokenDetails.prototype.getCompletion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.auditbotapi.TokenDetails} returns this
 */
proto.auditbotapi.TokenDetails.prototype.setCompletion = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.auditbotapi.Sources.prototype.toObject = function(opt_includeInstance) {
  return proto.auditbotapi.Sources.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.auditbotapi.Sources} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.auditbotapi.Sources.toObject = function(includeInstance, msg) {
  var f, obj = {
    objective: jspb.Message.getFieldWithDefault(msg, 1, ""),
    policy: jspb.Message.getFieldWithDefault(msg, 2, ""),
    evidence: jspb.Message.getFieldWithDefault(msg, 3, ""),
    guidance: jspb.Message.getFieldWithDefault(msg, 4, ""),
    services: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.auditbotapi.Sources}
 */
proto.auditbotapi.Sources.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.auditbotapi.Sources;
  return proto.auditbotapi.Sources.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.auditbotapi.Sources} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.auditbotapi.Sources}
 */
proto.auditbotapi.Sources.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setObjective(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPolicy(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEvidence(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setGuidance(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setServices(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.auditbotapi.Sources.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.auditbotapi.Sources.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.auditbotapi.Sources} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.auditbotapi.Sources.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getObjective();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPolicy();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEvidence();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getGuidance();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getServices();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string objective = 1;
 * @return {string}
 */
proto.auditbotapi.Sources.prototype.getObjective = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.auditbotapi.Sources} returns this
 */
proto.auditbotapi.Sources.prototype.setObjective = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string policy = 2;
 * @return {string}
 */
proto.auditbotapi.Sources.prototype.getPolicy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.auditbotapi.Sources} returns this
 */
proto.auditbotapi.Sources.prototype.setPolicy = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string evidence = 3;
 * @return {string}
 */
proto.auditbotapi.Sources.prototype.getEvidence = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.auditbotapi.Sources} returns this
 */
proto.auditbotapi.Sources.prototype.setEvidence = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string guidance = 4;
 * @return {string}
 */
proto.auditbotapi.Sources.prototype.getGuidance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.auditbotapi.Sources} returns this
 */
proto.auditbotapi.Sources.prototype.setGuidance = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string services = 5;
 * @return {string}
 */
proto.auditbotapi.Sources.prototype.getServices = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.auditbotapi.Sources} returns this
 */
proto.auditbotapi.Sources.prototype.setServices = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.auditbotapi.Completion.prototype.toObject = function(opt_includeInstance) {
  return proto.auditbotapi.Completion.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.auditbotapi.Completion} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.auditbotapi.Completion.toObject = function(includeInstance, msg) {
  var f, obj = {
    prompt: jspb.Message.getFieldWithDefault(msg, 1, 0),
    completion: jspb.Message.getFieldWithDefault(msg, 2, 0),
    startTime: jspb.Message.getFieldWithDefault(msg, 3, ""),
    endTime: jspb.Message.getFieldWithDefault(msg, 4, ""),
    wall: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.auditbotapi.Completion}
 */
proto.auditbotapi.Completion.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.auditbotapi.Completion;
  return proto.auditbotapi.Completion.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.auditbotapi.Completion} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.auditbotapi.Completion}
 */
proto.auditbotapi.Completion.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPrompt(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCompletion(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartTime(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndTime(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setWall(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.auditbotapi.Completion.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.auditbotapi.Completion.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.auditbotapi.Completion} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.auditbotapi.Completion.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPrompt();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getCompletion();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getStartTime();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEndTime();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getWall();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
};


/**
 * optional int64 prompt = 1;
 * @return {number}
 */
proto.auditbotapi.Completion.prototype.getPrompt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.auditbotapi.Completion} returns this
 */
proto.auditbotapi.Completion.prototype.setPrompt = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 completion = 2;
 * @return {number}
 */
proto.auditbotapi.Completion.prototype.getCompletion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.auditbotapi.Completion} returns this
 */
proto.auditbotapi.Completion.prototype.setCompletion = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string start_time = 3;
 * @return {string}
 */
proto.auditbotapi.Completion.prototype.getStartTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.auditbotapi.Completion} returns this
 */
proto.auditbotapi.Completion.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string end_time = 4;
 * @return {string}
 */
proto.auditbotapi.Completion.prototype.getEndTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.auditbotapi.Completion} returns this
 */
proto.auditbotapi.Completion.prototype.setEndTime = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional double wall = 5;
 * @return {number}
 */
proto.auditbotapi.Completion.prototype.getWall = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.auditbotapi.Completion} returns this
 */
proto.auditbotapi.Completion.prototype.setWall = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.auditbotapi.ControlMetadata.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.auditbotapi.ControlMetadata.prototype.toObject = function(opt_includeInstance) {
  return proto.auditbotapi.ControlMetadata.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.auditbotapi.ControlMetadata} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.auditbotapi.ControlMetadata.toObject = function(includeInstance, msg) {
  var f, obj = {
    suggestedEvidence: jspb.Message.getFieldWithDefault(msg, 1, ""),
    suggestedTests: jspb.Message.getFieldWithDefault(msg, 2, ""),
    evidenceIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    policyDocumentIdsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.auditbotapi.ControlMetadata}
 */
proto.auditbotapi.ControlMetadata.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.auditbotapi.ControlMetadata;
  return proto.auditbotapi.ControlMetadata.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.auditbotapi.ControlMetadata} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.auditbotapi.ControlMetadata}
 */
proto.auditbotapi.ControlMetadata.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSuggestedEvidence(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSuggestedTests(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addEvidenceIds(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addPolicyDocumentIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.auditbotapi.ControlMetadata.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.auditbotapi.ControlMetadata.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.auditbotapi.ControlMetadata} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.auditbotapi.ControlMetadata.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuggestedEvidence();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSuggestedTests();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEvidenceIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getPolicyDocumentIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
};


/**
 * optional string suggested_evidence = 1;
 * @return {string}
 */
proto.auditbotapi.ControlMetadata.prototype.getSuggestedEvidence = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.auditbotapi.ControlMetadata} returns this
 */
proto.auditbotapi.ControlMetadata.prototype.setSuggestedEvidence = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string suggested_tests = 2;
 * @return {string}
 */
proto.auditbotapi.ControlMetadata.prototype.getSuggestedTests = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.auditbotapi.ControlMetadata} returns this
 */
proto.auditbotapi.ControlMetadata.prototype.setSuggestedTests = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string evidence_ids = 3;
 * @return {!Array<string>}
 */
proto.auditbotapi.ControlMetadata.prototype.getEvidenceIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.auditbotapi.ControlMetadata} returns this
 */
proto.auditbotapi.ControlMetadata.prototype.setEvidenceIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.auditbotapi.ControlMetadata} returns this
 */
proto.auditbotapi.ControlMetadata.prototype.addEvidenceIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.auditbotapi.ControlMetadata} returns this
 */
proto.auditbotapi.ControlMetadata.prototype.clearEvidenceIdsList = function() {
  return this.setEvidenceIdsList([]);
};


/**
 * repeated string policy_document_ids = 4;
 * @return {!Array<string>}
 */
proto.auditbotapi.ControlMetadata.prototype.getPolicyDocumentIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.auditbotapi.ControlMetadata} returns this
 */
proto.auditbotapi.ControlMetadata.prototype.setPolicyDocumentIdsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.auditbotapi.ControlMetadata} returns this
 */
proto.auditbotapi.ControlMetadata.prototype.addPolicyDocumentIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.auditbotapi.ControlMetadata} returns this
 */
proto.auditbotapi.ControlMetadata.prototype.clearPolicyDocumentIdsList = function() {
  return this.setPolicyDocumentIdsList([]);
};


/**
 * @enum {number}
 */
proto.auditbotapi.EnforcementMode = {
  ABSOLUTE: 0,
  RELATIVE: 1
};

/**
 * @enum {number}
 */
proto.auditbotapi.ThresholdOperator = {
  GREATER_THAN_OR_EQUAL: 0,
  LESS_THAN_OR_EQUAL: 1
};

/**
 * @enum {number}
 */
proto.auditbotapi.HighlightCondition = {
  WHEN_TRUE: 0,
  WHEN_FALSE: 1
};

goog.object.extend(exports, proto.auditbotapi);
