import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useFetchDocumentBody } from 'src/components/async/document/useDocument'
import { useFetchGrcDocumentBody } from 'src/pages/ManageDocuments/manageDocuments.hooks'
import { UrlFragments } from 'src/Utils/globalEnums'
import { urlHasFragment } from 'src/Utils/url.helpers'

export const useFetchDocumentBodyWrapper = ({
  documentId,
  contentId,
  mime,
  isGrc = false,
}: {
  documentId: string
  contentId: string
  mime: string
  isGrc: boolean
}): (() => Promise<Uint8Array | string | undefined>) => {
  const downloadGrcDocument = useFetchGrcDocumentBody(documentId, mime)
  const downloadDocument = useFetchDocumentBody(contentId, mime)

  return isGrc ? downloadGrcDocument : downloadDocument
}

export const useToggleDocumentViewer = (): {
  isModalOpen: boolean
  toggleModal: () => void
} => {
  const [isModalOpen, setModalOpen] = useState<boolean>(false)
  const navigate = useNavigate()
  const toggleModal = () => {
    if (isModalOpen) {
      navigate(window.location.pathname + window.location.search, {
        replace: true,
      })
    }
    setModalOpen(!isModalOpen)
  }
  useEffect(() => {
    const shouldExpand = urlHasFragment(UrlFragments.SOURCES)
    setModalOpen(shouldExpand)
  }, [])
  return { isModalOpen, toggleModal }
}
