import React, { useEffect, useRef, useState } from 'react'
import { useFileTypeIcon } from 'src/components/ModalForms/FileType/useFileTypeIcon'
import {
  useEvidenceCsv,
  useEvidenceExcel,
} from 'src/components/async/document/useDocument'
import { CsvDataGridContainer } from 'src/components/ModalForms/Evidence/evidence.styles'
import {
  StyledCsvBody,
  StyledCsvModal,
} from 'src/pages/Evidence/evidence.styles'
import { EvidenceDataGrid } from 'src/components/ModalForms/Evidence/ViewEvidenceForm/ViewEvidenceForm.components'
import { getFileType } from 'src/components/ModalForms/Evidence/ViewEvidenceForm/ViewEvidenceForm.utils'
import log from 'loglevel'
import { TextButton } from '../Buttons/TextButton'
import { TableListLoader } from '../ContentLoaders/TableListLoader'
import {
  ExpandButtonRow,
  PreviewDiv,
  StyledCloseButton,
  StyledEvidenceIframe,
  StyledEvidenceImage,
  StyledImagePreview,
  StyledPreviewModal,
  StyledPreviewModalIframe,
  StyledPreviewModalImage,
} from './DocumentViewer.styles'
import { useToggleDocumentViewer } from './DocumentViewer.hooks'

export const InlineImage = ({
  file,
  isEvidenceShowPage,
  link,
  isModalOpen,
  toggleModal,
}: {
  file: File
  isEvidenceShowPage?: boolean
  link: string
  isModalOpen: boolean
  toggleModal: () => void
}): JSX.Element => (
  <>
    {isEvidenceShowPage ? (
      <StyledEvidenceImage
        src={link as string}
        alt={file.name}
        onClick={toggleModal}
      />
    ) : (
      <PreviewDiv>
        <StyledImagePreview src={link} alt="Preview" onClick={toggleModal} />
      </PreviewDiv>
    )}
    {isModalOpen && (
      <StyledPreviewModal onClick={toggleModal}>
        <StyledPreviewModalImage src={link as string} alt="Expanded Preview" />
        <CloseButton />
      </StyledPreviewModal>
    )}
  </>
)

export const InlinePdf = ({
  link,
  isEvidenceShowPage,
  isModalOpen,
  toggleModal,
}: {
  link: string
  isEvidenceShowPage?: boolean
  isModalOpen: boolean
  toggleModal: () => void
}): JSX.Element => {
  const FileTypeIcon = useFileTypeIcon({ mime: 'application/pdf' })
  return (
    <>
      {isEvidenceShowPage ? (
        <StyledEvidenceIframe src={link as string} typeof="application/pdf" />
      ) : (
        <PreviewDiv onClick={toggleModal}>
          <FileTypeIcon width="120px" height="120px" />
          <p>View PDF</p>
        </PreviewDiv>
      )}
      {isModalOpen && (
        <StyledPreviewModal onClick={toggleModal}>
          <StyledPreviewModalIframe
            src={link as string}
            typeof="application/pdf"
          />
          <CloseButton />
        </StyledPreviewModal>
      )}
    </>
  )
}

export const InlineCsv = ({
  contentId,
  isModalOpen,
  toggleModal,
}: {
  contentId: string
  isModalOpen: boolean
  toggleModal: () => void
}): JSX.Element => {
  // get rows and columns from csv file
  const { rows, columns, isLoading, error } = useEvidenceCsv(
    contentId,
    !!contentId,
  )
  if (isLoading) {
    return <TableListLoader />
  }
  if (error) {
    log.error('Error fetching csv file', error)
    return <></>
  }

  return (
    <>
      <CsvDataGridContainer>
        <EvidenceDataGrid rows={rows} columns={columns} />
      </CsvDataGridContainer>
      {isModalOpen && (
        <CsvPreviewModal
          body={<EvidenceDataGrid rows={rows} columns={columns} />}
          onClose={toggleModal}
        />
      )}
    </>
  )
}

export const InlineExcel = ({
  contentId,
  isModalOpen,
  toggleModal,
}: {
  contentId: string
  isModalOpen: boolean
  toggleModal: () => void
}): JSX.Element => {
  // get rows and columns from excel file
  const { rows, columns, isLoading, error } = useEvidenceExcel(
    contentId,
    !!contentId,
  )
  if (isLoading) {
    return <TableListLoader />
  }
  if (error) {
    log.error('Error fetching csv file', error)
    return <>{error}</>
  }

  return (
    <>
      <CsvDataGridContainer>
        <EvidenceDataGrid rows={rows} columns={columns} />
      </CsvDataGridContainer>
      {isModalOpen && (
        <CsvPreviewModal
          body={<EvidenceDataGrid rows={rows} columns={columns} />}
          onClose={toggleModal}
        />
      )}
    </>
  )
}

export const InlineDocument = ({
  file,
  contentId,
  isEvidenceShowPage,
}: {
  file: File
  contentId: string
  isEvidenceShowPage?: boolean
}): JSX.Element => {
  const { isModalOpen, toggleModal } = useToggleDocumentViewer()
  const [link, setLink] = useState<string | null>(null)
  const FileTypeIcon = useFileTypeIcon({ mime: file.type })
  const { isImage, isPdf, isCsv, isExcel } = getFileType(file.type)

  useEffect(() => {
    const blob = new Blob([file], { type: file.type })
    const url = URL.createObjectURL(blob)
    setLink(url)

    return () => {
      URL.revokeObjectURL(url)
    }
  }, [file])

  if ((!isImage && !isPdf && !isCsv && !isExcel) || !link) {
    return <></>
  }

  return (
    <>
      {isEvidenceShowPage && (
        <ExpandButtonRow>
          <TextButton onClick={toggleModal}>Expand</TextButton>
        </ExpandButtonRow>
      )}
      {isImage ? (
        <InlineImage
          file={file}
          isEvidenceShowPage={isEvidenceShowPage}
          link={link}
          isModalOpen={isModalOpen}
          toggleModal={toggleModal}
        />
      ) : isPdf ? (
        <InlinePdf
          link={link}
          isEvidenceShowPage={isEvidenceShowPage}
          isModalOpen={isModalOpen}
          toggleModal={toggleModal}
        />
      ) : isCsv ? (
        <InlineCsv
          contentId={contentId}
          isModalOpen={isModalOpen}
          toggleModal={toggleModal}
        />
      ) : isExcel ? (
        <InlineExcel
          contentId={contentId}
          isModalOpen={isModalOpen}
          toggleModal={toggleModal}
        />
      ) : (
        <PreviewDiv onClick={toggleModal}>
          <FileTypeIcon width="120px" height="120px" />
          <p>View Document</p>
        </PreviewDiv>
      )}
    </>
  )
}

export const CloseButton = ({
  onClick,
}: {
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
}): JSX.Element => (
  <StyledCloseButton onClick={onClick}>close</StyledCloseButton>
)

export const CloseTextButton = ({
  onClick,
}: {
  onClick: (e: React.MouseEvent<HTMLElement>) => void
}): JSX.Element => (
  <StyledCloseButton onClick={onClick}>close</StyledCloseButton>
)

export const CsvPreviewModal = ({
  body,
  onClose,
}: {
  body: JSX.Element
  onClose: () => void
}): JSX.Element => {
  const divRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (divRef.current && !divRef.current.contains(event.target as Node)) {
        onClose()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [onClose])

  return (
    <StyledCsvModal>
      <StyledCsvBody ref={divRef}>{body}</StyledCsvBody>
      <CloseButton onClick={onClose} />
    </StyledCsvModal>
  )
}
