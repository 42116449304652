import React, { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { Dropdown } from 'react-bootstrap'
import queryString from 'query-string'
import { MenuPlaceholder } from 'src/components/Placeholders/MenuPlaceholder'
import { Spinner } from 'src/Throbber'
import { NONE_ID } from 'src/Utils/globalConstants'
import { useNavigateContext } from '../../../Navigation/NavigateContext'
import { FilterParam } from '../FilterBar.types'
import { FilterDropdownToggle } from '../FilterBar.styles'
import { NestedDropdownMenu } from './FilterDropdown.styles'
import { DropdownItems } from './FilterDropdown'
import { DropdownItemType } from './FilterDropdown.constants'

export const NestedFilter = ({
  filterParam,
  filterValues,
}: {
  filterParam: FilterParam
  filterValues: DropdownItemType[]
}): JSX.Element => {
  const location = useLocation()
  const searchParamsFallback = queryString.parse(location.search, {
    arrayFormat: 'bracket',
  })
  const {
    navigateState: {
      parsedQueryParams,
      locationSearchString: contextLocationSearchString,
    },
  } = useNavigateContext()
  const searchParams = parsedQueryParams || searchParamsFallback
  const locationSearchString = contextLocationSearchString ?? location.search

  const appliedFilters = useMemo(() => {
    const appliedFilters: Record<string, string[]> = {}
    const preprocessed = searchParams[filterParam]
    if (Array.isArray(preprocessed)) {
      appliedFilters[filterParam] = preprocessed
    } else {
      appliedFilters[filterParam] = preprocessed ? [preprocessed] : []
    }
    return appliedFilters
  }, [searchParams, filterParam])

  return (
    <NestedDropdownMenu>
      <DropdownItems
        filterParam={filterParam}
        filterValues={filterValues}
        appliedFilters={appliedFilters}
        location={location}
        searchParams={searchParams}
        activeSubmenu={NONE_ID}
        setActiveSubmenu={() => NONE_ID}
        locationSearchString={locationSearchString}
      />
    </NestedDropdownMenu>
  )
}

export const LoadingFilterDropdown = ({
  filterName,
  customName,
}: {
  filterName: string
  customName?: string
}): JSX.Element => (
  <>
    <Dropdown.Toggle
      as={FilterDropdownToggle}
      id={`${filterName}-filter-dropdown`}
    >
      <p>{customName || filterName}</p>
      <Spinner size="s" color="primary" mx="xxs" />
    </Dropdown.Toggle>
    <Dropdown.Menu>
      <MenuPlaceholder height={108} />
    </Dropdown.Menu>
  </>
)
