import {
  Callback,
  EventProperties,
  Options,
  UserTraits,
} from '@segment/analytics-next'

export type TrackFunc = (
  event: string,
  properties?: EventProperties,
  options?: Options,
  callback?: Callback,
) => void

export interface UseAnalytics {
  events: Record<string, string>
  identify: (
    userId: string,
    traits: UserTraits,
    options?: Options,
    callback?: Callback,
  ) => void
  identifyOnce: (
    userId: string,
    traits: UserTraits,
    options?: Options,
    callback?: Callback,
  ) => void
  pageView: (location: Location, properties: EventProperties) => void
  reset: () => void
  track: TrackFunc
  trackOnce: TrackFunc
  isSegmentLoaded: boolean
}

/**
 * Current API keys representing our Segment workspaces - https://app.segment.com/trustero-thumper/sources
 * Since the FE client will always expose these keys there is no real need to use ENV vars for these values for v1
 * The risk is low for malicious users as we are only open to inaccurate data/events & potentially hitting our monthly quota
 */

// If we want to use AWS Secret Manager or NODE_ENV/.env files in the future we can rotate these keys then
export const segmentProdKey = 'li0DcJuE1FeF7GPm2AqR6FyBqcprAYN5'
// We will use the same development API key for localhost and all non-production builds for v1
export const segmentDevKey = 'mqIY9jI1qfEq2epyKW60zAhw3del8Ccu'

// Source will always be 'APP' in the Frontend (Ntrced events will have 'API' prefix)
const Src = 'app'

export enum UserAction {
  Click = 'click',
  Hover = 'hover',
  View = 'view',
  Submit = 'submit',
  Select = 'select',
  Create = 'create',
  Update = 'update',
  Delete = 'delete',
  OpenTab = 'openTab', // Used with Google Analytics
  Attach = 'attach',
}

/**
 * This enum contains our full set of product analytics events that we are tracking.
 * All new events should have a new key added using this convention:
 *
 * <Source>-<Action>-<Page/Feature/Concept>
 *
 * Note: Please ensure all string values use '-' instead of spaces
 *
 * Note: For any key that needs custom data, please leave a comment listing the
 * properties passed as this file will be our source of truth
 *
 */
export const APP_ANALYTICS_EVENTS: Record<string, string> = {
  // NavBar Sections
  C_NAV_HOME: `${Src}-${UserAction.Click}-nav-home`,
  C_NAV_CONTENT: `${Src}-${UserAction.Click}-nav-content`,
  C_NAV_AUDIT: `${Src}-${UserAction.Click}-nav-audit`,
  C_NAV_ANALYZE: `${Src}-${UserAction.Click}-nav-analyze`,
  C_NAV_SETTINGS: `${Src}-${UserAction.Click}-nav-settings`,

  // NavBar Items
  C_NAV_DASHBOARD: `${Src}-${UserAction.Click}-nav-dashboard`,
  C_NAV_SERVICES: `${Src}-${UserAction.Click}-nav-services`,
  C_NAV_RECEPTORS: `${Src}-${UserAction.Click}-nav-receptors`,
  C_NAV_COMPANY_INFO: `${Src}-${UserAction.Click}-nav-company-info`,
  C_NAV_POLICIES: `${Src}-${UserAction.Click}-nav-policies`,
  C_NAV_CONTROLS: `${Src}-${UserAction.Click}-nav-controls`,
  C_NAV_RISKS: `${Src}-${UserAction.Click}-nav-risks`,
  C_NAV_AUDITS: `${Src}-${UserAction.Click}-nav-audits`,
  C_NAV_REQUESTS: `${Src}-${UserAction.Click}-nav-requests`,
  C_NAV_REPORT_SCAN: `${Src}-${UserAction.Click}-nav-report-scan`,
  C_NAV_USERS: `${Src}-${UserAction.Click}-nav-users`,
  C_NAV_ROADMAP: `${Src}-${UserAction.Click}-nav-roadmap`,
  C_NAV_AUDITBOT: `${Src}-${UserAction.Click}-nav-auditbot`,
  C_NAV_AI_GRC_QA: `${Src}-${UserAction.Click}-nav-security-ai-grc-qa`,
  C_NAV_TRUSTERO_INTELLIGENCE: `${Src}-${UserAction.Click}-nav-trustero-intelligence`,
  C_NAV_KNOWLEDGE_BASE: `${Src}-${UserAction.Click}-nav-knowledge-base`,
  C_NAV_VENDORS: `${Src}-${UserAction.Click}-nav-vendors`,
  C_NAV_SCOPE: `${Src}-${UserAction.Click}-nav-scope`,
  C_NAV_ACCOUNT_SETTINGS: `${Src}-${UserAction.Click}-nav-account-settings`,
  C_NAV_HELP: `${Src}-${UserAction.Click}-nav-help`,
  C_NAV_EVIDENCE: `${Src}-${UserAction.Click}-nav-evidence`,
  C_NAV_GRC_PLATFORMS: `${Src}-${UserAction.Click}-nav-grc-platforms`,
  C_NAV_DOCUMENT_MANAGEMENT: `${Src}-${UserAction.Click}-nav-document-management-systems`,
  C_NAV_INTEGRATIONS: `${Src}-${UserAction.Click}-nav-integrations`,
  C_NAV_USAGE: `${Src}-${UserAction.Click}-nav-usage`,

  // Activity Log
  V_ACTIVITY_LOG: `${Src}-${UserAction.View}-activity-log`, // { toggle: 'open' | 'close', source: 'panel' | 'navbar' }

  // Authentication
  C_SIGNIN: `${Src}-${UserAction.Click}-signin`,
  C_SIGNOUT: `${Src}-${UserAction.Click}-signout`,

  // Controls
  C_CTRL_SCAN_TEST: `${Src}-${UserAction.Click}-control-scan-test`,
  C_CTRL_ADD_TEST: `${Src}-${UserAction.Click}-control-add-test`,
  C_CTRL_TOGGLE_ACCORDION: `${Src}-${UserAction.Click}-control-toggle-accordion`, // { modelId, type }

  // Suggestions
  C_SUGG_DISMISS_GUIDANCE: `${Src}-${UserAction.Click}-sugg-guidance-dismiss`, // { modelId, type, suggestion}
  C_SUGG_REFRESH_GUIDANCE: `${Src}-${UserAction.Click}-sugg-guidance-refresh`, // { modelId, type, oldSuggestion}
  C_SUGG_RESTORE_GUIDANCE: `${Src}-${UserAction.Click}-sugg-guidance-restore`, // { modelId }
  C_SUGG_SWITCH_TABS: `${Src}-${UserAction.Click}-sugg-switch-tabs`, // { modelId, tab }

  // Policies
  C_BRING_POLICY_AUDIT: `${Src}-${UserAction.Submit}-bring-policy-into-audit`,

  // Profile Menu - counts for these events can assume menu was opened prior
  C_MY_PROFILE: `${Src}-${UserAction.Click}-my-profile`,
  C_SWITCH_ACCT: `${Src}-${UserAction.Click}-switch-account`,

  // Marketplace
  C_MARKETPLACE_LINK: `${Src}-${UserAction.Click}-marketplace-link`, // { service }

  // Risks
  C_ADD_MANUAL_RISK: `${Src}-${UserAction.Click}-risk-add-manual`,
  C_ADD_CSV_RISK: `${Src}-${UserAction.Click}-risk-add-csv`,
  CREATE_RISK: `${Src}-${UserAction.Create}-risk`, // { type: <manual, csv> }
  UPDATE_RISK: `${Src}-${UserAction.Update}-risk-edit`, // { field }
  DELETE_RISK: `${Src}-${UserAction.Delete}-risk-delete`,
  LINK_CONTROL: `${Src}-${UserAction.Submit}-risk-control-link`,
  UNLINK_CONTROL: `${Src}-${UserAction.Submit}-risk-control-unlink`,

  // Users
  C_INVITE_NEW_USER: `${Src}-${UserAction.Click}-invite-new-user`,

  // Upload Evidence
  S_EVIDENCE_UPLOAD: `${Src}-${UserAction.Submit}-multiple-evidence-upload`, // { count, uploadSize (MB) }

  // Framework Wizard
  CREATE_FRAMEWORK: `${Src}-${UserAction.Create}-compliance-framework`, // { modelId, name }

  // SOC 2 Report Summary
  S_REPORT_SCAN: `${Src}-${UserAction.Submit}-report-scan`, // { fileName, fileSize }
  V_REPORT_SCAN: `${Src}-${UserAction.View}-report-scan`, // { id, }

  // Saas Buyer Events
  // Saas Buyer Welcome Modal
  V_WELCOME_MODAL: `${Src}-${UserAction.View}-welcome-modal`, // { signUpType }
  // User attaches first SOC 2 (SOC 2 Report Summary)
  A_WELCOME_MODAL: `${Src}-${UserAction.Attach}-welcome-modal`,
  // User uploads first SOC 2 (SOC 2 Report Summary)
  S_WELCOME_MODAL: `${Src}-${UserAction.Submit}-welcome-modal`,
  // User uploads first name/company name
  S_WELCOME_MODAL_USER: `${Src}-${UserAction.Submit}-welcome-modal-user`, // { signUpType }
  // User uploads first name/company name
  C_WELCOME_MODAL_TERMS: `${Src}-${UserAction.Click}-welcome-modal-view-terms`, // { signUpType }

  // Sign Up Page `/scan_report`
  C_SIGNUP_OIDC: `${Src}-${UserAction.Click}-signup-oidc`, // { provider, signUpType }
  // User clicks to redirect to `/login` page
  C_SIGNUP_LOGIN: `${Src}-${UserAction.Click}-signup-login`, // { signUpType }
  // User clicks to go to `trustero.com` from `/scan_report` page
  C_SIGNUP_WEBSITE: `${Src}-${UserAction.Click}-signup-website`, // { signUpType }

  // SOC 2 Report Summary (Additional Saas Buyer Tracking)
  S_ADDITIONAL_BUYER_REPORT_SCAN: `${Src}-${UserAction.Submit}-additional-buyer-report`,

  // Users (Saas Buyer Tracking)
  S_BUYER_INVITED_USER: `${Src}-${UserAction.Submit}-buyer-invited-user`,

  // Saas Buyer Sales Page (SaasBuyerDashboard)
  V_BUYER_SALES_PAGE: `${Src}-${UserAction.View}-buyer-sales-page`,
  C_BUYER_SALES_WALKTHROUGH: `${Src}-${UserAction.Click}-buyer-sales-walkthrough`,
  C_BUYER_SALES_DEMO: `${Src}-${UserAction.Click}-buyer-sales-demo`,
  C_BUYER_SALES_TRIAL: `${Src}-${UserAction.Click}-buyer-sales-trial`,

  // Examine and Test
  C_AUDITBOT_SCAN_AGAIN: `${Src}-${UserAction.Click}-scan-again`, // { auditId, controlId }

  // Control Test Balance
  V_CONTROL_TEST_HARD_LIMIT: `${Src}-${UserAction.View}-control-test-hard-limit`, // { accountId }
  V_CONTROL_TEST_SOFT_LIMIT: `${Src}-${UserAction.View}-control-test-soft-limit`, // { accountId }

  // AI GRC Q&A Balance
  V_AI_GRC_QA_SOFT_LIMIT: `${Src}-${UserAction.View}-security-ai-grc-qa-soft-limit`, // { accountId }
  V_AI_GRC_QA_HARD_LIMIT: `${Src}-${UserAction.View}-security-ai-grc-qa-hard-limit`, // { accountId }

  // AI GRC Q&A
  S_AI_GRC_QA: `${Src}-${UserAction.Submit}-ai-grc-qa`, // { accountId, accountType }

  // AI GRC Q&A Knowledge Base
  S_KNOWLEDGE_BASE_CSV: `${Src}-${UserAction.Submit}-knowledge-base-csv`, // { accountId, accountType, questionCount, answerCount }

  // Trustero Knowledge Base
  C_KNOWLEDGE_BASE: `${Src}-${UserAction.Click}-knowledge-base`,
  C_SUPPORT: `${Src}-${UserAction.Click}-support`,
}

/**
 * These are the high level sections/routes for our application
 */
export const APP_ANALYTICS_ROUTE_CATEGORIES: Record<string, string> = {
  LOGIN: 'login',
  AUDITS: 'audits',
  ACCOUNTS: 'accounts',
  DASHBOARD: 'dashboard',
  SERVICES: 'services',
  COMPANY_INFO: 'company_info',
  POLICIES: 'policies',
  CONTROLS: 'controls',
  REQUESTS: 'requests',
  USERS: 'users',
  ACTIVITIES: 'activities',
  PROFILE: 'profile',
  RISKS: 'risks',
  AUDITBOT: 'auditbot',
  ROADMAP: 'roadmap',
  AI_GRC_QA: 'ai_grc_qa',
  TRUSTERO_INTELLIGENCE: 'trustero_intelligence',
  KNOWLEDGE_BASE: 'knowledge_base',
  HELP: 'help',
}

export const APP_ANALYTICS_ROUTE_PAGE_NAMES: Record<string, string> = {
  // User Guide
  QUICK_START: 'quickstart',
  OVERVIEW: 'overview',
  LEARN_SOC2: 'learn-about-soc2',
  LEARN_ISO: 'learn-about-iso',
  GLOSSARY: 'glossary',
  // Services
  SERVICES_INDEX: 'services/index',
  SERVICES_SHOW: 'services/show',
  // Receptors
  RECEPTORS_INDEX: 'receptors/index',
  RECEPTORS_SHOW: 'receptors/show',
  // Policies
  POLICIES_INDEX: 'policies/index',
  POLICIES_SHOW: 'policies/show',
  // Controls
  CONTROLS_INDEX: 'controls/index',
  CONTROLS_SHOW: 'controls/show',
  // Requests
  REQUESTS_INDEX: 'requests/index',
  REQUESTS_SHOW: 'requests/show',
  // Risks
  RISKS_INDEX: 'risks/index',
  RISKS_SHOW: 'risks/show',
  // Auditbot (Old)
  AUDITBOT_INDEX: 'auditbot/index',
  AUDITBOT_SHOW: 'auditbot/show',
  // AI Examination and Test
  AI_EXAMINATION_AND_TEST_INDEX: 'ai_examination_and_test/index',
  AI_EXAMINATION_AND_TEST_SHOW: 'ai_examination_and_test/show',
  // Roadmap
  ROADMAP_INDEX: 'roadmap/index',
  ROADMAP_INITIAL_SCOPING: 'roadmap/show/initial-scoping',
  ROADMAP_PREPARE_CONTENT: 'roadmap/show/prepare-content',
  ROADMAP_OPERATIONALIZE: 'roadmap/show/operationalize',
  ROADMAP_HANDLE_REQUESTS: 'roadmap/show/handle-requests',
  ROADMAP_AUDIT_READINESS: 'roadmap/show/audit-readiness',
  // Security Questionnaire (Old)
  SECURITY_QUESTIONNAIRE_INDEX: 'security_questionnaire/index',
  SECURITY_QUESTIONNAIRE_SHOW: 'security_questionnaire/show',
  // AI GRC Q&A
  AI_GRC_QA_INDEX: 'ai_grc_qa/index',
  AI_GRC_QA_SHOW: 'ai_grc_qa/show',
  // Trustero Intelligence
  TRUSTERO_INTELLIGENCE_INDEX: 'trustero_intelligence/index',
  TRUSTERO_INTELLIGENCE_SHOW: 'trustero_intelligence/show',
  // Knowledge Base
  KNOWLEDGE_BASE_SHOW: 'knowledge_base',
}

// Legacy analytics
export enum EventCategory {
  ServiceRependencies_RecomendedActions = 'serviceDependencies:recommendeComplicances',
  ServicesGrid_RelatedControl = 'serviceGrid:relatedControl',
}
