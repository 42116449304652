import React, {
  createContext,
  useCallback,
  useContext,
  useReducer,
} from 'react'
import jwtDecode, { JwtPayload } from 'jwt-decode'
import log from 'loglevel'
import { Metadata } from 'grpc-web'
import {
  USE_CASE,
  USER_ROLE,
} from '@trustero/trustero-api-web/lib/account/account_pb'
import { loadFromStorage, saveToStorage } from '../Utils/storage'

const STORAGE_KEY = 'authcontext'
const logger = log.getLogger('context')

export type JwtNtrcePayload = JwtPayload & {
  aid: string
  email: string
  auc: string
  rol: string
}

export type AuthRecord = {
  isAuthenticated: boolean
  accessToken: string
  accountId: string
  email: string
  useCase: USE_CASE
  role: USER_ROLE
}

export type AuthAction = {
  type: AuthActionType
  accessToken?: string
  accountId?: string
  email?: string
  useCase: USE_CASE
  role: USER_ROLE
}

export const empty_authctx: AuthRecord = {
  isAuthenticated: false,
  accessToken: '',
  accountId: '',
  email: '',
  useCase: USE_CASE.SAAS_BUYER, // we want to default to limited access
  role: USER_ROLE.READONLY, // we want to default to limited access
}

export enum AuthActionType {
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
  REGISTER_LOGIN = 'REGISTER_LOGIN',
  SET_EMAIL = 'SET_EMAIL',
  REFRESH_TOKEN = 'REFRESH_TOKEN',
  QUIET_LOGOUT = 'QUIET_LOGOUT',
}

export const tokenDecodeToMinutes = (
  accessToken: string,
  debug: boolean,
): { age: number; expiration: number } => {
  const result = {
    age: Number.MAX_SAFE_INTEGER,
    expiration: -1,
  }
  if (!accessToken) {
    return result
  }
  const nowUTC = Date.now() / 1000.0
  const {
    exp: expirationUTC,
    aid: accountId,
    iat: issuedUTC,
    auc: useCase,
    rol: role,
  } = jwtDecode<JwtNtrcePayload>(accessToken)

  if (expirationUTC) {
    const deltaMinutes = (expirationUTC - nowUTC) / 60
    result.expiration = Math.floor(deltaMinutes)
  }
  if (issuedUTC) {
    const deltaMinutes = (nowUTC - issuedUTC) / 60
    result.age = Math.floor(deltaMinutes)
  }
  if (debug) {
    logger.debug('token expiration =', expirationUTC, 'now = ', nowUTC)
    logger.debug('  ', result.expiration, 'minutes until expiration')
    logger.debug('  ', result.age, 'minutes since created')
    logger.debug('  accountId ', accountId)
    logger.debug('  useCase', useCase)
    logger.debug('  role', role)
  }
  return result
}

const _load = (): AuthRecord => {
  // default empty auth info
  const authctx = loadFromStorage(STORAGE_KEY, empty_authctx)
  logger.debug('loaded authctx = ', authctx)
  //to print out debug information about accessToken time
  tokenDecodeToMinutes(authctx.accessToken, true)
  return authctx
}
const initCtxOnLoad = _load()

const _save = (authctx: AuthRecord) => {
  saveToStorage(STORAGE_KEY, authctx)
  logger.debug('saved authctx = ', authctx)
  //to print out debug information about accessToken time
  tokenDecodeToMinutes(authctx.accessToken, true)
}

export const useAuth = (): AuthRecord => {
  const { authCtx } = useContext(AuthContext)
  return authCtx
}

export const useToken = (): string => {
  const auth = useAuth()
  return auth.accessToken
}

// TODO: Remove read only from provider and add a new hook
export const useIsProvider = (): boolean => {
  const { authCtx } = useContext(AuthContext)
  return authCtx.useCase === USE_CASE.SAAS_PROVIDER
}

export const useIsInternalUser = (): boolean => {
  const { authCtx } = useContext(AuthContext)
  return authCtx.email.toLowerCase().endsWith('@trustero.com')
}

export const useIsReadOnlyUser = (): boolean => {
  const { authCtx } = useContext(AuthContext)
  return authCtx.role === USER_ROLE.READONLY
}

export const useIsAdmin = (): boolean => {
  const { authCtx } = useContext(AuthContext)
  return authCtx.role === USER_ROLE.ADMIN
}

export const useIsAuditor = (): boolean => {
  const { authCtx } = useContext(AuthContext)
  return authCtx.role === USER_ROLE.AUDITOR
}

export const useIsBuyer = (): boolean => {
  const { authCtx } = useContext(AuthContext)
  return authCtx.useCase === USE_CASE.SAAS_BUYER
}

export const useCurrentUserEmail = (): string => {
  const { authCtx } = useContext(AuthContext)
  return authCtx.email
}

type AuthContextType = {
  authCtx: AuthRecord
  authDispatch: React.Dispatch<AuthAction>
}

export const AuthContext = createContext<AuthContextType>({
  authCtx: initCtxOnLoad,
  authDispatch: () => null,
})

const logMissingParams = (
  message: string,
  params: Array<{ name: string; exists: boolean }>,
) => {
  let errorMessage = `${message}:`
  let count = 0
  params.forEach((param) => {
    if (!param.exists) {
      errorMessage =
        count === 0
          ? `${errorMessage} ${param.name}`
          : `${errorMessage} and ${param.name}`
      count++
    }
  })
  errorMessage = `${errorMessage} not provided`
  if (count) log.error('Error in authContext', errorMessage)
}

const isEqual = (x: AuthRecord, y: AuthRecord): boolean => {
  return (
    x.isAuthenticated === y.isAuthenticated &&
    x.accessToken === y.accessToken &&
    x.accountId === y.accountId &&
    x.email === y.email &&
    x.useCase === y.useCase
  )
}

const updatedState = (
  original: AuthRecord,
  updated: AuthRecord,
): AuthRecord => {
  return isEqual(original, updated) ? original : updated
}

export const authReducer = (
  state: AuthRecord,
  action: AuthAction,
): AuthRecord => {
  let newState = empty_authctx
  const { type, accessToken, accountId, email, useCase, role } = action

  switch (type) {
    case AuthActionType.LOGIN: {
      if (
        accessToken &&
        accountId !== undefined &&
        email &&
        useCase !== undefined &&
        role !== undefined
      ) {
        newState = {
          ...state,
          isAuthenticated: true,
          accessToken,
          accountId,
          email,
          useCase,
          role,
        }
        _save(newState)
        return updatedState(state, newState)
      }

      logMissingParams('Failed to login', [
        { name: 'access token', exists: Boolean(accessToken) },
        { name: 'account id', exists: Boolean(accountId) },
        { name: 'email', exists: Boolean(email) },
      ])
      return state
    }
    case AuthActionType.LOGOUT: {
      localStorage.clear()
      // save the email address so that we can prefill the email-a-link widget
      if (state?.email) {
        newState.email = state.email
      }
      _save(newState)
      // Ensure the window change waits for call
      setTimeout(() => (window.location.href = '/login')) // nosemgrep
      return updatedState(state, _load())
    }
    // log the user out without redirecting
    // currently only used on the signup page
    case AuthActionType.QUIET_LOGOUT: {
      localStorage.clear()

      _save(newState)
      return updatedState(state, _load())
    }
    case AuthActionType.REGISTER_LOGIN: {
      if (
        accessToken &&
        accountId &&
        email &&
        useCase !== undefined &&
        role !== undefined
      ) {
        newState = {
          ...state,
          accessToken,
          isAuthenticated: true,
          accountId,
          email,
          useCase,
          role,
        }
        _save(newState)
        return updatedState(state, newState)
      }

      logMissingParams('Failed to register user and login', [
        { name: 'account id', exists: Boolean(accountId) },
        { name: 'email', exists: Boolean(email) },
        { name: 'useCase', exists: useCase !== undefined },
        { name: 'role', exists: role !== undefined },
      ])
      return state
    }
    case AuthActionType.SET_EMAIL: {
      if (email) {
        newState = {
          ...state,
          isAuthenticated: false,
          email,
        }
        _save(newState)
        return updatedState(state, newState)
      }

      logMissingParams('Failed to set email', [
        { name: 'email', exists: Boolean(email) },
      ])
      return state
    }
    case AuthActionType.REFRESH_TOKEN: {
      if (accessToken && accountId && useCase !== undefined) {
        newState = {
          ...state,
          isAuthenticated: true,
          accessToken,
          accountId,
          useCase,
          role,
        }
        _save(newState)
        return updatedState(state, newState)
      }
      logMissingParams('Failed to refresh token', [
        { name: 'access token', exists: Boolean(accessToken) },
        { name: 'account id', exists: Boolean(accountId) },
        { name: 'useCase', exists: useCase !== undefined },
        { name: 'role', exists: role !== undefined },
      ])
      return state
    }
    default:
      throw new Error(`Unknown action type [${action.type}]`)
  }
}

export const AuthProvider = ({
  children,
}: {
  children: JSX.Element
}): JSX.Element => {
  const [authCtx, authDispatch] = useReducer(authReducer, initCtxOnLoad)

  return (
    <AuthContext.Provider value={{ authCtx, authDispatch }}>
      {children}
    </AuthContext.Provider>
  )
}

const authHeader = (accessToken: string): Metadata => {
  return accessToken ? { Authorization: `Bearer ${accessToken}` } : {}
}

export const useAuthHeader = (): Metadata => {
  const { authCtx } = useContext(AuthContext)
  return authHeader(authCtx.accessToken)
}

export const useAuthContext = (): AuthContextType => useContext(AuthContext)

export const useHandleSignupLogout = (): (() => void) => {
  const { authCtx } = useAuthContext()

  return useCallback(() => {
    authReducer(authCtx, {
      type: AuthActionType.QUIET_LOGOUT,
      useCase: USE_CASE.SAAS_BUYER,
      role: USER_ROLE.READONLY,
    })
  }, [authCtx])
}

export default AuthContext
