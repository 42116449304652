import { LoginProviders } from 'src/pages/Login/LoginPage.constants'
import queryString from 'query-string'
import {
  SIGNUP_REDIRECT_ROUTES,
  SignupTypes,
} from 'src/pages/SignUpPage/SignUpPage.constants'
import isFunction from 'lodash/isFunction'
import { isDev, isProduction, isStaging, isTesting } from './environment'

interface CognitoConfig {
  userPoolDomain: string
  appClientId: string
  redirectUri: string
  scopes: string
  provider: string
  state: string
}

type CognitoAuthBase = {
  provider: LoginProviders
  isSignup?: boolean
  signupType?: SignupTypes
}

type CognitoAuthOptional =
  | {
      isSignup: true
      track: (provider: string) => void
    }
  | {
      isSignup?: false
      track?: (provider: string) => void
    }

export type CognitoAuthGrant = CognitoAuthBase & CognitoAuthOptional

const CognitoVariables = Object.freeze({
  DEVELOPMENT: {
    userPoolDomain: 'local-trustero.auth.us-east-2.amazoncognito.com',
    appClientId: '9cl2v343gd1jlag4nt5s1m61h',
    redirectUri: 'http://localhost:6868/auth/oauth2/signin/cognito',
    scopes: 'openid+email+profile+aws.cognito.signin.user.admin',
    provider: '', // add the provider when logging in
    state: '', // add the state when logging in
  },
  TESTING: {
    userPoolDomain: 'testing-trustero.auth.us-east-2.amazoncognito.com',
    appClientId: '6jo8ekpqugabh1j127ld80333l',
    redirectUri:
      'https://testing.api.infra.trustero.com/auth/oauth2/signin/cognito',
    scopes: 'openid+email+profile+aws.cognito.signin.user.admin',
    provider: '',
    state: '',
  },
  STAGING: {
    userPoolDomain: 'staging-trustero.auth.us-east-2.amazoncognito.com',
    appClientId: 'mcke4p9ir1d1l7s95u9p8d7dr',
    redirectUri:
      'https://staging.api.infra.trustero.com/auth/oauth2/signin/cognito',
    scopes: 'openid+email+profile+aws.cognito.signin.user.admin',
    provider: '',
    state: '',
  },
  PRODUCTION: {
    userPoolDomain: 'trustero.auth.us-east-2.amazoncognito.com',
    appClientId: '4vv4i6133uqg9130t3vk7ticb',
    redirectUri:
      'https://prod.api.infra.trustero.com/auth/oauth2/signin/cognito',
    scopes: 'openid+email+profile+aws.cognito.signin.user.admin',
    provider: '',
    state: '',
  },
})

const LoginState = Object.freeze({
  LOGIN: Buffer.from('login').toString('base64'),
  SIGNUP: Buffer.from('signup').toString('base64'),
})

const getRedirectUrl = (signupType: SignupTypes): string =>
  `redirect_url=${SIGNUP_REDIRECT_ROUTES[signupType]}`

const getCognitoUrl = ({
  userPoolDomain,
  appClientId,
  redirectUri,
  scopes,
  provider,
  state,
}: CognitoConfig): string =>
  `https://${userPoolDomain}/oauth2/authorize?response_type=code&client_id=${appClientId}&redirect_uri=${redirectUri}&scopes=${scopes}&identity_provider=${provider}&state=${state}`

const getMicrosoftRedirectUrl = (isAzure: boolean): string => {
  const htmlFile = isAzure ? 'azure' : 'microsoft'

  return isTesting()
    ? `https%3A%2F%2Ftesting.api.infra.trustero.com%2F${htmlFile}.html`
    : isStaging()
    ? `https%3A%2F%2Fstaging.api.infra.trustero.com%2F${htmlFile}.html`
    : isProduction()
    ? `https%3A%2F%2Fprod.api.infra.trustero.com%2F${htmlFile}.html`
    : `http%3A%2F%2Flocalhost%3A6868%2F${htmlFile}.html`
}

export const msRedirect = (
  isAzure: boolean,
  signupType?: SignupTypes,
  track?: () => void,
): void => {
  if (signupType) {
    isFunction(track) && track()
  }

  let state = ''
  if (signupType) {
    state = `&state=${Buffer.from('signup').toString('base64')}`
    state += encodeURI(getRedirectUrl(signupType))
  }

  const msRedirectUrl = getMicrosoftRedirectUrl(isAzure)

  const url = isAzure
    ? `https://apptrustero.b2clogin.com/apptrustero.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1A_SIGNUP_SIGNIN&client_id=9c79d018-87bf-4b45-a9c6-818f57270198&nonce=defaultNonce&redirect_uri=${msRedirectUrl}&scope=openid&response_type=id_token&prompt=login${state}`
    : `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=c844af20-aabe-4bb1-9d6d-89c38039bcc9&nonce=defaultNonce&redirect_uri=${msRedirectUrl}&scope=openid email https://graph.microsoft.com/User.Read&response_type=id_token${state}`

  window.location.href = url
}

export const getProviderName = (domain: string): LoginProviders | null =>
  DomainToPingOneProvider[domain] || null

export const getCognitoAuthGrant = ({
  provider,
  isSignup,
  track,
  signupType,
}: CognitoAuthGrant): string => {
  let cognitoConfig: CognitoConfig = {
    userPoolDomain: '',
    appClientId: '',
    redirectUri: '',
    scopes: '',
    provider: '',
    state: '',
  }

  const state = isSignup ? LoginState.SIGNUP : LoginState.LOGIN

  if (isSignup) {
    // This will tell us if the user "Logged in" from the signup page
    track(provider)
  }

  if (isDev()) {
    // local dev case
    cognitoConfig = { ...CognitoVariables.DEVELOPMENT, provider, state }
  } else if (isTesting()) {
    // handle testing case
    cognitoConfig = { ...CognitoVariables.TESTING, provider, state }
  } else if (isStaging()) {
    // handle staging case
    cognitoConfig = { ...CognitoVariables.STAGING, provider, state }
  } else if (isProduction()) {
    // handle prod case
    cognitoConfig = { ...CognitoVariables.PRODUCTION, provider, state }
  }
  signupType && (cognitoConfig.state += getRedirectUrl(signupType))

  // Ensure any passed redirect_url is encoded properly
  // This is used for the hubspot knowledge base today to pass to our cognitoHandler service
  const searchParams = queryString.parse(window.location.search)
  const redirectUrl = searchParams.redirect_url
  if (redirectUrl)
    cognitoConfig.state = `redirect_url=${encodeURI(redirectUrl as string)}`

  return (window.location.href = getCognitoUrl(cognitoConfig))
}

const DomainToPingOneProvider = Object.freeze({
  'trustero.com': LoginProviders.PINGONE_TRUSTERO,
  'nafinc.com': LoginProviders.PINGONE_NAFINC,
}) as Record<string, LoginProviders>
