import { GoogleDrive } from 'src/components/Icons/Dependencies'
import styled from 'styled-components/macro'
import { compose, margin, textStyle } from 'styled-system'
import palette from 'src/designSystem/variables/palette'
import { TypeButton } from '../FileType/TypeSelector.styles'

export const DocumentDetailsContainer = styled.div.attrs({
  mb: 'l',
})`
  ${margin}
  position: relative;
`

export const Label = styled.label.attrs({
  mb: 'xxxs',
  textStyle: 'body.default',
})`
  ${compose(margin, textStyle)}
  display: flex;
  justify-content: space-between;
`

export const GoogleDriveIcon = styled(GoogleDrive)`
  width: 48px;
  height: 48px;
`

export const StyledTypeButton = styled(TypeButton)`
  margin: 0;
  cursor: pointer;
`

export const CitationPreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export const CitationPreviewQuestion = styled.div`
  font-weight: 300;
  font-size: 12px;
  color: ${palette.neutral[600]};
`

export const CitationPreviewAnswer = styled.div`
  font-weight: 400;
  font-size: 12px;
  color: ${palette.neutral[600]};
`
