import { useCallback, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import queryString, { ParsedQuery } from 'query-string'
import FileSaver from 'file-saver'
import log from 'loglevel'
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'
import {
  CreateVendorRequest,
  CreateVendorsRequest,
  DeleteVendorRequest,
  GetVendorRequest,
  ListVendorsRequest,
  ListVendorsResponse,
  UpdateVendorRequest,
  UpdateVendorsRequest,
  VendorRecord,
} from '@trustero/trustero-api-web/lib/vendormanagement/vendormanagement_pb'
import {
  RISK_LINK_TYPE,
  RiskLink,
} from '@trustero/trustero-api-web/lib/risk/risk_pb'
import {
  AddAttestationRequest,
  AddAttestationsRequest,
  AttestationRecord,
  Attestations,
  DeleteAttestationRequest,
  ListAttestationsRequest,
  UpdateAttestationRequest,
} from '@trustero/trustero-api-web/lib/vendormanagement/attestation_pb'
import { VendorPromiseClient } from '@trustero/trustero-api-web/lib/vendormanagement/vendormanagement_grpc_web_pb'
import { AttestationPromiseClient } from '@trustero/trustero-api-web/lib/vendormanagement/attestation_grpc_web_pb'
import { MODEL_TYPE } from '@trustero/trustero-api-web/lib/common/model_pb'
import { useAuthorizedGrpcClient } from 'src/adapter'
import { GrpcResponse, NewGrpcResponse } from 'src/components/async/hooks/types'
import { useSwrImmutableGrpc } from 'src/components/async/useSwrImmutableGrpc'
import { useGrpcRevalidateByMethod } from 'src/components'
import { FilterParam } from 'src/components/Reusable/IndexPage/FilterBar/FilterBar.types'
import { useConfirmationContext } from 'src/Confirmation'
import { useFetchDocumentBody } from 'src/components/async/document/useDocument'
import { useThrobberContext } from 'src/Throbber/ThrobberContext'
import { capitalizeFirstLetter } from 'src/Utils/globalHelpers'
import { showInfoToast } from 'src/Utils/helpers/toast'
import { useConfirmationModal } from 'src/components/ModalForms'
import { useToggleLinks } from '../Risks/risks.hooks'
import { applyVendorsFilters, applyVendorsSort } from './vendors.helpers'

export const useInvalidateVendorsCache = (): (() => Promise<void>) => {
  const mutateFunc = useGrpcRevalidateByMethod()

  return useCallback(async () => {
    try {
      await Promise.all([
        mutateFunc(VendorPromiseClient.prototype.listVendors),
        mutateFunc(VendorPromiseClient.prototype.getVendor),
        mutateFunc(AttestationPromiseClient.prototype.listAttestations),
      ])
    } catch (error) {
      log.error('Error when invalidating vendors cache', error)
    }
  }, [mutateFunc])
}

export const useVendorsRequest = (): ListVendorsRequest => {
  const location = useLocation()

  return useMemo(() => {
    const req = new ListVendorsRequest()
    const queryParams: ParsedQuery<string> = queryString.parse(
      location.search,
      {
        arrayFormat: 'bracket',
      },
    )
    // Apply all filters for each parameter in URL
    Object.values(FilterParam).forEach((filterType: FilterParam) =>
      applyVendorsFilters(req, queryParams, filterType),
    )
    applyVendorsSort(req, queryParams)
    return req
  }, [location.search])
}

export const useVendor = (
  vendorId: string,
  shouldFetch = true,
): GrpcResponse<VendorRecord> => {
  const request = new GetVendorRequest().setId(vendorId)
  const { response } = useSwrImmutableGrpc(
    VendorPromiseClient.prototype.getVendor,
    request,
    shouldFetch,
  )
  return NewGrpcResponse(response)
}

export const useVendors = (
  req: ListVendorsRequest = new ListVendorsRequest(),
  shouldFetch = true,
): GrpcResponse<ListVendorsResponse> => {
  const { response } = useSwrImmutableGrpc(
    VendorPromiseClient.prototype.listVendors,
    req,
    shouldFetch,
  )
  return NewGrpcResponse(response)
}

export const useBulkCreateVendors = (): ((
  requests: CreateVendorRequest[],
) => Promise<void>) => {
  const mutate = useInvalidateVendorsCache()
  const vendorClient = useAuthorizedGrpcClient(VendorPromiseClient)

  return async (requests: CreateVendorRequest[]) => {
    const request = new CreateVendorsRequest().setRequestsList(requests)
    await vendorClient.createVendors(request)
    await mutate()
  }
}

export const useUpdateVendors = (): ((
  requests: UpdateVendorRequest[],
) => Promise<void>) => {
  const mutate = useInvalidateVendorsCache()
  const vendorClient = useAuthorizedGrpcClient(VendorPromiseClient)

  return async (requests: UpdateVendorRequest[]) => {
    const updates = new UpdateVendorsRequest().setRequestsList(requests)
    await vendorClient.updateVendors(updates)
    await mutate()
  }
}

/**
 * Custom hook to delete a vendor.
 *
 * @returns A function that deletes a vendor.
 */
export const useDeleteVendor = (): ((id: string) => Promise<void>) => {
  const { setConfirmationState } = useConfirmationContext()
  const client = useAuthorizedGrpcClient(VendorPromiseClient)
  const mutate = useInvalidateVendorsCache()
  const { startThrobber, stopThrobber } = useThrobberContext()

  return async (id: string) => {
    setConfirmationState({
      isShown: true,
      size: 'lg',
      title: 'Are you sure you want to delete this vendor?',
      body: 'This will delete any attestations and links to risks.',
      confirmText: 'Delete Vendor',
      onConfirmCB: async () => {
        try {
          startThrobber()
          await client.deleteVendor(new DeleteVendorRequest().setId(id))
        } catch (err) {
          log.error('Error deleting vendor', err)
          setConfirmationState({
            isShown: false,
            title: '',
            body: '',
            confirmText: '',
            onConfirmCB: () => null,
          })
          showInfoToast(
            'There was an error deleting the vendor. Please try again.',
          )
        } finally {
          await mutate()
          stopThrobber()
        }
      },
    })
  }
}

export const useAttestations = (
  vendorId: string,
  shouldFetch = true,
): GrpcResponse<Attestations> => {
  const request = new ListAttestationsRequest().setVendorId(vendorId)
  const { response } = useSwrImmutableGrpc(
    AttestationPromiseClient.prototype.listAttestations,
    request,
    shouldFetch,
  )
  return NewGrpcResponse(response)
}

export const useAddAttestation = (): (({
  description,
  reportType,
  expirationDate,
  vendorId,
  vendorName,
  file,
}: {
  description: string
  reportType: string
  expirationDate: Date
  vendorId: string
  vendorName: string
  file: File
}) => Promise<void>) => {
  const client = useAuthorizedGrpcClient(AttestationPromiseClient)
  const mutate = useInvalidateVendorsCache()

  return async ({
    description,
    reportType,
    expirationDate,
    vendorId,
    vendorName,
    file,
  }) => {
    const attestation = new AttestationRecord()
      .setDescription(description)
      .setReportType(reportType)
      .setExpirationDate(Timestamp.fromDate(expirationDate))
      .setVendorId(vendorId)
      .setMime(file.type)
    const attestationReq = new AddAttestationRequest()
      .setAttestation(attestation)
      .setBody(new Uint8Array(await new Response(file).arrayBuffer()))
      .setVendorName(vendorName)
    const req = new AddAttestationsRequest().setRequestsList([attestationReq])
    await client.addAttestations(req)
    await mutate()
  }
}

/**
 * Custom hook to delete an attestation.
 *
 * @returns A function that deletes an attestation.
 */
export const useDeleteAttestation = (): ((id: string) => Promise<void>) => {
  const { setConfirmationState } = useConfirmationContext()
  const client = useAuthorizedGrpcClient(AttestationPromiseClient)
  const mutate = useInvalidateVendorsCache()
  const { startThrobber, stopThrobber } = useThrobberContext()

  return async (id: string) => {
    setConfirmationState({
      isShown: true,
      size: 'lg',
      title: 'Delete Attestation?',
      body: 'This will remove the attestation from this vendor.',
      confirmText: 'Delete',
      onConfirmCB: async () => {
        try {
          startThrobber()
          await client.deleteAttestation(
            new DeleteAttestationRequest().setId(id),
          )
        } catch (err) {
          log.error('Error deleting attestation', err)
          setConfirmationState({
            isShown: false,
            title: '',
            body: '',
            confirmText: '',
            onConfirmCB: () => null,
          })
          showInfoToast(
            'There was an error deleting the attestation. Please try again.',
          )
        } finally {
          await mutate()
          stopThrobber()
        }
      },
    })
  }
}

export const useDownloadAttestation = (
  contentId: string,
  description: string,
): (() => Promise<void>) => {
  const fetchBody = useFetchDocumentBody(contentId, 'pdf')

  return async () => {
    try {
      const body = await fetchBody()
      if (!(body instanceof Uint8Array)) {
        showInfoToast(
          'There was a problem downloading the attestation. Please try again.',
        )
        return
      }
      const blob = new Blob([body], {
        type: 'application/pdf',
      })
      FileSaver.saveAs(blob, `${description}.pdf`)
    } catch (err) {
      log.error('Error downloading attestation', err)
      showInfoToast(
        'There was an error downloading the attestation. Please try again.',
      )
    }
  }
}

export const useUnlinkVendorRiskModal = (
  vendorId: string,
  vendorName: string,
  riskId: string,
  riskCustomId: string,
  riskName: string,
  subjectType: MODEL_TYPE = MODEL_TYPE.VENDOR,
): (() => void) => {
  const unlinkFunc = useToggleLinks()
  const mutate = useInvalidateVendorsCache()
  const isVendorSubject = subjectType === MODEL_TYPE.VENDOR
  const subject = isVendorSubject ? 'vendor' : 'risk'
  const attachment = isVendorSubject ? 'risk' : 'vendor'
  const subjectId = isVendorSubject ? vendorId : riskId
  const riskText = `${riskCustomId} ${riskName}`
  const title = isVendorSubject
    ? `Unlink Risk from ${vendorName}?`
    : `Unlink ${vendorName} from this Risk?`
  const body = isVendorSubject
    ? `This will remove ${riskText}`
    : `This will remove ${vendorName} from ${riskText}`

  const unlinkVendor = useCallback(async () => {
    try {
      await unlinkFunc({
        type: RISK_LINK_TYPE.UNLINK,
        links: [
          new RiskLink()
            .setId(vendorId)
            .setModelType(MODEL_TYPE.VENDOR)
            .setRiskId(riskId),
        ],
      })
      await mutate()
    } catch (err) {
      log.error(
        `Error unlinking ${attachment} from ${subject} id ${subjectId}`,
        err,
      )
      showInfoToast(
        `There was an error unlinking the ${attachment} from the ${subject}. Please try again.`,
      )
    }
  }, [unlinkFunc, attachment, subject, subjectId, riskId, vendorId, mutate])

  const confirmationModalProps = {
    title: title,
    body: body,
    confirmText: `Unlink ${capitalizeFirstLetter(attachment)}`,
    onConfirmCB: unlinkVendor,
  }

  return useConfirmationModal(confirmationModalProps)
}

export type UpdateAttestation = {
  id: string
  description: string
  reportType: string
  expirationDate: Date
}

export const useUpdateAttestation = (): ((
  attestation: UpdateAttestation,
) => Promise<void>) => {
  const client = useAuthorizedGrpcClient(AttestationPromiseClient)
  const mutate = useInvalidateVendorsCache()

  return async ({ id, description, reportType, expirationDate }) => {
    const attestation = new AttestationRecord()
      .setDescription(description)
      .setReportType(reportType)
      .setExpirationDate(Timestamp.fromDate(expirationDate))
      .setId(id)
    const req = new UpdateAttestationRequest().setAttestation(attestation)
    await client.updateAttestation(req)
    await mutate()
  }
}
