import React, { FormEventHandler } from 'react'
import { faBan, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  NumberSelectedTextPlacement,
  SelectedCountSpan,
} from 'src/components/ModalForms/ModalForm.styles'
import { ModalFormId } from '../../ModalForms'
import { TextButton } from '../Buttons/TextButton'
import { Grid } from '../Grid'
import { Spinner } from '../../../Throbber'
import palette from '../../../designSystem/variables/palette'
import { TestIds } from '../../../Utils/testIds'
import { FlexAlign, FlexRow } from '../Flex'

const StyledTitle = styled.p`
  padding-bottom: 10px;
  font-size: 14px;
  color: ${palette.neutral[900]};
`

const GridContainer = styled.div<{
  $noScroll?: boolean
  $fullHeight?: boolean
}>`
  ${({ $noScroll }) =>
    $noScroll
      ? ``
      : `max-height: 350px;
  overflow-y: auto;`}
  width: 100%;
  margin-top: 25px;
  ${({ $fullHeight }) => $fullHeight && `height: 100vh;`}
`

const ItemGrid = styled(Grid)<{
  $gridRowGap?: number
  $gridAutoFlow?: 'row' | 'column'
}>`
  width: 100%;
  ${({ $gridRowGap }) =>
    $gridRowGap !== undefined && `grid-row-gap: ${$gridRowGap}px;`}
  ${({ $gridAutoFlow }) =>
    $gridAutoFlow !== undefined && `grid-auto-flow: ${$gridAutoFlow};`}
`

const Icon = styled(FontAwesomeIcon)`
  color: ${palette.neutral[600]};
  margin-right: 0px;
`

export type DependentMultiSelectFormProps =
  | {
      showSelectButtons?: false
      handleSelectAll?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
      handleDeselectAll?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
      customSelectButtons?: JSX.Element
    }
  | {
      showSelectButtons: true
      handleSelectAll: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
      handleDeselectAll: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
      customSelectButtons?: JSX.Element
    }

interface CommonMultiSelectFormProps {
  loading: boolean
  formId: ModalFormId
  title?: string
  gridItems: JSX.Element | JSX.Element[]
  showSelectButtons?: boolean
  onSubmit?: FormEventHandler<HTMLFormElement>
  gridTemplateColumnStyling: string
  numberSelectedText?: string
  numberSelectedTextPlacement?: NumberSelectedTextPlacement
  showEmpty?: boolean
  emptyState?: JSX.Element
  noScroll?: boolean
  fullHeight?: boolean
  gridRowGap?: number
  gridAutoFlow?: 'row' | 'column'
  filters?: React.ReactNode
  headers?: React.ReactNode
  footer?: React.ReactNode
}

export type MultiSelectFormProps = DependentMultiSelectFormProps &
  CommonMultiSelectFormProps

export const MultiSelectForm = ({
  loading,
  formId,
  title,
  showSelectButtons,
  handleSelectAll,
  handleDeselectAll,
  gridItems,
  onSubmit,
  gridTemplateColumnStyling,
  showEmpty,
  emptyState,
  numberSelectedText,
  customSelectButtons,
  noScroll,
  numberSelectedTextPlacement = 'bottom',
  gridRowGap,
  gridAutoFlow,
  filters,
  fullHeight,
  headers,
  footer,
}: MultiSelectFormProps): JSX.Element =>
  loading ? (
    <FlexRow justify={FlexAlign.CENTER} $padding={32}>
      <Spinner color="primary" size="xl" data-testid={TestIds.SPINNER} />
    </FlexRow>
  ) : (
    <form id={formId} onSubmit={onSubmit}>
      {!!title && <StyledTitle>{title}</StyledTitle>}
      {showSelectButtons && (
        <FlexRow justify={FlexAlign.FLEX_START}>
          <TextButton
            onClick={handleDeselectAll}
            style={{ marginRight: '16px' }}
          >
            <Icon icon={faBan} />
            <>Deselect All</>
          </TextButton>
          <TextButton onClick={handleSelectAll}>
            <Icon icon={faPlusCircle} />
            <>Select All</>
          </TextButton>
          {numberSelectedTextPlacement === 'top' && numberSelectedText && (
            <SelectedCountSpan $placement={numberSelectedTextPlacement}>
              {numberSelectedText}
            </SelectedCountSpan>
          )}
          {customSelectButtons}
        </FlexRow>
      )}
      {numberSelectedText &&
        (!showSelectButtons || numberSelectedTextPlacement === 'bottom') && (
          <SelectedCountSpan $placement={numberSelectedTextPlacement}>
            {numberSelectedText}
          </SelectedCountSpan>
        )}
      {filters}
      {showEmpty ? (
        emptyState
      ) : (
        <GridContainer $noScroll={noScroll} $fullHeight={fullHeight}>
          <ItemGrid
            gridTemplateColumns={gridTemplateColumnStyling}
            $gridRowGap={gridRowGap}
            $gridAutoFlow={gridAutoFlow}
          >
            {headers}
            {gridItems}
          </ItemGrid>
          {footer}
        </GridContainer>
      )}
    </form>
  )

MultiSelectForm.defaultProps = {
  gridTemplateColumnStyling: 'min-content min-content 1fr',
}
