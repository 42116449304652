import { toast, ToastContent, ToastOptions } from 'react-toastify'

export const ToastPrompts = Object.freeze({
  SOMETHING_WRONG: 'Something went wrong, please refresh and try again.',
  NO_CONTROL_EXISTS: 'This control does not exist.',
  CONTROL_OUTSIDE_AUDIT: (auditName: string) =>
    `This control is not part of the audit ${auditName || ''}`,
  LINK_CONTROL_TO_REQUEST: 'Linking controls failed. Please try again.',
  LINK_REQUEST_TO_CONTROL: 'Linking requests failed. Please try again.',
  EVIDENCE_UPLOAD_FAILED: 'Evidence upload failed',
  POLICY_DOC_UPLOAD_FAILED: 'Policy document upload failed',
  POLICY_ACKNOWLEDGEMENT_FORM_ERROR:
    'Error generating policy acknowledgement form',
  POLICY_DOC_DOWNLOAD_ERROR:
    'There was a problem downloading the policy document file.',
  BUYER_UPDATE_FAILED:
    'There was a problem updating your account. Please try again.',
  DOC_DOWNLOAD_ERROR: 'There was a problem downloading the document.',
  EVIDENCE_DOWNLOAD_ERROR: 'There was a problem downloading the evidence.',
  DOC_DELETE_ERROR: 'There was a problem deleting the document.',
  REPORT_DELETE_FAILED: 'There was a problem deleting the report.',
  REPORT_DOWNLOAD_FAILED: 'There was a problem downloading the report.',
  AUDITBOT_SCAN_ERROR:
    'There was a problem examinining and testing the controls.',
  AUDITBOT_DELETE_FAILED:
    'There was a problem deleting the AI Examination and Test result.',
  AUDITBOT_CANCEL_FAILED:
    'There was a problem cancelling the AI Examination and Test process.',
  READ_ONLY_ACCOUNT: 'This is a Read Only Demo account.',
  GENERATE_SYSTEM_DESCRIPTION_ERROR: 'Error generating system description',
  COPIED_ANSWER: 'Copied answer to clipboard',
})

const DEFAULT_TOAST_CONFIG: ToastOptions = {
  position: 'bottom-center',
  autoClose: 5000,
}

export const showInfoToast = (
  content: ToastContent,
  config?: ToastOptions,
): void => {
  const options = config
    ? { ...DEFAULT_TOAST_CONFIG, ...config }
    : DEFAULT_TOAST_CONFIG
  toast.info(content, options)
}
