import * as CSV from 'csv-string'
import log from 'loglevel'
import FileSaver from 'file-saver'
import {
  Excluded,
  ServiceDiscovery,
} from '@trustero/trustero-api-web/lib/attachment/attachment_pb'
import { Document } from '@trustero/trustero-api-web/lib/attachment/attachment_pb'
import {
  Evidence,
  Struct,
} from '@trustero/trustero-api-web/lib/receptor_v1/receptor_pb'
import { TDocumentDefinitions } from 'pdfmake/interfaces'
import { getServiceTemplate } from 'src/xgenerated/service'
import { isJSON } from 'src/Utils/globalHelpers'
import { EvidenceTable } from 'src/Utils/Evidence/evidence.types'
import {
  evidenceToMarkdown,
  getEvidenceSources,
  tabulate,
} from 'src/Utils/Evidence/evidence.helpers'
import * as pdfMake from 'pdfmake/build/pdfmake'
import * as pdfFonts from 'pdfmake/build/vfs_fonts'
import { MIME_TYPE } from 'src/Utils/globalEnums'
import { ModalFormIdQueryParam } from '../../ModalForm'

export const ViewEvidenceQueryParams = {
  MODAL_FORM_ID: ModalFormIdQueryParam,
  EVIDENCE_ID: 'evidenceId',
}

export const ExclusionsToMarkdown = (
  exclusions: Excluded[],
  getServiceNameById: (id: string) => string,
): string => {
  const toMarkdown = (p: Excluded.AsObject) =>
    [
      '<br>',
      '<br>',
      `## ${getServiceNameById(p.serviceId)}`,
      '\n',
      `${p.instanceNamesList.map((q) => ` * ${q}`).join('\n')}`,
    ].join('\n')

  const exclusionsMarkdown = [
    '# Out of scope',
    '\n',
    'The following entities are out of scope and were filtered out of the evidence document:',
    exclusions.length > 0
      ? exclusions.map((exclusion) => exclusion.toObject()).map(toMarkdown)
      : 'None',
  ].join('\n')

  return [exclusionsMarkdown].join('\n')
}

const parseAPIReponse = (apiResponse: string) => {
  try {
    apiResponse = apiResponse.replace(/\\n/g, '\\n')
    return JSON.stringify(JSON.parse(apiResponse), null, 2)
  } catch (err) {
    log.error('error when parsing discovery', err, apiResponse)
    return apiResponse
  }
}

export const DiscoveriesToMarkdown = (
  discoveries: ServiceDiscovery[],
): string => {
  const toMarkdown = (p: ServiceDiscovery.AsObject) =>
    [
      '## API Call',
      '\n',
      p.apiCallsList.map((call) => ` * ${call}`).join('\n'),
      '\n',
      '## API Response',
      '\n',
      '```',
      isJSON(p.discovery) ? parseAPIReponse(p.discovery) : p.discovery,
      '```',
    ].join('\n')

  return [
    'These are the API calls that were made to collect the evidence and the responses that were received:',
    '<br>',
    '<br>',
    ...discoveries.map((p) => p.toObject()).map(toMarkdown),
  ].join('\n')
}

export const generatePdfBlob = (
  content: TDocumentDefinitions,
): Promise<Blob> => {
  return new Promise((resolve, reject) => {
    pdfMake
      .createPdf(
        content,
        undefined,
        undefined,
        pdfFonts as unknown as { [file: string]: string },
      )
      .getBlob((blob: Blob) => {
        resolve(blob)
      }),
      (error: Error) => {
        reject(error)
      }
  })
}

export const getColumnWidths = (table: EvidenceTable): string[] => {
  const widths: string[] = []
  table.headers.forEach(() => {
    widths.push(`${100 / table.headers.length}%`)
  })
  return widths
}

/**
 * @deprecated
 * use generateEvidencePdfWithExclusions instead
 */
export const generateEvidencePdf = async (
  document: Document,
  body: Uint8Array | string,
  caption: string,
): Promise<TDocumentDefinitions> => {
  const evidence = Evidence.deserializeBinary(body as Uint8Array) // should always be Uint8Array from ContentStore
  const evidenceSources = getEvidenceSources(evidence)
  const apiSections = evidenceSources.map((source) => [
    {
      text: 'API Call',
      style: 'subHeading',
    },
    {
      text: source.getApiCallsList().join('\n'),
      style: 'body',
    },
    {
      text: 'API Response',
      style: 'subHeading',
    },
    {
      text: JSON.stringify(JSON.parse(source.getDiscovery() || '{}'), null, 2),
      preserveLeadingSpaces: true,
      style: 'json',
    },
  ])
  const evidenceStruct: Struct = evidence.getStruct() ?? new Struct()
  const noEvidence = evidenceStruct.getRowsList().length === 0
  const noEvidenceMessage = getNoEvidenceMessage(evidence)
  const evidenceTable = tabulate(evidenceStruct)
  const exclusions = ExclusionsToMarkdown(
    document.getAllexclusions()?.getExclusionsList() || [],
    (id: string) => getServiceTemplate(id)?.name || 'Unknown Service',
  )
  const evidenceSection = noEvidence
    ? { text: [noEvidenceMessage], style: 'body' }
    : {
        table: {
          widths: getColumnWidths(evidenceTable),
          body: [
            evidenceTable.headers,
            ...evidenceTable.body.map((row) =>
              row.map((cell) => cell.replaceAll(':heavy_check_mark:', 'Y')),
            ),
          ],
          headerRows: 1,
          layout: 'fit',
        },
        style: 'table',
      }

  return {
    content: [
      { text: caption, style: 'title' },
      { text: 'Evidence', style: 'heading' },
      evidenceSection,
      { text: 'Exclusions', style: 'heading' },
      { text: [exclusions], style: 'body' },
      { text: 'Sources', style: 'heading' },
      ...apiSections,
    ],
    styles: {
      title: { fontSize: 18, bold: true, margin: [0, 0, 0, 10] },
      heading: { fontSize: 16, bold: true, margin: [0, 10, 0, 5] },
      subHeading: { fontSize: 12, bold: true, margin: [0, 5, 0, 5] },
      body: { fontSize: 10, margin: [0, 0, 0, 10] },
      table: { fontSize: 10, margin: [0, 0, 0, 10] },
      json: {
        fontSize: 10,
        margin: [0, 0, 0, 10],
      },
    },
  }
}

export const handleOnClick = (event: React.MouseEvent<HTMLElement>): void => {
  event.stopPropagation()
  const target = event.target as Element
  target.getAttribute('href')
    ? target.setAttribute('target', '_blank')
    : event.preventDefault()
}

const generateEvidenceCSV = (evidenceTable: EvidenceTable): string => {
  const formattedHeaders = [evidenceTable.headers.join(',')]
  const csvRows = formattedHeaders
    .concat(CSV.stringify(evidenceTable.body))
    .join('\r\n')
  return csvRows.replaceAll(':heavy_check_mark:', '\u2713')
}

export const getNoEvidenceMessage = (evidence: Evidence): string =>
  `No instances found for ${evidence.getCaption()}`

export const formatEvidence = ({
  evidence,
  asCsv,
}: {
  evidence: Evidence
  asCsv?: boolean
}): string => {
  const evidenceStruct: Struct = evidence.getStruct() ?? new Struct()
  const noEvidence = evidenceStruct.getRowsList().length === 0
  const noEvidenceMessage = getNoEvidenceMessage(evidence)
  if (noEvidence) return noEvidenceMessage
  const evidenceTable = tabulate(evidenceStruct)
  return asCsv
    ? generateEvidenceCSV(evidenceTable)
    : evidenceToMarkdown(evidenceTable)
}

export const downloadEvidenceTable = (evidenceBody: Uint8Array): void => {
  const evidence: Evidence = Evidence.deserializeBinary(evidenceBody)
  const formattedEvidence = formatEvidence({ evidence, asCsv: true })
  const table = new Blob([formattedEvidence], {
    type: 'text/csv;charset=utf-8;',
  })
  FileSaver.saveAs(table, `${evidence.getCaption()}.csv`)
}

export const isImage = (type: string): boolean => type.startsWith('image/')
export const isPdf = (type: string): boolean =>
  type === MIME_TYPE.APPLICATION_PDF
export const isHtml = (type: string): boolean => type === MIME_TYPE.TEXT_HTML
export const isCsv = (type: string): boolean => type === MIME_TYPE.TEXT_CSV
export const isExcel = (type: string): boolean =>
  type === MIME_TYPE.APPLICATION_EXCEL ||
  type === MIME_TYPE.APPLICATION_VND_MS_EXCEL ||
  type === MIME_TYPE.APPLICATION_SPREADSHEET

export const isViewable = (type: string): boolean =>
  isImage(type) || isPdf(type) || isHtml(type) || isCsv(type) || isExcel(type)

export const getFileType = (
  type: string,
): {
  isImage: boolean
  isPdf: boolean
  isHtml: boolean
  isCsv: boolean
  isExcel: boolean
} => {
  return {
    isImage: isImage(type),
    isPdf: isPdf(type),
    isHtml: isHtml(type),
    isCsv: isCsv(type),
    isExcel: isExcel(type),
  }
}
