import { useCallback, useEffect, useMemo } from 'react'
import pluralize from 'pluralize'
import {
  Controls,
  ListControlsRequest,
} from '@trustero/trustero-api-web/lib/model/control_pb'
import {
  ListPoliciesRequest,
  PolicyRecords,
} from '@trustero/trustero-api-web/lib/model/policy_pb'
import { ChatContextServicePromiseClient } from '@trustero/trustero-api-web/lib/chatcontext/chatcontext_grpc_web_pb'
import {
  ChatContextSourceEvidenceFor,
  ChatContextSourceType,
  ControlIds,
  GetSourcesTotalsRequest,
  GetSourcesTotalsResponse,
} from '@trustero/trustero-api-web/lib/chatcontext/chatcontext_pb'
import { useSwrImmutableGrpc } from 'src/components/async/useSwrImmutableGrpc'
import { NewGrpcResponse } from 'src/components/async/hooks/types'
import { useControls } from 'src/components/async/model/control'
import { usePolicies } from 'src/components/async/policy'
import { useGetKnowledgeBases } from 'src/pages/KnowledgeBase/knowledgeBase.hooks'
import { GetKnowledgeBasesResponse } from '@trustero/trustero-api-web/lib/questionnaire/questionnaire_pb'
import { GrpcResponse } from 'src/components/async/hooks/types'
import { useAuthorizedGrpcClient } from 'src/adapter/grpcClient'
import { getUpdateChatContextRequest } from './chatContext.utils'

export type ChatContextType =
  | ChatContextSourceType.KNOWLEDGE_BASE
  | ChatContextSourceType.POLICY
  | ChatContextSourceType.CONTROL
  | ChatContextSourceType.EVIDENCE
  | ChatContextSourceType.COMPANY
  | ChatContextSourceType.DOCUMENT_GROUP

export const ChatContextTypes: ChatContextType[] = [
  ChatContextSourceType.KNOWLEDGE_BASE,
  ChatContextSourceType.POLICY,
  ChatContextSourceType.CONTROL,
  ChatContextSourceType.EVIDENCE,
  ChatContextSourceType.COMPANY,
  ChatContextSourceType.DOCUMENT_GROUP,
]

export type ChatContextSelection = {
  includeAll: boolean
  included?: Set<string>
  type?: Record<string, number>
}

export type ChatContext = {
  [K in ChatContextType]: ChatContextSelection
}

export const useChatContextPolicies = (): GrpcResponse<PolicyRecords> => {
  const listPolicies = useMemo(() => new ListPoliciesRequest(), [])
  return usePolicies(listPolicies)
}

export const useChatContextControls = (): GrpcResponse<Controls> => {
  const listControls = useMemo(() => new ListControlsRequest(), [])
  return useControls(listControls)
}

export const useChatContextKnowledgeBases =
  (): GrpcResponse<GetKnowledgeBasesResponse> => {
    return useGetKnowledgeBases()
  }

export const useUpdateChatContext = (): ((
  chatContextId: string,
  context: ChatContext,
) => Promise<void>) => {
  const client = useAuthorizedGrpcClient(ChatContextServicePromiseClient)

  return useCallback(
    async (chatContextId: string, context: ChatContext) => {
      const request = getUpdateChatContextRequest(chatContextId, context)
      await client.updateChatContext(request)
    },
    [client],
  )
}

const useGetSourcesTotals = ({
  chatContextId,
  context,
}: {
  chatContextId?: string
  context: ChatContext
}): GrpcResponse<GetSourcesTotalsResponse> => {
  const request = useMemo(() => {
    if (chatContextId) {
      return new GetSourcesTotalsRequest().setChatContextId(chatContextId ?? '')
    } else {
      const controlIds = Object.keys(
        context[ChatContextSourceType.EVIDENCE].type ?? {},
      ).filter(
        (id) =>
          context[ChatContextSourceType.EVIDENCE].type?.[id] ===
          ChatContextSourceEvidenceFor.FOR_CONTROL,
      )
      return new GetSourcesTotalsRequest().setControlIds(
        new ControlIds().setIdsList(controlIds),
      )
    }
  }, [chatContextId, context])

  const shouldFetch = true

  const { response } = useSwrImmutableGrpc(
    ChatContextServicePromiseClient.prototype.getSourcesTotals,
    request,
    shouldFetch,
  )

  return NewGrpcResponse(response)
}

export type GetSummaryProps = {
  context: ChatContext
  chatContextId?: string
  shortFormat?: boolean
}

export const useGetSummary = ({
  context,
  chatContextId,
  shortFormat,
}: GetSummaryProps): Record<ChatContextType, string | string[]> => {
  const {
    data: sourcesTotals,
    isLoading,
    mutate,
  } = useGetSourcesTotals({ chatContextId, context })

  const kbSummary = useGetKbSummary(context, sourcesTotals, isLoading)
  const policySummary = useGetPolicySummary(context, sourcesTotals, isLoading)
  const controlSummary = useGetControlSummary(context, sourcesTotals, isLoading)
  const evidenceSummary = useGetEvidenceSummary(
    context,
    sourcesTotals,
    isLoading,
    shortFormat,
  )
  const companySummary = useGetCompanySummary(context)
  const documentGroupSummary = useGetDocumentGroupSummary(
    context,
    sourcesTotals,
    isLoading,
  )

  useEffect(() => {
    mutate()
  }, [context, mutate])

  return useMemo(
    () => ({
      [ChatContextSourceType.KNOWLEDGE_BASE]: kbSummary,
      [ChatContextSourceType.POLICY]: policySummary,
      [ChatContextSourceType.CONTROL]: controlSummary,
      [ChatContextSourceType.EVIDENCE]: evidenceSummary,
      [ChatContextSourceType.COMPANY]: companySummary,
      [ChatContextSourceType.DOCUMENT_GROUP]: documentGroupSummary,
    }),
    [
      kbSummary,
      policySummary,
      controlSummary,
      evidenceSummary,
      companySummary,
      documentGroupSummary,
    ],
  )
}

export const useGetKbSummary = (
  context: ChatContext,
  sourcesTotals: GetSourcesTotalsResponse | undefined,
  isLoading: boolean,
): string => {
  return useMemo(() => {
    const total = sourcesTotals
      ?.getTotalsList()
      .find((total) => total.getType() === ChatContextSourceType.KNOWLEDGE_BASE)
      ?.getTotal()
    const includedSize =
      context[ChatContextSourceType.KNOWLEDGE_BASE].included?.size
    const includeAll = context[ChatContextSourceType.KNOWLEDGE_BASE].includeAll
    return getSummaryString(includeAll, includedSize, total, isLoading)
  }, [sourcesTotals, isLoading, context])
}

export const useGetPolicySummary = (
  context: ChatContext,
  sourcesTotals: GetSourcesTotalsResponse | undefined,
  isLoading: boolean,
): string => {
  return useMemo(() => {
    const total = sourcesTotals
      ?.getTotalsList()
      .find((total) => total.getType() === ChatContextSourceType.POLICY)
      ?.getTotal()
    const includedSize = context[ChatContextSourceType.POLICY].included?.size
    const includeAll = context[ChatContextSourceType.POLICY].includeAll
    return getSummaryString(includeAll, includedSize, total, isLoading)
  }, [sourcesTotals, isLoading, context])
}

export const useGetControlSummary = (
  context: ChatContext,
  sourcesTotals: GetSourcesTotalsResponse | undefined,
  isLoading: boolean,
): string => {
  return useMemo(() => {
    const total = sourcesTotals
      ?.getTotalsList()
      .find((total) => total.getType() === ChatContextSourceType.CONTROL)
      ?.getTotal()
    const includedSize = context[ChatContextSourceType.CONTROL].included?.size
    const includeAll = context[ChatContextSourceType.CONTROL].includeAll
    return getSummaryString(includeAll, includedSize, total, isLoading)
  }, [sourcesTotals, isLoading, context])
}

export const useGetDocumentGroupSummary = (
  context: ChatContext,
  sourcesTotals: GetSourcesTotalsResponse | undefined,
  isLoading: boolean,
): string => {
  return useMemo(() => {
    const total = sourcesTotals
      ?.getTotalsList()
      .find((total) => total.getType() === ChatContextSourceType.DOCUMENT_GROUP)
      ?.getTotal()
    const includedSize =
      context[ChatContextSourceType.DOCUMENT_GROUP].included?.size
    const includeAll = context[ChatContextSourceType.DOCUMENT_GROUP].includeAll
    return getSummaryString(includeAll, includedSize, total, isLoading)
  }, [sourcesTotals, isLoading, context])
}

export const useGetEvidenceSummary = (
  context: ChatContext,
  sourcesTotals: GetSourcesTotalsResponse | undefined,
  isLoading: boolean,
  shortFormat?: boolean,
): string | string[] => {
  return useMemo(() => {
    if (isLoading) {
      return 'Loading...'
    }

    const { controls, individual } = getEvidenceCategoryCount(
      context[ChatContextSourceType.EVIDENCE],
    )

    const includeAll = context[ChatContextSourceType.EVIDENCE].includeAll

    const summary = []

    if (controls && !includeAll) {
      summary.push(pluralize('control', controls, true))
      if (!shortFormat) {
        const evidenceForControls =
          sourcesTotals?.getTotalEvidenceForControls() ?? 0
        const formatted = new Intl.NumberFormat('en-US').format(
          evidenceForControls,
        )
        summary.push(
          `covering ${formatted} ${pluralize(
            'piece',
            evidenceForControls,
          )} of evidence`,
        )
      }
    }

    if (individual) {
      summary.push(
        `${individual} distinct ${pluralize(
          'piece',
          individual,
          false,
        )} of evidence included`,
      )
    }

    if (!summary.length) {
      if (includeAll) {
        summary.push('All included')
      } else {
        summary.push('Not included')
      }
    }

    return summary
  }, [sourcesTotals, isLoading, context, shortFormat])
}

export const useGetCompanySummary = (context: ChatContext): string => {
  return useMemo(() => {
    const includeAll = context[ChatContextSourceType.COMPANY].includeAll
    return getSummaryString(includeAll, undefined, undefined, false)
  }, [context])
}

const getSummaryString = (
  includeAll: boolean,
  includedSize?: number,
  total?: number,
  isLoading?: boolean,
) => {
  if (includeAll) return 'All included'
  if (!includedSize) return 'Not included'
  if (isLoading || total === undefined) return 'Loading...'
  if (total === 0) return 'No data to include'
  return `${includedSize} included of ${total}`
}

export const getEvidenceCategoryCount = (
  selection: ChatContextSelection,
): { controls: number; individual: number } => {
  const { controls, individual } = Object.values(selection.type ?? {}).reduce(
    (acc, value) => {
      if (value === ChatContextSourceEvidenceFor.FOR_CONTROL) {
        acc.controls++
      } else if (value === ChatContextSourceEvidenceFor.FOR_SELF) {
        acc.individual++
      }
      return acc
    },
    { controls: 0, individual: 0 },
  )

  return { controls, individual }
}
