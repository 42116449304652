import log from 'loglevel'
import {
  GetOrCreateComplianceRoadmapResponse,
  GetRiskResponse,
  GetRoadmapWidgetCountsResponse,
  GetScopeResponse,
} from '@trustero/trustero-api-web/lib/roadmap/roadmap_pb'
import {
  AUDIT_RESULT,
  AuditReadinessRecord,
  AuditReadinessTestRecord,
  GetAuditReadinessResponse,
  GetSmartChecksTestInformationResponse,
  SmartCheckRecord,
  SmartControlTestRecord,
} from '@trustero/trustero-api-web/lib/audit/auditbot_pb'
import { getPercentageRoundingDown } from 'src/Utils/globalHelpers'
import { getCheckResult } from '../AuditBot/accordion/subsection/ControlChecks/AuditBotControlChecks.helpers'
import {
  FRACTION_ELEMENTS,
  IMPLEMENTATION_CHECKS,
  RoadmapToggles,
  RoadmapIndexRowType,
  RoadmapSteps,
  SmartCheckCounts,
  RoadmapIndexConfig,
  OPERATING_EFFECTIVENESS_CHECKS,
} from './roadmap.constants'

// Returns the current step in the roadmap or true if the roadmap journey is complete
export const getCurrentStep = (
  config: RoadmapIndexConfig,
): RoadmapSteps | true => {
  if (config[RoadmapSteps.Scope].percentage < 100) {
    return RoadmapSteps.Scope
  } else if (config[RoadmapSteps.RiskAssessment].percentage < 100) {
    return RoadmapSteps.RiskAssessment
  } else if (config[RoadmapSteps.Design].percentage < 100) {
    return RoadmapSteps.Design
  } else if (config[RoadmapSteps.Implementation].percentage < 100) {
    return RoadmapSteps.Implementation
  } else if (config[RoadmapSteps.HandleRequests].percentage < 100) {
    return RoadmapSteps.HandleRequests
  } else if (config[RoadmapSteps.OperatingEffectiveness].percentage < 100) {
    return RoadmapSteps.OperatingEffectiveness
  } else {
    return true
  }
}

export const getScopePercentage = (
  data?: GetScopeResponse,
  hasSoc2?: boolean,
): number => {
  if (!data) return -1
  let numerator = 0
  let denominator = Object.keys(data.toObject()).length - 1 // correct for soc 2 only field

  data.getReceptors() && numerator++
  data.getServices() && numerator++
  data.getCompanyName() && numerator++
  data.getCompanyWebsite() && numerator++
  data.getCompanySize() && numerator++
  data.getIndustry() && numerator++
  data.getCountry() && numerator++
  data.getTeamLocation() && numerator++
  data.getAddress() && numerator++
  data.getDeviceOwner() && numerator++
  data.getDeviceType() && numerator++
  data.getProvidersIdentified() && numerator++
  data.getServiceRolesFulfilled() && numerator++
  data.getFrameworks() && numerator++
  data.getAudits() && numerator++
  data.getArchDiagram() && numerator++
  data.getOrgChart() && numerator++
  if (denominator === 0) return 0

  // account for soc 2 only field
  if (hasSoc2) {
    denominator++
    data.getSystemDesc() && numerator++
  }

  return getPercentageRoundingDown(numerator, denominator)
}

export const getRoadmapWidgetFractionElements = (
  data: GetRoadmapWidgetCountsResponse,
): FRACTION_ELEMENTS => {
  let numerator = 0
  let denominator = 0
  const response = data.toObject()
  const values = Object.values(response)
  values.forEach((value) => {
    if (!value) return
    numerator += value.finished
    denominator += value.total
  })

  return {
    numerator,
    denominator,
  }
}

export const handleRoadmapErrors = ({
  auditId,
  widgetError,
  designError,
  implementationError,
  handleRequestsError,
  operatingEffectivenessError,
  roadmapToggleError,
  smartCheckError,
  auditReadinessError,
  auditError,
  riskError,
}: {
  auditId?: string
  widgetError?: Error
  designError?: Error
  implementationError?: Error
  handleRequestsError?: Error
  operatingEffectivenessError?: Error
  roadmapToggleError?: Error
  smartCheckError?: Error
  auditReadinessError?: Error
  auditError?: Error
  riskError?: Error
}): void => {
  if (!auditId) return
  widgetError &&
    log.error(
      `Error getting roadmap widget data for audit id: ${auditId}`,
      widgetError,
    )
  designError &&
    log.error(`Error getting design data for audit id: ${auditId}`, designError)
  implementationError &&
    log.error(
      `Error getting implementation data for audit id: ${auditId}`,
      implementationError,
    )
  handleRequestsError &&
    log.error(
      `Error getting handle requests data for audit id: ${auditId}`,
      handleRequestsError,
    )
  operatingEffectivenessError &&
    log.error(
      `Error getting operating effectiveness data for audit id: ${auditId}`,
      operatingEffectivenessError,
    )
  roadmapToggleError &&
    log.error(
      `Error getting roadmap toggle data for audit id: ${auditId}`,
      roadmapToggleError,
    )
  smartCheckError &&
    log.error(
      `Error getting smart check data for audit id: ${auditId}`,
      smartCheckError,
    )
  auditReadinessError &&
    log.error(
      `Error getting audit readiness data for audit id: ${auditId}`,
      auditReadinessError,
    )
  auditError &&
    log.error(`Error getting audit data for audit id: ${auditId}`, auditError)
  riskError &&
    log.error(`Error getting risk data for audit id: ${auditId}`, riskError)
}

export const getRoadmapStepPercentage = ({
  checks,
  widgetData,
  roadmapToggleData,
  smartChecksData,
  auditReadinessData,
  includeSmartChecks = false,
  includeAuditReadiness = false,
}: {
  checks: RoadmapToggles[]
  widgetData?: GetRoadmapWidgetCountsResponse
  roadmapToggleData?: GetOrCreateComplianceRoadmapResponse
  smartChecksData?: GetSmartChecksTestInformationResponse
  auditReadinessData?: GetAuditReadinessResponse
  includeSmartChecks?: boolean
  includeAuditReadiness?: boolean
}): number => {
  if (
    !widgetData ||
    !roadmapToggleData ||
    (includeSmartChecks && !smartChecksData)
  )
    return -1
  let numerator = 0
  let denominator = 0
  const { numerator: widgetNumerator, denominator: widgetDenominator } =
    getRoadmapWidgetFractionElements(widgetData)
  numerator += widgetNumerator
  denominator += widgetDenominator
  const checkboxInfo = roadmapToggleData.getComplianceRoadmap()
  if (checkboxInfo) {
    denominator += checks.length
    for (const check of checks) {
      switch (check) {
        case RoadmapToggles.PoliciesApproved:
          checkboxInfo.getPoliciesApproved() && numerator++
          break
        case RoadmapToggles.BusinessContinuityContingency:
          checkboxInfo.getBusinessContinuityContingency() && numerator++
          break
        case RoadmapToggles.SupportingDocumentsSop:
          checkboxInfo.getHasSupportingDocumentsSop() && numerator++
          break
      }
    }
  }
  if (includeSmartChecks && smartChecksData) {
    const smartChecks = smartChecksData.getSmartChecksList()
    smartChecks.forEach((smartCheck: SmartCheckRecord) => {
      const tests = smartCheck.getTestControlsList()
      denominator += tests.length
      tests.forEach((test: SmartControlTestRecord) => {
        if (test.getResult() === AUDIT_RESULT.PASS || !!test.getIsControlNa()) {
          numerator++
        }
      })
    })
  }
  if (includeAuditReadiness && auditReadinessData) {
    numerator +=
      auditReadinessData.getControlsPassed() +
      auditReadinessData.getControlsNotApplicable()
    denominator += auditReadinessData.getControlsTotal()
  }
  if (denominator === 0) return 0
  return getPercentageRoundingDown(numerator, denominator)
}

export const getHandleRequestsPercentage = (
  data?: GetRoadmapWidgetCountsResponse,
): number => {
  if (!data) return -1
  const { numerator, denominator } = getRoadmapWidgetFractionElements(data)
  if (denominator === 0) return 0
  return getPercentageRoundingDown(numerator, denominator)
}

export const getRiskPercentage = (
  data?: GetRiskResponse,
  counts?: GetRoadmapWidgetCountsResponse,
): number => {
  const risksStarted = !!data?.getRisksStarted()
  const riskCounts = counts?.getRisks()
  const numerator = (riskCounts?.getFinished() || 0) + (risksStarted ? 1 : 0)
  const denominator = (riskCounts?.getTotal() || 0) + 1
  const percentage = getPercentageRoundingDown(numerator, denominator)

  return percentage
}

export const getRoadMapIndexRowsConfig = ({
  scopeData,
  designWidgetData,
  implementationWidgetData,
  handleRequestsWidgetData,
  operatingEffectivenessWidgetData,
  roadmapToggleData,
  smartChecksData,
  auditReadinessData,
  riskData,
  riskCounts,
  hasSoc2,
}: {
  scopeData?: GetScopeResponse
  designWidgetData?: GetRoadmapWidgetCountsResponse
  implementationWidgetData?: GetRoadmapWidgetCountsResponse
  handleRequestsWidgetData?: GetRoadmapWidgetCountsResponse
  operatingEffectivenessWidgetData?: GetRoadmapWidgetCountsResponse
  roadmapToggleData?: GetOrCreateComplianceRoadmapResponse
  smartChecksData?: GetSmartChecksTestInformationResponse
  auditReadinessData?: GetAuditReadinessResponse
  riskData?: GetRiskResponse
  riskCounts?: GetRoadmapWidgetCountsResponse
  hasSoc2?: boolean
}): Record<RoadmapSteps, RoadmapIndexRowType> => {
  return {
    [RoadmapSteps.Scope]: {
      title: 'Scope',
      description:
        'Your first challenge. Get started on the basics, allowing everyone involved to understand what the basic structures will be and what systems are in scope.',
      link: RoadmapSteps.Scope,
      percentage: getScopePercentage(scopeData, hasSoc2),
    },
    [RoadmapSteps.RiskAssessment]: {
      title: 'Risk Assessment',
      description: 'Identify and track your organization’s risks.',
      link: RoadmapSteps.RiskAssessment,
      percentage: getRiskPercentage(riskData, riskCounts),
    },
    [RoadmapSteps.Design]: {
      title: 'Design',
      description:
        'Finalize your policies and control language so you know exactly what compliance should look like for your company.',
      link: RoadmapSteps.Design,
      percentage: getRoadmapStepPercentage({
        checks: [],
        widgetData: designWidgetData,
        roadmapToggleData,
        smartChecksData,
        includeSmartChecks: true,
      }),
    },
    [RoadmapSteps.Implementation]: {
      title: 'Implementation',
      description: 'Define and delegate the work of proving compliance.',
      link: RoadmapSteps.Implementation,
      percentage: getRoadmapStepPercentage({
        checks: IMPLEMENTATION_CHECKS,
        widgetData: implementationWidgetData,
        roadmapToggleData,
      }),
    },
    [RoadmapSteps.HandleRequests]: {
      title: 'Handle Document Requests',
      description: `Use your auditor's document requests to link evidence to the relevant controls.`,
      link: RoadmapSteps.HandleRequests,
      percentage: getHandleRequestsPercentage(handleRequestsWidgetData),
    },
    [RoadmapSteps.OperatingEffectiveness]: {
      title: 'Operating Effectiveness',
      description:
        'Use Trustero AI to check that all controls should pass your audit. This involves many tests of your evidence on each control, ensuring that it proves you are doing what your controls require.',
      link: RoadmapSteps.OperatingEffectiveness,
      percentage: getRoadmapStepPercentage({
        checks: OPERATING_EFFECTIVENESS_CHECKS,
        widgetData: operatingEffectivenessWidgetData,
        roadmapToggleData,
        auditReadinessData,
        includeAuditReadiness: true,
      }),
    },
  }
}

export const getControlReadinessResults = (
  smartChecks?: AuditReadinessRecord[],
  auditControlIds?: string[],
): SmartCheckCounts => {
  const resultCounts: SmartCheckCounts = {
    passing: [],
    issues: [],
    notScanned: auditControlIds || [],
  }

  if (!smartChecks || !auditControlIds) {
    return resultCounts
  }

  const tempMap = {
    passing: new Set<string>(),
    issues: new Set<string>(),
  }

  smartChecks.reduce((acc, sc) => {
    const res = getCheckResult(
      sc
        .getControlTestsList()
        .map((ct: AuditReadinessTestRecord) => ct.getResult()),
    )
    res === AUDIT_RESULT.PASS && acc.passing.add(sc.getControlId())
    res === AUDIT_RESULT.FAIL && acc.issues.add(sc.getControlId())
    return acc
  }, tempMap as Record<string, Set<string>>)

  resultCounts.notScanned = resultCounts.notScanned.filter(
    (id) => !tempMap.passing.has(id) && !tempMap.issues.has(id),
  )

  resultCounts.passing = Array.from(tempMap.passing)
  resultCounts.issues = Array.from(tempMap.issues)

  return resultCounts
}

export const getControlPluralization = (count: number): string => {
  const showVal = count > -1 ? `${count}` : '--'
  return `${showVal} ${count !== 1 ? 'controls' : 'control'}`
}

export const getRoadmapSteps = (auditId?: string): RoadmapSteps[] => {
  const steps = Object.values(RoadmapSteps)

  if (!auditId)
    return steps.filter((step) => step !== RoadmapSteps.HandleRequests)
  return steps
}

export const getTotalRoadmapPercentage = (
  config: RoadmapIndexConfig,
  steps: RoadmapSteps[],
): number => {
  const total = steps.reduce((acc, step) => {
    return acc + config[step].percentage
  }, 0)
  return Math.floor(total / steps.length)
}
