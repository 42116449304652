import {
  filterBarHeight,
  headerHeight,
} from 'src/components/PageLayout/dimensionUtils'
import styled from 'styled-components/macro'
import { FlexAlign } from '../Flex'
import { GridColumn, GridHeader } from './Grid'

export const CenteredGridColumn = styled(GridColumn)`
  justify-content: center;
`

export const NoPaddingGridColumn = styled(GridColumn)`
  padding: 0px;
`

export const StickyGridHeader = styled(GridHeader)<{ $gridAlign?: FlexAlign }>`
  position: sticky;
  z-index: 1;
  ${({ $gridAlign }) => {
    if (!$gridAlign) return ''
    return `justify-content: ${$gridAlign};`
  }}
`

export const StickyGridHeaderAdjustedTop = styled(StickyGridHeader).attrs({
  top: headerHeight + filterBarHeight,
})<{ $gridAlign?: FlexAlign }>`
  position: sticky;
  z-index: 1;
  ${({ $gridAlign }) => {
    if (!$gridAlign) return ''
    return `justify-content: ${$gridAlign};`
  }}
`
