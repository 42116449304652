import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { MODEL_TYPE } from '@trustero/trustero-api-web/lib/common/model_pb'
import { GridColumn, GridRow } from 'src/components/Reusable/Grid'
import { DepartmentChip } from 'src/pages/Controls/DepartmentChip' // TODO: move into global helper
import { StatusDropdown } from 'src/components/Status'
import { useHasRequiredPermissions } from 'src/app/AppAuth/AppAuth.hooks'
import { PERMISSIONS } from 'src/config/roleConfig'
import { OwnerAssignmentButton } from '../../../components/Reusable/Buttons'
import { generatePermalink } from '../../../components/PageLayout/Permalink'
import { RisksMathMenu, RiskRowMenu } from '../menus'
import { CenteredRiskGridColumn, RiskName } from '../risks.styles'
import { RiskFields } from '../risks.constants'
import { CalculatedRiskMathChip } from '../risks.components'
import { useRisk } from '../risks.hooks'

const RiskGridRow = ({ riskId }: { riskId: string }): JSX.Element => {
  const params = useParams()
  const risk = useRisk(riskId)
  const hasMenuPermissions = useHasRequiredPermissions([
    PERMISSIONS.READ,
    PERMISSIONS.EDIT,
  ])

  if (!risk) {
    return <></>
  }

  // Risk data
  const id = risk.getId()
  const name = risk.getName()
  const customId = risk.getCustomId()
  const controlIds = risk.getControlIds()
  const controlCount = controlIds.length ? controlIds.split(',').length : 0
  const departmentId = risk.getDepartmentId()
  const ownerEmail = risk.getOwnerEmail()
  const status = risk.getStatus()
  const impact = risk.getAdverseImpact()
  const likelihood = risk.getLikelihood()
  const riskAfterTreatment = risk.getRiskAfterTreatment()
  const permalink = generatePermalink({
    pageContext: params.pageContext as string,
    modelType: MODEL_TYPE.RISK,
    modelId: customId,
    isInternalLink: true,
    includeSearchParams: true,
  })

  return (
    <GridRow id={`risks-index-row-${id}`} as={Link} to={permalink}>
      <GridColumn>
        <DepartmentChip departmentId={departmentId} />
      </GridColumn>
      <GridColumn>{customId}</GridColumn>
      <GridColumn>
        <RiskName>{name}</RiskName>
      </GridColumn>
      <CenteredRiskGridColumn>{controlCount}</CenteredRiskGridColumn>
      <CenteredRiskGridColumn>
        <CalculatedRiskMathChip
          riskId={id}
          impact={impact}
          likelihood={likelihood}
          field={RiskFields.inherentRisk}
        />
      </CenteredRiskGridColumn>
      <CenteredRiskGridColumn>
        <RisksMathMenu
          riskId={id}
          field={RiskFields.riskAfterTreatment}
          initValue={riskAfterTreatment}
          isReadOnly
        />
      </CenteredRiskGridColumn>
      <CenteredRiskGridColumn>
        <StatusDropdown
          model={risk}
          modelId={id}
          modelType={MODEL_TYPE.RISK}
          status={status}
          isDismissed={false}
        />
      </CenteredRiskGridColumn>
      <CenteredRiskGridColumn>
        <OwnerAssignmentButton
          model={risk}
          modelType={MODEL_TYPE.RISK}
          modelId={id}
          email={ownerEmail}
        />
      </CenteredRiskGridColumn>
      <CenteredRiskGridColumn>
        {hasMenuPermissions && <RiskRowMenu risk={risk} />}
      </CenteredRiskGridColumn>
    </GridRow>
  )
}

export const RiskGridRows = ({
  riskIds,
}: {
  riskIds: string[]
}): JSX.Element => {
  return (
    <>
      {riskIds.map((riskId: string) => (
        <RiskGridRow key={riskId} riskId={riskId} />
      ))}
    </>
  )
}
